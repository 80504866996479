import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index'; // Adjust the path according to your project structure

export const selectIdentifySessions = (state: RootState) => state.identifySessions.identify_sessions;
export const selectIdentifySessionsLoading = (state: RootState) => state.identifySessions.loading;
export const selectIdentifySessionsError = (state: RootState) => state.identifySessions.error;
export const selectIdentifySessionsPagination = (state: RootState) => state.identifySessions.pagination;
export const selectCurrentIdentify = (state: RootState) => state.identifySessions.currentIdentifySession;

export const selectTrajectory = (state: RootState) => state.sessions.trajectory;

export const selectIdentifyData = (state: RootState) => state.identifySessions.data;
export const selectIdentifyDataLoading = (state: RootState) => state.identifySessions.identifyloading;
export const selectIdentifyPaginationData = (state: RootState) => state.identifySessions.pagination;
export const selectIdentifyInitialMarker = (state: RootState) => state.identifySessions.initialMarker;
// Selector to get the advanced analytics data
export const selectAdvancedAnalyticsData = (state: RootState) => state.identifySessions.advancedAnalyticsData;

// Selector to check if advanced analytics data is loading
export const selectAdvancedAnalyticsLoading = (state: RootState) => state.identifySessions.advancedAnalyticsLoading;

// Selector to get any errors related to fetching advanced analytics data
export const selectAdvancedAnalyticsError = (state: RootState) => state.identifySessions.error;

// Example selector to get top device IDs from the advanced analytics data
export const selectTopDeviceIDs = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.top_device_ids || []
);

// Example selector to get carrier usage from the advanced analytics data
export const selectCarrierUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.carrier_usage || []
);

// Example selector to get RAT usage from the advanced analytics data
export const selectRATUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.rat_usage || []
);

// Example selector to get SSID usage from the advanced analytics data
export const selectSSIDUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.ssid_usage || []
);

export const selectAPPUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.top_apps || []
);

export const selectBrowserUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.browser_usage || []
);

export const selectTotalAppleDevices = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_apple_devices || 0
);

export const selectTotalAndroidDevices = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_android_devices || 0
);

export const selectPCDevices = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_pc_devices || 0
);

export const selectUniqueIds = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_unique_ids || 0
);

export const selectTotalIds = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_ids || 0
);