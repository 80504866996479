import React, { useEffect } from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from './store';
import { BrowserRouter, Navigate, Route, Router, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Home from './pages/Home';
import Analytics from './pages/Analytics';
import { fetchUserInfo } from './store/slices/authSlice';
import PrivateRoute from './layouts/PrivateRoute';
import { selectToken } from './store/selectors/authSelectors';
const App: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const tokenTemp = useSelector(selectToken); // Get markers from state

    useEffect(() => {
        const token = localStorage.getItem('token') || sessionStorage.getItem('token') || tokenTemp;
        if (token) {
            dispatch(fetchUserInfo()); // Fetch user info if token exists
        }
    }, [dispatch,tokenTemp]);

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                <Route path="/" element={<PrivateRoute><Home /></PrivateRoute>} />
                <Route path="/analytics" element={<PrivateRoute><Analytics /></PrivateRoute>} />
                <Route path="/dashboard" element={<PrivateRoute><Home /></PrivateRoute>} />
            </Routes>
        </BrowserRouter>
    );
};

export default App;
