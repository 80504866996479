import { useDispatch, useSelector } from 'react-redux';
import { fetchDataQuerys } from '../store/slices/userSlice';
import { AppDispatch, RootState } from '../store';
import { useEffect } from 'react';

export const useDataQuery = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { queries, loading } = useSelector((state: RootState) => state.queries);

  useEffect(() => {
    dispatch(fetchDataQuerys());
  }, [dispatch]);

  return { queries, loading };
};
