import React, { useEffect, useRef, useState } from 'react';
import { Row, Col, Card, Table, Button, Modal, Divider, Statistic } from 'antd';
import {
  BarChart, Bar, LineChart, Line, ScatterChart, Scatter,
  CartesianGrid, XAxis, YAxis, Tooltip, Legend, PieChart, Pie, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Cell
} from 'recharts';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import { message } from 'antd';

import mapboxgl from 'mapbox-gl';
import { useDispatch, useSelector } from 'react-redux';
import {   selectTopDeviceIDs, 
  selectCarrierUsage, 
  selectRATUsage, 
  selectSSIDUsage, 
  selectAPPUsage, 
  selectAdvancedAnalyticsLoading, 
  selectBrowserUsage, 
  selectCurrentIdentify,
  selectTotalAppleDevices, 
  selectTotalAndroidDevices, 
  selectUniqueIds, 
  selectTotalIds,
  selectPCDevices } from '../store/selectors/identifySelectors';
import { Spin } from 'antd/lib';
import { fetchAdvancedAnalyticsData } from '../store/slices/sessionSlice';
import { AppDispatch } from '../store';
import type { StatisticProps } from 'antd';
import CountUp from 'react-countup';
// Dataset
const data = [
  {
    "device_id": "3ef3f27e-7ee4-4e57-afb7-644b9f15b961",
    "events": 90,
    "hours_spent": 15.53,
    "distance_traveled_in_km": 0.22981314162245087,
    "activities": [
      { "possible_activity": "Home", "lat": 33.070556640625, "lon": 34.71228790283203, "radius": 192 }
    ]
  },
  // ... (other data points)
];

const AdvancedAnalytics: React.FC<any> = React.memo(({ tableWidth, tabHeight }) => {
  const { height, width } = useWindowDimensions();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedActivities, setSelectedActivities] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const mapContainer = useRef<HTMLDivElement | null>(null);
  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lon: number } | null>(null);
  const maps = useRef<mapboxgl.Map | null>(null);
  const topDeviceIDs = useSelector(selectTopDeviceIDs);
  const carrierUsage = useSelector(selectCarrierUsage);
  const ratUsage = useSelector(selectRATUsage);
  const ssidUsage = useSelector(selectSSIDUsage);
  const appUsage = useSelector(selectAPPUsage);
  const browserUsage = useSelector(selectBrowserUsage);
  const appleDevices = useSelector(selectTotalAppleDevices);
  const androidDevices = useSelector(selectTotalAndroidDevices);
  const totalIds = useSelector(selectTotalIds);
  const totalUniqueIds = useSelector(selectUniqueIds);
  const pcDevices = useSelector(selectPCDevices);
  const loading = useSelector(selectAdvancedAnalyticsLoading);
  const current_session = useSelector(selectCurrentIdentify);
  const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator="," />
  );
  useEffect(() => {
    if (current_session) {
      dispatch(fetchAdvancedAnalyticsData(current_session.session_id));
    }
  }, [dispatch, current_session]);
  // Update the map rendering logic
  useEffect(() => {
    mapboxgl.accessToken = "pk.eyJ1IjoiZHJha2l1cyIsImEiOiJjbTE5NXEwc3gxYWtjMmpzY2hheW9xbzhhIn0.okfXThYubEd6vlcOhf6Yeg";
    var map: any = '';

    if (isModalVisible && selectedActivities.length > 0 && mapContainer.current) {
      map = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [selectedActivities[0].lat, selectedActivities[0].lon],
        zoom: 4,
      });

      const bounds = new mapboxgl.LngLatBounds();
      selectedActivities.forEach(activity => {
        var popup = new mapboxgl.Popup()
        .setText(activity.possible_activity)
        .addTo(map);

        new mapboxgl.Marker({ color: 'red' })
        .setLngLat([activity.lat, activity.lon])
        .addTo(map)
        .setPopup(popup);

        bounds.extend([activity.lat, activity.lon]);
      });

      map.fitBounds(bounds, { padding: 20 });
    }

    return () => {
      if (map) {
        maps.current = map;
      }
    };
  }, [selectedActivities, isModalVisible]);

  const showModal = (activities: any) => {
    setSelectedActivities(activities);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    message.success('Copied ' + text + ' to clipboard!');
  };
  // Define columns for the table
  const columns = [
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Events',
      dataIndex: 'events',
      key: 'events',
    },
    {
      title: 'Hours Spent',
      dataIndex: 'hours_spent',
      key: 'hours_spent',
    },
    {
      title: 'Distance Traveled (km)',
      dataIndex: 'distance_traveled_in_km',
      key: 'distance_traveled_in_km',
    },
    {
      title: 'Appears In Countries',
      dataIndex: 'visited_countries',
      key: 'visited_countries',
    },
    {
      title: 'View Activity Location',
      key: 'action',
      render: (_: any, record: any) => (
        <Button
          type="primary"
          onClick={() => showModal(record.activities)}
          disabled={record.activities.length === 0}
        >
          View Activities
        </Button>
      ),
    },
  ];
 
  const COLORS = ['#DB5202', '#289C9C', '#864F2E','#2F5C5C'];
  return (

    <div style={{ width: tableWidth, padding: 24 }}>
      <Spin percent={'auto'} spinning={loading}>
        <Divider orientation="left" style={{ borderColor: '#289C9C', fontWeight: 600 }}>Device Distribution</Divider>
        <Row gutter={[16, 16]}>
          {/* Bar Chart */}
          <Col span={4} style={{ marginBottom: '16px' }}>
            <Card title="Total Locations">
              <Statistic value={totalIds} formatter={formatter} />
            </Card>
          </Col>

          <Col span={4} style={{ marginBottom: '16px' }}>
            <Card title="Unique Devices">
            <Statistic value={totalUniqueIds} formatter={formatter} />
            </Card>
          </Col>
          <Col span={4} style={{ marginBottom: '16px' }}>
            <Card title="Android Devices">
            <Statistic value={androidDevices} formatter={formatter} />
            </Card>
          </Col>
          <Col span={4} style={{ marginBottom: '16px' }}>
            <Card title="Apple Devices">
            <Statistic value={appleDevices} formatter={formatter} />
            </Card>
          </Col>
          <Col span={4} style={{ marginBottom: '16px' }}>
            <Card title="PC Devices">
            <Statistic value={pcDevices} formatter={formatter} />
            </Card>
          </Col>
          <Col span={4} style={{ marginBottom: '16px' }}>
            <Card title="Top Device IDs">
            <Statistic value={topDeviceIDs.length} formatter={formatter} />
            </Card>
          </Col>
        </Row>
        <Divider orientation="left" style={{ borderColor: '#03dcdc', fontWeight: 600 }}>Top Devices</Divider>
        
        <Row gutter={[16, 16]}>
          {/* Bar Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Events per Device">
              <BarChart width={500} height={300} data={topDeviceIDs}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="device_id" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="events" fill="#8884d8" />
              </BarChart>

            </Card>
          </Col>

          {/* Line Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Hours Spent per Device">
              <LineChart width={((width - 600) / 2)} height={300} data={topDeviceIDs}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="device_id" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="hours_spent" stroke="#82ca9d" />
              </LineChart>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {/* Distance Traveled Line Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Distance Traveled per Device">
              <LineChart width={((width - 600) / 2)} height={300} data={topDeviceIDs}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="device_id" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="distance_traveled_in_km" stroke="#82ca9d" />
              </LineChart>
            </Card>
          </Col>

          {/* Scatter Plot */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Distance vs Hours Spent">
              <ScatterChart width={((width - 600) / 2)} height={300}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="hours_spent" name="Hours Spent" />
                <YAxis dataKey="distance_traveled_in_km" name="Distance Traveled (km)" />
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Scatter data={topDeviceIDs} fill="#82ca9d" />
              </ScatterChart>
            </Card>
          </Col>
        </Row>

        <Row gutter={[16, 16]}>
          {/* Correlation Bar Chart */}
          <Col span={24}>
            <Card title="Correlation: Events and Hours Spent">
              <BarChart width={width - 600} height={300} data={topDeviceIDs}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="device_id" />
                <YAxis yAxisId="left" orientation="left" dataKey="events" stroke="#8884d8" />
                <YAxis yAxisId="right" orientation="right" dataKey="hours_spent" stroke="#82ca9d" />
                <Tooltip />
                <Legend />
                <Bar yAxisId="left" dataKey="events" fill="#8884d8" />
                <Bar yAxisId="right" dataKey="hours_spent" fill="#82ca9d" />
              </BarChart>
            </Card>
          </Col>
        </Row>

        {/* Table */}
        <Row gutter={[16, 16]}>
          <Col span={24}>
            <Card title="Top Devices Pattern Recognition">
              <Table dataSource={topDeviceIDs} columns={columns} rowKey="device_id" />
            </Card>
          </Col>
        </Row>

        <Divider orientation="left" style={{ borderColor: '#03dcdc', fontWeight: 600 }}>APP & SSID Usage</Divider>
        <Row gutter={[16, 16]}>
          {/* Pie Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Top Apps">
              <PieChart width={(width - 600) / 2} height={300}>
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Pie
                  data={appUsage}
                  dataKey="total"
                  nameKey="app"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {appUsage.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card>
          </Col>
          {/* Radar Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Top SSIDs">
            <PieChart width={(width - 600) / 2} height={300}>
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Pie
                  data={ssidUsage}
                  dataKey="total"
                  nameKey="ssid"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {ssidUsage.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card>
          </Col>
        </Row>

        <Divider orientation="left" style={{ borderColor: '#03dcdc', fontWeight: 600 }}>Carrier Usage</Divider>
        <Row gutter={[16, 16]}>
          {/* Pie Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Carrier Usage Distribution">
              <PieChart width={(width - 600) / 2} height={300}>
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Pie
                  data={carrierUsage}
                  dataKey="total"
                  nameKey="carrier"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {carrierUsage.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card>
          </Col>
          {/* Radar Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Carrier Usage Comparison">
              <RadarChart outerRadius={90} width={((width - 600) / 2)} height={300} data={carrierUsage}>
                <PolarGrid />
                <PolarAngleAxis dataKey="carrier" />
                <PolarRadiusAxis />
                <Radar
                  name="Usage"
                  dataKey="total"
                  stroke="#8884d8"
                  fill="#82ca9d"
                  fillOpacity={0.6}
                />
                <Tooltip />
              </RadarChart>
            </Card>
          </Col>
        </Row>
        <Divider orientation="left" style={{ borderColor: '#03dcdc', fontWeight: 600 }}>Rat Usage</Divider>
        <Row gutter={[16, 16]}>
          {/* Pie Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Rat Usage Distribution">
              <PieChart width={(width - 600) / 2} height={300}>
                <Tooltip cursor={{ strokeDasharray: '3 3' }} />
                <Legend />
                <Pie
                  data={ratUsage}
                  dataKey="total"
                  nameKey="rat"
                  cx="50%"
                  cy="50%"
                  outerRadius={100}
                  label
                >
                  {ratUsage.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                  ))}
                </Pie>
                <Tooltip />
              </PieChart>
            </Card>
          </Col>
          {/* Radar Chart */}
          <Col span={12} style={{ marginBottom: '16px' }}>
            <Card title="Rat Usage Comparison">
              <RadarChart outerRadius={90} width={((width - 600) / 2)} height={300} data={ratUsage}>
                <PolarGrid />
                <PolarAngleAxis dataKey="rat" />
                <PolarRadiusAxis />
                <Radar
                  name="Usage"
                  dataKey="total"
                  stroke="#8884d8"
                  fill="#82ca9d"
                  fillOpacity={0.6}
                />
                <Tooltip />
              </RadarChart>
            </Card>
          </Col>
        </Row>
        {/* Modal for Activities */}
        <Modal
          title="Activities"
          open={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          {selectedActivities.length > 0 ? (
            <div
              ref={mapContainer}
              style={{ width: '100%', height: '400px' }}
            />) : (
            <p>No activities available.</p>
          )}
        </Modal></Spin>
    </div>
  );
});

export default AdvancedAnalytics;

