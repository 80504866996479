import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '..';
import { fetchMarkers } from './sessionSlice';
import { toggleIdentifySidebar, toggleRightSidebar, setPanningAreas, setSelectedSessionId } from './uiSlice';

// Define the interfaces for IdentifySession data structure
interface Timeframe {
  start: string | null;
  end: string | null;
}

interface Filters {
  email: string;
  msisdn: string;
  geo_file: string | null;
  ip: string;
  device_id: string;
  id_type: string | null;
  ssid: string;
  bssid: string;
  target_name: string;
}

interface IdentifyData {
  id_type: string;
  rat: string;
  app: string | null;
  device_model: string;
  device_id: string;
  lat: number;
  lon: number;
  ts: string;
  carrier: string;
}

interface IdentifySession {
  name: string;
  session_id: string;
  status: string;
  created_at: string;
  data_count: number;
  start: string | null;
  end: string | null;
  timeframe: Timeframe;
  filters: Filters;
  session_type: string;
  panning_areas: any;
}


interface ResponeSessionAnalyticsData {
  items: AnalyticsData[] | null;
  total: number;
  page: number;
  size: number;
  pages: number;
}
export interface AnalyticsData {
  id_type: string;
  rat: string;
  app: string | null;
  device_brand: string;
  device_id: string;
  lat: number;
  lon: number;
  ts: string;
  gender: string;
  ip: string;
  location_type: string;
  ssid: string;
  bssid: string;
  device_model: string;
  ua: string | null;
  age: number | null;
  plmn: number | null;
  long_cellid: number | null;
  hashed_email: string | null;
  device_type: string | null;
  country: string | null;
  ip_type: string | null;
  isp: string | null;
}
export interface AnalyticsGraphData {
  total_events: any;
  countries_covered: any[];
  total_device_ids: any;
  total_area_sessions: any;
  total_identify_sessions: any;
  total_unique_ssids: any;
  total_unique_ips: any;
}

interface IdentifySessionState {
  identify_sessions: IdentifySession[];
  data: IdentifyData[];
  loading: boolean;
  identifyloading: boolean;
  identifyerror: string | null;
  error: string | null;
  createloading:boolean;
  createrror:string | null;
  initialMarker: any;
  currentIdentifySession: IdentifySession | null;
  pagination: {
    total: number;
    page: number;
    size: number;
    pages: number;
  };
  identifypagination: {
    total: number;
    page: number;
    size: number;
    pages: number;
  };
  analyticsData: AnalyticsData[]; // Add analyticsData to the state
  analyticsLoading: boolean;
  paginationAnalytics: ResponeSessionAnalyticsData | null;
  //Graph Data
  analyticsGraphData: AnalyticsGraphData| null; // Add analyticsData to the state
  analyticsGraphLoading:boolean;
  advancedAnalyticsData: {
    top_device_ids: any[];
    carrier_usage: any[];
    rat_usage: any[];
    ssid_usage: any[];
    top_apps: any[];
    browser_usage: any[];
    total_apple_devices: number;
    total_android_devices:number;
    total_pc_devices: number;
    total_unique_ids: number;
    total_ids: number;
  } | null;
  advancedAnalyticsLoading: boolean;
}

// Initial state
const initialState: IdentifySessionState = {
  identify_sessions: [],
  data: [],
  currentIdentifySession: null,
  loading: false,
  identifyloading: false,
  createloading:false,
  createrror:null,
  identifyerror: null,
  initialMarker:null,
  error: null,
  identifypagination: {
    total: 0,
    page: 1,
    size: 50,
    pages: 1,
  },
  pagination: {
    total: 0,
    page: 1,
    size: 50,
    pages: 1,
  },
  paginationAnalytics: null,
  analyticsData: [], // Initialize analyticsData as an empty array
  analyticsLoading: false,
  //Graph Data
  analyticsGraphData: null,
  analyticsGraphLoading: false,
  advancedAnalyticsData: null,
  advancedAnalyticsLoading: false,
};
// Async thunk for creating a session
export const createIdentifySession = createAsyncThunk(
  'identifySessions/createIdentifySession',
  async (sessionData: any, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
            const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');


    if (!token) {
      return rejectWithValue('No token available');
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/sessions/identify/create`,
        sessionData,
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      const merged = Object.assign({}, response.data, sessionData);
      const currentSession: any = merged;
      // dispatch(setCurrentIdentifySession(currentSession));
      // dispatch(setPanningAreas(currentSession.panning_areas));
      // dispatch(setSelectedSessionId(currentSession.session_id));
      // await dispatch(fetchMarkers({sessionId:currentSession.session_id,sessionType:'IDENTIFY',timeframe:null,filters:currentSession.filters})); // Await the dispatch to ensure markers are fetched before proceeding
      // dispatch(toggleBottomSidebar());
      // dispatch(toggleIdentifySidebar(true));
      dispatch(toggleRightSidebar(false));

      return merged;
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to create session');
    }
  }
);

export const fetchIdentifySessionData = createAsyncThunk(
  'identifySessions/fetchIdentifySessionData',
  async (
    { session_id, start, end, filters, page = 1, pageSize = 10, orderBy = null }: 
    { session_id: string; start: any; end: any; filters: any; page: number; pageSize: number; orderBy:any },
    { getState,dispatch, rejectWithValue }
  ) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');

      if (!token) {
        return rejectWithValue('No token available');
      }
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/sessions/datatables/data?page=${page}&size=${pageSize}`,
        {
          session_id,
          session_type:'IDENTIFY',
          start,
          end,
          order_by:orderBy,
          filters,
        },
        {
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        }
      );
      dispatch(setInitialIdentifyMarker({lat:response.data.items[0].lat,lon:response.data.items[0].lon }));
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data?.message || 'Failed to fetch identify data');
    }
  }
);

export const fetchAdvancedAnalyticsData = createAsyncThunk(
  'data/fetchAdvancedAnalyticsData',
  async (sessionId: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');

    if (!token) {
      return rejectWithValue('No token available');
    }

    try {
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/analytics/advanced?session_id=${sessionId}`,
        {
          params: {
            session_id: sessionId,
            session_type: 'IDENTIFY'
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response.data; // Assuming response.data is in the expected format
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch advanced analytics data');
    }
  }
);

export const fetchIdentifySessions = createAsyncThunk(
    'identifySessions/fetchIdentifySessions',
    async ({ page = 1, pageSize = 10, search_filter = "" }: { page: number; pageSize: number; search_filter: string }, { getState, dispatch, rejectWithValue }) => {
              try {
                const state = getState() as RootState;
                const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');
    
        
        if (!token) {
          return rejectWithValue('No token available');
        }
        
        const params = {
          page: page,
          size: pageSize,
          search_filter: search_filter
        }
        const response = await axios.get(
          
          `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/sessions/identify/`, 
          {
          params: params,
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`, // Use your token
          },
        });
        return response.data;
      } catch (error: any) {
        return rejectWithValue(error.response?.data?.message || 'Failed to fetch identify sessions');
      }
    }
  );
  const identifySessionSlice = createSlice({
    name: 'identifySessions',
    initialState,
    reducers: {
      clearError: (state) => {
        state.error = null;
      },
      setCurrentIdentifySession(state, action: PayloadAction<any>) {
        state.currentIdentifySession = action.payload;
      },
      setInitialIdentifyMarker(state,action: PayloadAction<any>) {
        state.initialMarker =(action.payload!=null)? {lat:action.payload.lat, lon:action.payload.lon } :action.payload;

      },
      clearIdentifySession(state) {
        state.data = [];
        state.currentIdentifySession=null;
        state.initialMarker=null;
      }
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchIdentifySessions.pending, (state) => {
          state.loading = true;
          state.error = null;
        })
        .addCase(fetchIdentifySessions.fulfilled, (state, action) => {
          state.loading = false;
          state.identify_sessions = action.payload.items;
          state.pagination = {
            total: action.payload.total,
            page: action.payload.page,
            size: action.payload.size,
            pages: action.payload.pages,
          };
        })
        .addCase(fetchIdentifySessions.rejected, (state, action) => {
          state.loading = false;
          state.error = action.payload as string;
        })
        .addCase(fetchIdentifySessionData.pending, (state) => {
          state.identifyloading = true;
          state.identifyerror = null;
        })
        .addCase(fetchIdentifySessionData.fulfilled, (state, action: PayloadAction<any>) => {
          state.identifyloading = false;
          state.data = action.payload.items;
          state.identifypagination = {
            total: action.payload.total,
            page: action.payload.page,
            size: action.payload.size,
            pages: action.payload.pages,
          };        })
        .addCase(fetchIdentifySessionData.rejected, (state, action: PayloadAction<any>) => {
          state.identifyloading = false;
          state.identifyerror = action.payload || 'Failed to fetch identify session data';
        })
              // Create Session
      .addCase(createIdentifySession.pending, (state) => {
        state.createloading = true;
        state.createrror = null;
      })
      .addCase(createIdentifySession.fulfilled, (state, action: PayloadAction<any>) => {
        state.createloading = false;
        state.identify_sessions.unshift(action.payload);
        state.currentIdentifySession = action.payload;
      })
      .addCase(createIdentifySession.rejected, (state, action) => {
        state.createloading = false;
        state.createrror = action.payload as string || 'Failed to create session';
      })
      // Fetch Advanced Analytics Data
      .addCase(fetchAdvancedAnalyticsData.pending, (state) => {
        state.advancedAnalyticsLoading = true;
        state.error = null;
      })
      .addCase(fetchAdvancedAnalyticsData.fulfilled, (state, action: PayloadAction<any>) => {
        state.advancedAnalyticsLoading = false;
        state.advancedAnalyticsData = action.payload;
      })
      .addCase(fetchAdvancedAnalyticsData.rejected, (state, action) => {
        state.advancedAnalyticsLoading = false;
        state.error = action.payload as string || 'Failed to fetch advanced analytics data';
      });
    },
  });
  
  export const { 
    clearError, 
    setCurrentIdentifySession,
    setInitialIdentifyMarker,
    clearIdentifySession 
  } = identifySessionSlice.actions;
  
  export default identifySessionSlice.reducer;

