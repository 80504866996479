import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Collapse, Descriptions, Typography, Tag, Space, Spin, Badge } from "antd";
import './FlippingCard.css';
import { Content } from "antd/es/layout/layout";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapDynamic from "./MapDynamic";
const { Panel } = Collapse;

interface FlippingCardProps {
    active: string,
    session: {
        session_id: any;
        name: string;
        status: any;
        area_search: Array<{
            type: string;
            geometry: {
                type: string;
                coordinates: number[][][];
            };
            properties: Record<string, any>;
        }>;
        filters: {
            gender: string | null;
            rat: string | null;
            device_id: string | null;
            device_brand: string | null;
            device_model: string | null;
            ip: string | null;
            app: string | null;
            carrier: string | null;
            ssid: string | null;
            bssid: string | null;
            id_type: string | null;
            location_type: string | null;
            lat: string | null;
            lon: string | null;

        };
        timeframe: {
            start: string;
            end: string;
        };
        type: string;
        data_count: number;
    },
    onActivated: (evt: any, polygon: any) => any;
}

const FlippingCard: React.FC<FlippingCardProps> = ({ session, active, onActivated }) => {
    const [flipped, setFlipped] = useState(false);
    const [startTime, setStartTime] = useState<any>('');
    const [endTime, setEndTime] = useState<any>('');
    const map = useRef<mapboxgl.Map | null>(null);
    const [currentPolygon, setcurrentPolygon] = useState<any>('');
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const toggleFlip = (event: React.MouseEvent) => {
        event.stopPropagation();
        setFlipped(!flipped);
    };

    const handleCardClick = () => {
        onActivated(session.session_id, {
            "type": "FeatureCollection",
            "features": session.area_search
        });
    };
    const parseDate = (dateString: string) => {
        const tempDate = new Date(dateString);

        // Get the day name
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = daysOfWeek[tempDate.getDay()];

        // Get the month name
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthName = months[tempDate.getMonth()];

        // Get the day number, year, and time parts
        const dayNum = tempDate.getDate();
        const year = tempDate.getFullYear();
        let hours = tempDate.getHours();
        const minutes = tempDate.getMinutes();
        const seconds = tempDate.getSeconds();
        const period = hours >= 12 ? "PM" : "AM";

        // Convert 24-hour format to 12-hour format
        hours = hours % 12 || 12;

        // Format numbers to always have two digits
        const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);

        // Return all parts as an object
        return {
            dayName,
            monthName,
            dayNum: formatNumber(dayNum),
            year: year.toString(),
            hours: formatNumber(hours),
            minutes: formatNumber(minutes),
            seconds: formatNumber(seconds),
            period
        };
    };
    const { filters, name, timeframe, type, data_count, status } = session;
    const handleAccordionClick = (event: any) => {
        // Prevent card click from being triggered when interacting with the accordion
        event.stopPropagation();
    };
    useEffect(() => {
        setcurrentPolygon({
            "type": "FeatureCollection",
            "features": session.area_search
        })
    }, [session])
    useEffect(() => {
        // Parse the  date and set the state with the returned object
        if (timeframe.start && timeframe.end) {
            const start = parseDate(timeframe.start);
            const end = parseDate(timeframe.end);
            setEndTime(end);
            setStartTime(start);
        }

    }, [timeframe]); // Dependency array contains apoel, so it runs when apoel changes

    return (
        <>
            <Badge.Ribbon text={session.data_count}  color="#03dcdc">

                <Card
                    className={`flipping-card ${flipped ? 'flipped' : ''}`}
                    hoverable
                    onClick={handleCardClick}
                    style={{
                        marginBottom: '16px', 
                        border: (active === session.session_id) ? '2px solid #27e8e1' : 'none'
                    }}
                >
                    <Content className="flipping-card-inner">
                        <div onClick={handleAccordionClick} className={`flipping-card-front ${!flipped ? 'active' : ''}`}  >

                            <Collapse defaultActiveKey={['0']} accordion
                            >
                                {/* Session Information */}
                                <Panel header={<Space>{name}<Tag color={status === 'COMPLETE' ? 'success' : (status == 'FAILED') ? 'error' : 'processing'}>{status}</Tag></Space>} key="1">
                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label="Start Time" span={4}>
                                            <div className="datetime">
                                                <div className="date">
                                                    <span id="dayname">{startTime.dayName} </span>
                                                    <span id="month">{startTime.monthName} </span>
                                                    <span id="daynum">{startTime.dayNum} </span>
                                                </div>
                                                <div className="time">
                                                    <span id="hour">{startTime.hours} </span>:
                                                    <span id="minutes">{startTime.minutes}</span>:
                                                    <span id="seconds">{startTime.seconds}</span>
                                                    <span id="period">{startTime.period}</span>
                                                </div>
                                                <div className="date">
                                                    <span id="year">{startTime.year} </span>
                                                </div>
                                            </div></Descriptions.Item>
                                        <Descriptions.Item label="End Time" span={4}>
                                            <div className="datetime">
                                                <div className="date">
                                                    <span id="dayname">{endTime.dayName} </span>
                                                    <span id="month">{endTime.monthName} </span>
                                                    <span id="daynum">{endTime.dayNum} </span>,
                                                </div>
                                                <div className="time">
                                                    <span id="hour">{endTime.hours} </span>:
                                                    <span id="minutes">{endTime.minutes} </span>:
                                                    <span id="seconds">{endTime.seconds} </span>
                                                    <span id="period">{endTime.period} </span>
                                                </div>
                                                <div className="date">
                                                    <span id="year">{endTime.year} </span>
                                                </div>
                                            </div></Descriptions.Item>
                                    </Descriptions>
                                </Panel>

                                <Panel header={session.type} key="2" style={{ zIndex: 100000 }}>

                                    <MapDynamic markerCoordinates={[40, -74]} polygonData={currentPolygon} />
                                </Panel>
                            </Collapse>
                        </div>
                        <div className={`flipping-card-back ${flipped ? 'active' : ''}`} title="Filters" onClick={handleAccordionClick}>
                            <Collapse defaultActiveKey={['0']} accordion                         // Prevent accordion click from activating the card
                            >
                                {/* Session Information */}


                                {/* Device Information */}
                                <Panel header="Device Information" key="1">

                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label={"Device ID"} span={4}>{filters.device_id || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Device Brand"} span={4}>{filters.device_brand || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Device Model"} span={4}>{filters.device_model || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"App"} span={4}>{filters.app || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Rat"} span={4}>{filters.rat || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Carrier"} span={4}>{filters.carrier || 'N/A'}</Descriptions.Item>


                                    </Descriptions>
                                </Panel>

                                {/* Personal Identifiable Information */}
                                <Panel header="Personal Identifiable Information" key="2">
                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label={"Gender"} span={4}>{filters.gender || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"IP"} span={4}>{filters.ip || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"SSID"} span={4}>{filters.ssid || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"BSSID"} span={4}>{filters.bssid || 'N/A'}</Descriptions.Item>


                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        </div>
                    </Content>
                    <Button type={flipped ? "primary" : "default"} style={{ margin: '16px 0' }} onClick={toggleFlip}>
                        {flipped ? "Show Info" : "Show Filters"}
                    </Button>
                </Card>    </Badge.Ribbon>
        </>
    );
};

export default FlippingCard;
