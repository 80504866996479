import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Badge, Space, Avatar, message, Input } from 'antd';
import { ProTable, ProColumns } from '@ant-design/pro-components';
import { fetchDeanData } from '../store/slices/deanSlice';
import { selectDeanData, selectDeanLoading } from '../store/selectors/deanSelectors';
import { AppDispatch } from '../store';
import facebook_logo from "../assets/services/facebook.png"
import instagram_logo from "../assets/services/instagram.png"
import whatsapp_logo from "../assets/services/whatsapp.png"
import linkedin_logo from "../assets/services//linkedin.png"
import google_logo from "../assets/services/google.png"
import microsoft_logo from "../assets/services/microsoft.png"
import telegram_logo from "../assets/services/telegram.png"
import useWindowDimensions from "../hooks/useWindowsDimensions";

import {
    PlusCircleOutlined
  } from '@ant-design/icons';
import DeanModal from './DeanModal';
const DeanTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const deanData = useSelector(selectDeanData);
  const loading = useSelector(selectDeanLoading);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100); // Number of cards per page
  const { height } = useWindowDimensions();
  const [searchText, setSearchText] = useState<any>(null); // Number of cards per page

  useEffect(() => {
    dispatch(fetchDeanData({page: currentPage, pageSize: pageSize, searchText: searchText}));
  }, [currentPage, pageSize]);

  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    message.success('Copied ' + text + ' to clipboard!');
  };

  const columns: ProColumns[] = [
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      // copyable: true,
      // ellipsis: true,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Avatar',
      dataIndex: 'device_id',
      key: 'device_id',
      render: (device_id, record) => {
        if (record?.social_media && record?.social_media.length != 0){
          return (
            <Avatar.Group>
                <Avatar size={48} src={record?.social_media[0].avatar} />
            </Avatar.Group>
          )
        }else {
          return (
            <Avatar.Group>
                <Avatar size={48}/>
            </Avatar.Group>
          )
        }
      } 
    },
    {
      title: 'Active Services',
      dataIndex: 'device_id',
      key: 'device_id',
      render: (device_id, record) => {
        let microsoft = false;
        let instagram = false;
        let facebook = false;
        let whatsapp = false
        let google = false;
        let linkedin = false;
        let telegram = false;
        record?.social_media.forEach((activity: { service: string; }) => {     
          if (activity.service.toLowerCase() == 'instagram'){
            instagram = true;
          }
          else if (activity.service.toLowerCase() == 'facebook'){
            facebook = true;
          }
          else if (activity.service.toLowerCase() == 'telegram'){
            telegram = true;
          }else if (activity.service.toLowerCase() == 'whatsapp'){
            whatsapp = true;
          }else if (activity.service.toLowerCase() == 'microsoft'){
            microsoft = true;
          }else if (activity.service.toLowerCase() == 'linkedin'){
            linkedin = true;
          }else if (activity.service.toLowerCase() == 'google'){
            google = true;
          }
        });

        return (
          <Avatar.Group>
              {microsoft&&<Avatar size={38} src={microsoft_logo} alt="microsoft" />}
              {instagram&&<Avatar size={38} src={instagram_logo} alt="instagram" />}
              {linkedin&&<Avatar size={38} src={linkedin_logo} alt="linkedin" />}
              {google&&<Avatar size={38} src={google_logo} alt="google" />}
              {whatsapp&&<Avatar size={38} src={whatsapp_logo} alt="whatsapp" />}
              {telegram&&<Avatar size={38} src={telegram_logo} alt="telegram" />}
              {facebook&&<Avatar size={38} src={facebook_logo} alt="facebook" />}
          </Avatar.Group>
        )
        
      } 
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Badge
          status={status === 'PENDING' ? 'processing' : 'success'}
          text={status}
          color={status === 'PENDING' ? 'blue' : 'green'}
        />
      ),
    },
    {
      title: 'MSISDN',
      dataIndex: 'msisdn',
      key: 'msisdn',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Hashed Device ID',
      dataIndex: 'md5_device_id',
      ellipsis:true,
      key: 'md5_device_id',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      valueType: 'dateTime',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
  ];

  return (
      <>
    <ProTable<any>
      columns={columns}
      dataSource={deanData?.items}
      loading={loading}
      scroll={{ y: height /8 }}
      rowKey={(data: any, index: any) => index} // Use index as the key
      toolBarRender={() => [
        <Input.Search
          placeholder="Dean Data Search"
          enterButton
          value={searchText}
          onChange={( e: any ) => {
            setSearchText(e.target.value);
          }}
          onSearch={() => {
            setCurrentPage(1);
            dispatch(fetchDeanData({page: currentPage, pageSize: pageSize, searchText: searchText}));
          }}
          style={{ maxWidth: '100%', width: '100%', padding: "6px" }}
        />,
        <Button key="create-dean" type="primary" onClick={() => setModalVisible(true)}>
        De-anonymize  <PlusCircleOutlined twoToneColor="#52c41a" />
        </Button>,
      ]}
      search={false}
      pagination={{
        current: currentPage, total: deanData?.total, pageSize: pageSize, onChange: (page, size) => {
          setCurrentPage(page);
          setPageSize(size); // Update pageSize state
        }
      }}
    />
          <DeanModal
        visible={modalVisible}
        onClose={() => setModalVisible(false)}
        device_id={''}
      />
    </>
  );
};

export default DeanTable;
