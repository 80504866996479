// MapComponent.tsx
import React, { useEffect, useRef } from "react";
import mapboxgl from "mapbox-gl";
import * as turf from "@turf/turf";

interface MapComponentProps {
  markerCoordinates: any; // For the marker
  polygonData: any;
}

const MapDynamic: React.FC<MapComponentProps> = ({ markerCoordinates, polygonData }) => {
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);

  useEffect(() => {
    mapboxgl.accessToken = window.__RUNTIME_CONFIG__.ADINT_UI_MAPBOX_TOKEN;

    if (mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: markerCoordinates,
        zoom: 10,
      });

      // Add the marker
    //   new mapboxgl.Marker({ color: 'blue' })
    //     .setLngLat(markerCoordinates)
    //     .addTo(map.current);

      // Add polygon if available
      if (polygonData) {
        map.current.on('load', () => {
          map.current?.addSource('polygon', {
            type: 'geojson',
            data: polygonData,
          });

          map.current?.addLayer({
            id: 'polygon-layer',
            type: 'fill',
            source: 'polygon',
            layout: {},
            paint: {
                'fill-color': '#dc0303',
                'fill-opacity': 0.1,
            },
          });

          map.current?.addLayer({
            id: 'polygon-outline',
            type: 'line',
            source: 'polygon',
            layout: {},
            paint: {
                'line-color': '#dc0303',
                'line-width': 2,
            },
          });
          // Directly set the viewport based on the polygon's bounding box
          const bbox = turf.bbox(polygonData);
          if (map.current) {
            map.current.fitBounds(bbox as mapboxgl.LngLatBoundsLike, { padding: 20 ,duration:0});
          }
        });
      }
    }

    return () => {
      map.current?.remove();
    };
  }, [markerCoordinates, polygonData]);

  return (
    <div
      ref={mapContainer}
      style={{ height: '200px', width: '100%' }}
    />
  );
};

export default MapDynamic;
