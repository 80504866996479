import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '..';

interface ReportState {
  loading: boolean;
  error: string | null;
}

const initialState: ReportState = {
  loading: false,
  error: null,
};


// Async thunk for exporting data
export const downloadDataExport = createAsyncThunk(
  'session/downloadDataExport',
  async ({ sessionId = '', sessionType = '' }: { sessionId: string, sessionType: string },  { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');
      
      // Requesting the export
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/sessions/datatables/download`,
        {
          params: {
            session_id: sessionId,
            session_type: sessionType
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json', // Request PDF format explicitly
          },
          responseType: 'blob', // Fetching the file as a blob
        }
      );

    //   // Create a download link and trigger the download for PDF
    //   const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/html' }));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', `report-${sessionId}.html`); // Save the file as .pdf
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
      // Create a download link and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${sessionType}-${sessionId}.csv`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Download failed');
    }
  }
);


const exportSlice = createSlice({
  name: 'export',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadDataExport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadDataExport.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadDataExport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default exportSlice.reducer;
