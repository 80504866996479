import React, { useState } from 'react';
import { DashboardOutlined, DotChartOutlined, SettingOutlined, ProfileOutlined, LogoutOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu, Button } from 'antd';
import logo from '../assets/edge-logo2.png';
import Link from 'antd/es/typography/Link';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { logout } from '../store/slices/authSlice'; // Adjust the import path if necessary

type MenuItem = Required<MenuProps>['items'][number];

const Navigation: React.FC = () => {
  const [current, setCurrent] = useState('dashboard');
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onClick: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  const handleLogout = () => {
    dispatch(logout());
    navigate('/login'); // Redirect to login page after logout
  };

  const items: MenuItem[] = [
    {
      label: <Link>Dashboard</Link>,
      key: 'dashboard',
      icon: <DashboardOutlined />,
    },

  ];

  return (
    <div style={{
      backgroundColor: 'rgba(16, 24, 39, 0.8)',
      width: '100%',
      textAlign: 'center',
      position: 'absolute',
      top: 0,
      left: 0,
      zIndex: 10,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 20px',
    }}>
      <img width={150} style={{ margin: '3px 80px 0px 84px' }} src={logo} />
      <Menu
        style={{
          backgroundColor: 'none',
          background: 'none',
          width: 'auto',
          flex: 1,
        }}
        onClick={onClick}
        selectedKeys={[current]}
        mode="horizontal"
        items={items}
      />
      <Button
        type="text"
        icon={<LogoutOutlined />}
        onClick={handleLogout}
        style={{ marginRight: 20, color: 'white' }}
      >
        Logout
      </Button>
    </div>
  );
};

export default Navigation;
