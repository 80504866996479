import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "..";
import { DataQuery } from '../../interfaces';
import { List } from 'antd';
// export const fetchDataQuerys = createAsyncThunk('queries/fetchDataQuerys', async () => {
//   const response = await axios.get('https://dummyjson.com/queries');
//   console.log(response,response.data,response.data['queries'])
//   return response.data['queries'];
// });

// Define the interface for the user data based on the provided structure


interface Histogram {
  label: string;
  start: number;
  end: number;
  count: number;
}

// Modify the initial state to match the new data structure
const initialState: TimeframeState = {
  histogram: [],
  loading: false,
  error: null,
};

interface TimeframeState {
    histogram: Histogram[];
    error: string | null;
    loading: boolean;
}

// Modify the fetchDataQuerys thunk to use the dummydata variable
export const fetchHistogram = createAsyncThunk(
    "data/fetchHistogram",
    async (
        {
        sessionId,
        sessionType,
        }: { sessionId: string; sessionType: string; },
      { getState, rejectWithValue }
    ) => {
      const state = getState() as RootState;
      const token =
        state.auth.token ||
        localStorage.getItem("token") ||
        sessionStorage.getItem("token");
  
      if (!token) {
        return rejectWithValue("No token available");
      }
  
      try {
        const response = await axios.post(
          `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/map/timeline`,
          {
            session_id: sessionId,
            session_type: sessionType,
            // filters: filters,
          },
          {
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        );
  
        return response.data; // Assuming response.data is in the AnalyticsGraphData format
      } catch (err: any) {
        return rejectWithValue(
          err.response?.data?.detail || "Failed to fetch histogram"
        );
      }
    }
  );

const timelineSlice = createSlice({
  name: 'timeline',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchHistogram.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchHistogram.fulfilled, (state, action) => {
        state.loading = false;
        state.histogram = action.payload;
      })
      .addCase(fetchHistogram.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch queries';
      });
  },
});

export default timelineSlice.reducer;