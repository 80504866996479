import React, { useEffect, useState } from 'react';
import { Steps, Button, Form, Input, Select, Typography, Divider, Card, DatePicker } from 'antd';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { createSession } from '../store/slices/sessionSlice';
import dayjs from 'dayjs';
import { resetQF } from '../store/selectors/uiSelectors';
import { resetQueryForm, setFeatureId, setSegm } from '../store/slices/uiSlice';

const { Step } = Steps;
const { Option } = Select;
const { RangePicker } = DatePicker;

const QueryForm: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const polygon = useSelector((state: RootState) => state.ui.polygon);
  const dispatch = useDispatch<AppDispatch>();
  const polygons = useSelector((state: RootState) => state.ui.multipolygon);
  const drawMode = useSelector((state: RootState) => state.ui.activeDraw);

  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({});

  const [timeframe, setTimeframe] = useState<any>([
    dayjs().subtract(1, 'months').startOf('day'),
    dayjs().endOf('day'),
  ]);  
  const queryForm = useSelector(resetQF);
  useEffect(() => {
    if(queryForm){
      form.resetFields();
      dispatch(resetQueryForm(false));
      setCurrent(0); // Reset the steps to the initial state

    }
      }, [dispatch, queryForm]);
    
  const onChange = (value: number) => {
    setCurrent(value);
  };

  const next = () => {
    form.validateFields()
      .then(() => {
        setFormData({ ...formData, ...form.getFieldsValue() });
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const onFinish = () => {
    const values = formData;
    const session = {
      name: values.name,
      timeframe: {
        start: timeframe[0],
        end: timeframe[1]
      },
      area_search: polygons ? polygons : [polygon],
      countries: [],
      filters: {
        rat: values.rat || null,
        gender: values.gender || null,
        device_id: null,
        device_brand: values.device_brand || null,
        device_model: values.device_model || null,
        ip: values.ip || null,
        app: values.app || null,
        carrier: null,
        ssid: null,
        bssid: null,
        id_type: values.id_type || null,
        location_type:null
      },
      type:drawMode =='Multi Area' ? "Multi Area Search" :drawMode =='Multi Area Compare' ? "Multi Area Comparison" :drawMode =='Border Control'?"Border Control": "Area Search"
    };
    dispatch(createSession(session));
    dispatch(setSegm('Static'));
    dispatch(setFeatureId(''));

    setCurrent(0); // Reset the steps to the initial state
    form.resetFields();

  };

  const handleTimeframeChange = (period: string) => {
    const newTimeframe = {
      '1d': [dayjs().subtract(1, 'day').startOf('day'), dayjs().endOf('day')],
      '1w': [dayjs().subtract(1, 'week').startOf('day'), dayjs().endOf('day')],
      '1m': [dayjs().subtract(1, 'month').startOf('day'), dayjs().endOf('day')],
      '3m': [dayjs().subtract(3, 'month').startOf('day'), dayjs().endOf('day')],
      '6m': [dayjs().subtract(6, 'month').startOf('day'), dayjs().endOf('day')],
      '1y': [dayjs().subtract(1, 'year').startOf('day'), dayjs().endOf('day')],
    }[period];

    setTimeframe(newTimeframe);
    form.setFieldsValue({ timeframe: newTimeframe });
  };

  const steps = [
    {
      title: 'Session Details (mandatory)',
      content: (
        <>
          <Form.Item
            label="Session Name"
            name="name"
            rules={[{ required: true, message: 'Please enter a session name!' }]}
          >
            <Input placeholder="Enter session name" />
          </Form.Item>
          <Divider/>
          <Form.Item
            label={
              <div>
                Timeframe
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1d')}>
                  1d
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1w')}>
                  1w
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1m')}>
                  1m
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('3m')}>
                  3m
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('6m')}>
                  6m
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1y')}>
                  1y
                </Button>
              </div>
            }
            name="timeframe"
            rules={[{ required: true, message: 'Please select a time range!' }]}
            initialValue={timeframe} // Set default values
          >
            <RangePicker
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              value={timeframe}
              onChange={(dates) => setTimeframe(dates as [dayjs.Dayjs, dayjs.Dayjs])}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Network & Connectivity (optional)',
      content: (
        <>
        <Form.Item
          label="Network Connection Technology"
          name="rat"
        >
          <Select placeholder="Select your network technology">
            <Option value={null} selected>ALL</Option>
            <Option value="NR">5G</Option>
            <Option value="LTE">4G</Option>
            <Option value="WCDMA">3G</Option>
            <Option value="GSM">2G</Option>
            <Option value="WIFI">WIFI</Option>
            <Option value="ETHERNET">ETHERNET</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="IP Type"
          name="ip_type"
        >
          <Select placeholder="Select your preffered ip type">
            <Option value={null} selected>ALL</Option>
            <Option value="VPN">VPN</Option>
            <Option value="REAL_MOBILE_DATA">REAL_MOBILE_DATA</Option>
            <Option value="REAL_MOBILE_WIFI">REAL_MOBILE_WIFI</Option>
            <Option value="PROXY">PROXY</Option>
            <Option value="TOR">TOR</Option>
          </Select>
        </Form.Item>
        <Form.Item
            label="Location Type"
            name="location_type"
            rules={[{ required: false, message: 'Please select the Location Type!' }]}
          >
            <Select placeholder="Select your Location Type">
              <Option value={null} selected>ALL</Option>
              <Option value="GPS ">GPS</Option>
              <Option value="IP">IP</Option>
              <Option value="CELLULAR">CELLULAR</Option>
            </Select>
          </Form.Item>
        </>
        
      ),
    },
    {
      title: 'Personal & Device ID (optional)',
      content: (
        <>
          <Form.Item
            label="Gender"
            name="gender"
          >
            <Select placeholder="Select your gender">
              <Option value={null} selected>ALL</Option>
              <Option value="M">MALE</Option>
              <Option value="F">FEMALE</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="ID Type"
            name="id_type"
            rules={[{ required: false, message: 'Please select the ID Type!' }]}
          >
            <Select placeholder="Select your ID type">
            <Option value={null} selected>ALL</Option>
              <Option value="AAID ">SDK</Option>
              <Option value="IDFA">RTB</Option>
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Device Details (optional)',
      content: (
        <>
          <Form.Item
            label="Device Type"
            name="device_type"
            rules={[{ required: false, message: 'Please select the Device Type!' }]}
          >
            <Select placeholder="Select your Device Type">
              <Option value='MOBILE_TABLET' selected>Mobile/Tablet</Option>
              <Option value="PC ">Personal Computer</Option>
              <Option value="PHONE">Phone</Option>
              <Option value="TABLET">Tablet</Option>
              <Option value="CONNECTED_DEVICE">Connected Device</Option>
              <Option value={null}>ALL</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Device Brand"
            name="device_brand"
          >
            <Input placeholder="e.g., Apple, Samsung" />
          </Form.Item>
          <Form.Item
            label="Device Model"
            name="device_model"
          >
            <Input placeholder="e.g., iPhone 13, Galaxy S21" />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Application & IP (optional)',
      content: (
        <>
          <Form.Item
            label="App"
            name="app"
          >
            <Input placeholder="e.g., WhatsApp, Facebook" />
          </Form.Item>
          <Form.Item
            label="IP Address"
            name="ip"
          >
            <Input placeholder="e.g., 192.168.0.1" />
          </Form.Item>
          <Form.Item
            label="Advertisment URL"
            name="ad_url"
          >
            <Input placeholder="www.example.com/advertisment.html" />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Device ID (optional)',
      content: (
        <Form.Item
          label="DeviceID"
          name="maid"
        >
          <Input placeholder="Mobile Advertising ID (optional)" />
        </Form.Item>
      ),
    },
    {
      title: 'Review & Confirm',
      content: (
        <>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ overflow: 'hidden' }}
    >
      <Card>
        <Steps direction="vertical" current={current} onChange={onChange}>
          {steps.map((item, index) => (
            <Step key={index} title={item.title} />
          ))}
        </Steps>
      </Card>
      <Divider />
      <Card>
        {steps[current].content}
        <div className="steps-action" style={{ marginTop: '20px' }}>
          {current > 0 && !(current === steps.length - 1) && (
            <Button type="dashed" style={{ margin: '0 8px' }} onClick={prev}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
        </div>
      </Card>
    </Form>
  );
};

export default QueryForm;
