import React, { useEffect, useState } from 'react';
import { Steps, Button, Form, Input, Select, Typography, Divider, Card, DatePicker, Space, InputNumber, Slider } from 'antd';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { createSession, createTask } from '../store/slices/sessionSlice';
import dayjs from 'dayjs';
import { resetQF } from '../store/selectors/uiSelectors';
import { resetQueryForm, setFeatureId, setSegm } from '../store/slices/uiSlice';
import { selectTaskType } from '../store/selectors/sessionSelectors';
import { useCountries } from 'use-react-countries'
import { selectAllowedCountries } from '../store/selectors/authSelectors';
import { countryToAlpha3 } from "country-to-iso";


const { Step } = Steps;
const { Option } = Select;
const { RangePicker } = DatePicker;

const TaskQueryForm: React.FC = () => {
  const [current, setCurrent] = useState(0);
  const polygon = useSelector((state: RootState) => state.ui.polygon);
  const allowedCountries = useSelector(selectAllowedCountries);

  const dispatch = useDispatch<AppDispatch>();
  const polygons = useSelector((state: RootState) => state.ui.multipolygon);
  const drawMode = useSelector((state: RootState) => state.ui.activeDraw);
  const taskType = useSelector(selectTaskType);
  const [form] = Form.useForm();
  const [formData, setFormData] = useState<any>({});

  const [geofenceType, setGeofenceType] = useState<any>(null);
  const [individuaslType, setIndividualsType] = useState<any>(null);
  
  const [borderControlType, setBorderControlType] = useState<any>(null);
  const [fromCountries, setfromCountries] = useState<any>(null);
  const [countryOptions, setCountryOptions] = useState<any>(null);
  const [toCountry, setToCountry] = useState<any>(null);

  const [deviceIds, setDeviceIds] = useState<any>([]);
  const [ssidList, setSSIDS] = useState<any>([]);
  const [ipList, setIPs] = useState<any>([]);
  const [bssidList, setBSSIDs] = useState<any>([]);
  const [orKeywordsList, setOrKeywords] = useState<any>([]);
  const [andKeywordsList, setAndKeywords] = useState<any>([]);
  const [appList, setAPPs] = useState<any>([]);
  const [ratList, setRATs] = useState<any>([]);
  const [ipTypeList, setIPTypes] = useState<any>([]);
  const [locationTypeList, setLocationTypes] = useState<any>([]);
  const [idTypeList, setIdTypes] = useState<any>([]);
  const [deviceTypeList, setDeviceType] = useState<any>([]);
  const [deviceBrandsList, setDeviceBrands] = useState<any>([]);
  const [deviceModelsList, setDeviceModels] = useState<any>([]);
  const [carrierList, setCarriers] = useState<any>([]);
  const [urlList, setURLs] = useState<any>([]);
  const [ageList, setAgeRange] = useState<any>([]);

  const [interval, setInterval] = useState<any>(15);
  const [intervalType, setIntervalType] = useState<any>('minutes');

  const { countries } = useCountries()

  const [timeframe, setTimeframe] = useState<any>([
    dayjs().subtract(1, 'months').startOf('day'),
    dayjs().endOf('day'),
  ]);  
  const queryForm = useSelector(resetQF);
  
  useEffect(() => {
    if (!countryOptions){
      getCountryOptions();
    }
    }, [countryOptions]);

  useEffect(() => {
    if(queryForm){
      form.resetFields();
      dispatch(resetQueryForm(false));
      setCurrent(0); // Reset the steps to the initial state

    }
      }, [dispatch, queryForm]);
    
  const onChange = (value: number) => {
    setCurrent(value);
  };

  const next = () => {
    form.validateFields()
      .then(() => {
        setFormData({ ...formData, ...form.getFieldsValue() });
        setCurrent(current + 1);
      })
      .catch((errorInfo) => {
        console.log('Validation Failed:', errorInfo);
      });
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const calculateRunInterval = () => {
    if (intervalType == 'hours') {
      return interval * 60;
    }else if (intervalType == 'minutes'){
      return interval;
    }else if (intervalType == 'days') {
      return interval * 60 * 24;
    }else if (intervalType == 'weeks'){
      return interval * 60 * 7;
    }
  };

  const formatTaskConfig = () => {
    if (taskType == 'Geofence'){
      return {
        operation: 'Geofence',
        monitor_for: geofenceType,
        geofence: polygon
      }
    }else if (taskType == 'Individuals Monitoring'){
      return {
        operation: 'Individuals Monitor',
        monitor_for: geofenceType,
        to_country: toCountry || [],
      }
      
    }else if (taskType == 'Border Control'){
      return {
        operation: 'Border Control',
        monitor_for: geofenceType,
        to_country: toCountry || [],
        from_country: fromCountries || [],
        geofence: polygon
      }
    }
  };

  const onFinish = () => {
    const values = formData;
    const task = {
      run_until: timeframe[1],
      run_interval: calculateRunInterval(),
      task_config: formatTaskConfig(),
      filters: {
        rat: ratList || null,
        age: ageList || null,
        gender: values.gender || null,
        device_id_list: deviceIds || null,
        ip_list: ipList || null,
        device_type: deviceTypeList || null,
        ip_type: ipTypeList || null,
        or_keywords: orKeywordsList || null,
        and_keywords: andKeywordsList || null,
        bssid_list: bssidList || null,
        ssid_list: ssidList || null,
        device_brand: deviceBrandsList || null,
        device_model: deviceModelsList || null,
        app: appList || null,
        carrier: carrierList || null,
        id_type: idTypeList || null,
        location_type: locationTypeList || null
      },
    };
    dispatch(createTask(task));
    dispatch(setSegm('Static'));
    dispatch(setFeatureId(''));

    setCurrent(0); // Reset the steps to the initial state
    form.resetFields();

  };

  const handleTimeframeChange = (period: string) => {
    const newTimeframe = {
      '1d': [dayjs(), dayjs().add(1, 'day')],
      '1w': [dayjs(), dayjs().add(1, 'week')],
      '1m': [dayjs(), dayjs().add(1, 'month')],
      '1h': [dayjs(), dayjs().add(1, 'hour')]
    }[period];

    setTimeframe(newTimeframe);
    form.setFieldsValue({ timeframe: newTimeframe });
  };
  
  const getCountryOptions = () => {
    var options : Array<any> = [];
    for (let element of countries) {
      if (toCountry && element.name == toCountry){
        continue;
      }
      let iso3 = countryToAlpha3(element.name);
      if (!allowedCountries.includes(iso3)){
        continue;
      }
      options.push(
        {
          'label': element.name,
          'emoji': element.emoji,
          'value': iso3,
          'desc': element.name
        }
      )
    };
    setCountryOptions(options);
  };

  const steps = [
    {
      title: 'Task Configuration (mandatory)',
      content: (
        <>
          {taskType == 'Geofence' && <Form.Item
            label="Geofence Behaviour"
            name="geofence"
            rules={[{ required: true, message: 'Please specify Geofence type' }]}
          >
            <Select
            defaultValue={null}
            value={geofenceType}
            style={{ width: 160 }}
            onChange={setGeofenceType}
            options={[
              { value: 'Entry', label: 'Collect On Entry' },
              { value: 'Exit', label: 'Collect On Exit' }
            ]}
          />
          </Form.Item>}
          {taskType == 'Individuals Monitoring' && <Form.Item
            label="Individuals Behaviour"
            name="individuals"
            rules={[{ required: false}]}
          >
            <Select
            defaultValue={null}
            style={{ width: 240 }}
            onChange={setIndividualsType}
            options={[
              { value: null, label: '' },
              { value: 'notify_on_id_proximity', label: 'Collect On Devices Proximity' },
              { value: 'notify_on_id_seperation', label: 'Collect On Devices Separation' },
            ]}
          />
          </Form.Item>}

          {taskType == 'Border Control' && <Form.Item
            label="Control Behaviour"
            name="control"
            rules={[{ required: true, message: 'Please specify Geofence type' }]}
          >
            <Select
            showSearch
            optionFilterProp="label"
            style={{ width: 240 }}
            onChange={setBorderControlType}
            options={[
              { value: 'Entry', label: 'Collect On Border Entry' },
              { value: 'Exit', label: 'Collect On Border Exit' },
            ]}
          />
          </Form.Item>} 
          
          {taskType == 'Border Control' && <Form.Item
            label={(borderControlType && borderControlType == 'Entry') ? "Arriving From": "Travelling To"}
            name="travelling_to"
            rules={[{ required: false,}]}
          >
            <Select
            mode="multiple"
            defaultValue={null}
            style={{ width: 240 }}
            onChange={(borderControlType && borderControlType == 'IN') ? setfromCountries: setToCountry}
            options={countryOptions}
            optionRender={(option) => (
              <Space>
                {/* <span role="img" aria-label={option.data.label}>
                  {option.data.emoji}
                </span> */}
                {option.data.label} {option.data.emoji}
              </Space>
            )}
          />
          </Form.Item>}
          {taskType == 'Individuals Monitoring' && <Form.Item
            label="Travelling To"
            name="travelling_to"
            rules={[{ required: false }]}
          >
            <Select
            mode="multiple"
            defaultValue={null}
            style={{ width: 240 }}
            onChange={setToCountry}
            options={countryOptions}
            optionRender={(option) => (
              <Space>
                {/* <span role="img" aria-label={option.data.label}>
                  {option.data.emoji}
                </span> */}
                {option.data.label} {option.data.emoji}
              </Space>
            )}
          />
          </Form.Item>}
          <Divider/>
          <Form.Item
            label={
              <div>
                Task End Date
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1h')}>
                  1h
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1d')}>
                  1d
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1w')}>
                  1w
                </Button>
                <Button size="small" type="link" onClick={() => handleTimeframeChange('1m')}>
                  1m
                </Button>
              </div>
            }
            name="timeframe"
            rules={[{ required: true, message: 'Please select a time range!' }]}
            initialValue={timeframe} // Set default values
          >
            <RangePicker
              minDate={dayjs()}
              showTime={{ format: 'HH:mm' }}
              format="YYYY-MM-DD HH:mm"
              placeholder={['Start Time', 'End Time']}
              value={timeframe}
              onChange={(dates) => setTimeframe(dates as [dayjs.Dayjs, dayjs.Dayjs])}
            />
          </Form.Item>
          <Form.Item
            label="Collection Interval"
            name="interval"
            rules={[{ required: false, message: 'Please specify a collection interval'}]}
          >
            <Space>
              <Select value={intervalType} defaultValue='hours' onChange={setIntervalType} style={{ width: 100 }} >
                <Option value="minutes">Minutes</Option>
                <Option value="hours">Hours</Option>
                <Option value="days">Days</Option>
                <Option value="weeks">Weeks</Option>
              </Select>
              {intervalType && intervalType == 'hours' && <InputNumber min={1} max={24} value={interval} onChange={setInterval} />}
              {intervalType && intervalType == 'minutes' && <InputNumber min={1} max={60} value={interval} onChange={setInterval} />}
              {intervalType && intervalType == 'days' && <InputNumber min={1} max={30} value={interval} onChange={setInterval} />}
              {intervalType && intervalType == 'weeks' && <InputNumber min={1} max={4} value={interval} onChange={setInterval} />}
            </Space>

          </Form.Item>

        </>
      ),
    },
    {
      title: 'Filters (optional)',
      content: (
        <>
          
          <Form.Item
            label="Quick Mass Filters"
            name="filters_list"
          >
            <Button type="primary" >
              Filters Upload
            </Button>
          </Form.Item>
          <Form.Item
            label="Device ID"
            name="device_id"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter DeviceIDs"
              onChange={setDeviceIds}
              options={[]}
            />

          </Form.Item>
          {taskType == 'Individuals Monitoring' && <Form.Item
            label="IP Address"
            name="ip"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="e.g., 192.168.0.1"
              onChange={setIPs}
              options={[]}
            />
          </Form.Item>}
          {taskType == 'Individuals Monitoring' && <Form.Item
            label="SSID"
            name="ssid"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter SSID" 
              onChange={setSSIDS}
              options={[]}
            />
          </Form.Item>}
          {taskType == 'Individuals Monitoring' && <Form.Item
            label="BSSID"
            name="bssid"
          >
             <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter BSSID" 
              onChange={setBSSIDs}
              options={[]}
            />
          </Form.Item>}
          <Form.Item
            label="APPs"
            name="apps"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter APP Names"
              onChange={setAPPs}
              options={[]}
            />

          </Form.Item>
          <Form.Item
            label="Advertisment URL/Domains"
            name="ad_url"
          >
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter Full URL or Domain"
              onChange={setURLs}
              options={[]}
            />
          </Form.Item>
          <Form.Item
            label="URL Keyword Matcher"
            name="url_keywrods"
          > 
            OR
             <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter Keywords" 
              onChange={setOrKeywords}
              options={[]}
            />
            AND
            <Select
              mode="tags"
              style={{ width: '100%' }}
              placeholder="Enter Keywords" 
              onChange={setAndKeywords}
              options={[]}
            />
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Network & Connectivity (optional)',
      content: (
        <>
        <Form.Item
          label="Network Connection Technology"
          name="rat"
        >
          <Select placeholder="Select your network technology" onChange={setRATs} mode='multiple'>
            <Option value="NR">5G</Option>
            <Option value="LTE">4G</Option>
            <Option value="WCDMA">3G</Option>
            <Option value="GSM">2G</Option>
            <Option value="WIFI">WIFI</Option>
            <Option value="ETHERNET">ETHERNET</Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="IP Type"
          name="ip_type"
        >
          <Select placeholder="Select your preffered ip type" onChange={setIPTypes} mode='multiple'>
            <Option value="VPN">VPN</Option>
            <Option value="REAL_MOBILE_DATA">REAL_MOBILE_DATA</Option>
            <Option value="REAL_MOBILE_WIFI">REAL_MOBILE_WIFI</Option>
            <Option value="PROXY">PROXY</Option>
            <Option value="TOR">TOR</Option>
          </Select>
        </Form.Item>
        <Form.Item
            label="Location Type"
            name="location_type"
            rules={[{ required: false, message: 'Please select the Location Type!' }]}
          >
            <Select placeholder="Select your Location Type" onChange={setLocationTypes} mode='multiple'>
              <Option value="GPS ">GPS</Option>
              <Option value="IP">IP</Option>
              <Option value="CELLULAR">CELLULAR</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Carrier"
            name="carrier"
            rules={[{ required: false, message: 'Please select the Carriers' }]}
          >
            <Select placeholder="Select your Carrier List" onChange={setCarriers} mode='tags' options={[]}></Select>
          </Form.Item>
        </>
        
      ),
    },
    {
      title: 'Personal & Device ID (optional)',
      content: (
        <>
          <Form.Item
            label="Gender"
            name="gender"
          >
            <Select placeholder="Select your gender">
              <Option value={null} selected>ALL</Option>
              <Option value="M">MALE</Option>
              <Option value="F">FEMALE</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Age Range"
            name="age"
            rules={[{ required: false, message: 'Please select a range for the age' }]}
          >
            <Slider range defaultValue={[]} onChange={setAgeRange}/>

          </Form.Item>
          <Form.Item
            label="ID Type"
            name="id_type"
            rules={[{ required: false, message: 'Please select the ID Type!' }]}
          >
            <Select placeholder="Select your ID type" onChange={setIdTypes} mode='multiple'>
              <Option value="AAID ">SDK</Option>
              <Option value="IDFA">RTB</Option>
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Device Details (optional)',
      content: (
        <>
          <Form.Item
            label="Device Type"
            name="device_type"
            rules={[{ required: false, message: 'Please select the Device Type!' }]}
          >
            <Select placeholder="Select your Device Type" onChange={setDeviceType} mode='multiple'>
              <Option value='MOBILE_TABLET' selected>Mobile/Tablet</Option>
              <Option value="PC ">Personal Computer</Option>
              <Option value="PHONE">Phone</Option>
              <Option value="TABLET">Tablet</Option>
              <Option value="CONNECTED_DEVICE">Connected Device</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="Device Brand"
            name="device_brand"
          >
            <Select placeholder="Se.g., Apple, Samsung" onChange={setDeviceBrands} mode='tags' options={[]}>
            </Select>
          </Form.Item>
          <Form.Item
            label="Device Model"
            name="device_model"
          >
            <Select placeholder="e.g., iPhone 13, Galaxy S21" onChange={setDeviceModels} mode='tags' options={[]}>
            </Select>
          </Form.Item>
        </>
      ),
    },
    {
      title: 'Review & Confirm',
      content: (
        <>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Form.Item>
        </>
      ),
    },
  ];

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={onFinish}
      style={{ overflow: 'hidden' }}
    >
      <Card>
        <Steps direction="vertical" current={current} onChange={onChange}>
          {steps.map((item, index) => (
            <Step key={index} title={item.title} />
          ))}
        </Steps>
      </Card>
      <Divider />
      <Card>
        {steps[current].content}
        <div className="steps-action" style={{ marginTop: '20px' }}>
          {current > 0 && !(current === steps.length - 1) && (
            <Button type="dashed" style={{ margin: '0 8px' }} onClick={prev}>
              Previous
            </Button>
          )}
          {current < steps.length - 1 && (
            <Button type="primary" onClick={next}>
              Next
            </Button>
          )}
        </div>
      </Card>
    </Form>
  );
};

export default TaskQueryForm;
