import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { store } from './store';
import { Provider } from 'react-redux';
import { ConfigProvider, theme } from 'antd';
import enUSIntl from 'antd/lib/locale/en_US';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ConfigProvider
            locale={enUSIntl}
  theme={{

        token: {
          // // Seed Token
          
          colorPrimary: '#00ffff',
          colorBorderBg:'#00ffff',
          // borderRadius: 2,
colorText:'#a0b3c6',          // // Alias Token
colorBorder:'#a0b3c6',
          colorBgContainer: 'rgba(16, 24, 39, 0.8)',
          // colorPrimaryHover: 'rgba(16, 24, 39, 1)',
          // fontSize: 16
        },

        // 1. Use dark algorithm
        algorithm: theme.darkAlgorithm,
  
        // 2. Combine dark algorithm and compact algorithm
        // algorithm: [theme.darkAlgorithm, theme.compactAlgorithm],
      }}

    >
      <Provider store={store}>

        <App />  </Provider></ConfigProvider>,
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
