import React from 'react';
import Icon from '@ant-design/icons';

// Memoized GrabIcon component
const GrabIcon = React.memo(({ color }: { color: string }) => (
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 512.00 512.00"
    xmlns="http://www.w3.org/2000/svg"
    fill={'none'} // Use the color prop directly
    stroke={color} // Use the color prop directly
  >
    <g id="SVGRepo_bgCarrier" strokeWidth="0"></g>
    <g id="SVGRepo_tracerCarrier" strokeLinecap="round" strokeLinejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <polygon
        fill={color} // Use the color prop directly for polygon fill
        points="130.412 323.98 78.529 272.098 240 272.098 240 433.568 188.118 381.687 165.49 404.313 256 494.823 346.51 404.313 323.882 381.687 272 433.568 272 272.098 432.667 272.098 380.784 323.98 403.412 346.607 493.921 256.098 403.412 165.588 380.784 188.215 432.667 240.098 272 240.098 272 79.432 323.882 131.313 346.51 108.687 256 18.177 165.49 108.687 188.118 131.313 240 79.432 240 240.098 78.529 240.098 130.412 188.215 107.784 165.588 17.274 256.098 107.785 346.608 130.412 323.98"
        className="ci-primary"
      ></polygon>
    </g>
  </svg>
));

// CustomGrabIcon component that wraps GrabIcon
const CustomGrabIcon = (props: { color: string }) => {
  return <Icon component={() => <GrabIcon color={props.color} />} />;
};

export default CustomGrabIcon;
