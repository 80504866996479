import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Collapse, Descriptions, Tag, Space, Badge } from "antd";
import './FlippingCard.css';
import { Content } from "antd/es/layout/layout";
import MapDynamic from "./MapDynamic";

const { Panel } = Collapse;

interface IdentifyFlippingCardProps {
    active: string;
    session: {
        session_id: any;
        name: string;
        status: any;
        filters: {
            email: string | null;
            msisdn: string | null;
            device_id: string | null;
            ip: string | null;
            ssid: string | null;
            bssid: string | null;
        };
        timeframe: {
            start: string;
            end: string;
        };
        data_count: number;
    };
    onActivated: (evt: any, polygon: any) => any;
}

const IdentifyFlippingCard: React.FC<IdentifyFlippingCardProps> = ({ session, active, onActivated }) => {
    const [flipped, setFlipped] = useState(false);
    const [startTime, setStartTime] = useState<any>('');
    const [endTime, setEndTime] = useState<any>('');

    const toggleFlip = (event: React.MouseEvent) => {
        event.stopPropagation();
        setFlipped(!flipped);
    };

    const handleCardClick = () => {
        onActivated(session.session_id, null); // No area_search for identify sessions
    };

    const parseDate = (dateString: string) => {
        const date = new Date(dateString);
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = daysOfWeek[date.getDay()];
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthName = months[date.getMonth()];
        const dayNum = date.getDate();
        const year = date.getFullYear();
        let hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        const period = hours >= 12 ? "PM" : "AM";
        hours = hours % 12 || 12;

        const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);

        return {
            dayName,
            monthName,
            dayNum: formatNumber(dayNum),
            year: year.toString(),
            hours: formatNumber(hours),
            minutes: formatNumber(minutes),
            seconds: formatNumber(seconds),
            period
        };
    };

    const { filters, name, timeframe, data_count, status } = session;

    useEffect(() => {
        if (timeframe.start && timeframe.end) {
            const start = parseDate(timeframe.start);
            const end = parseDate(timeframe.end);
            setStartTime(start);
            setEndTime(end);
        }
    }, [timeframe]);

    return (
        <>
            <Badge.Ribbon text={session.data_count} color="#03dcdc">

                <Card
                    className={`flipping-card ${flipped ? 'flipped' : ''}`}
                    hoverable
                    onClick={handleCardClick}
                    style={{
                        marginBottom: '16px',
                        border: (active === session.session_id) ? '2px solid #27e8e1' : 'none'
                    }}
                >
                    <Content className="flipping-card-inner">
                        <div className={`flipping-card-front ${!flipped ? 'active' : ''}`}>
                            <Collapse defaultActiveKey={['0']} accordion>
                                <Panel header={<Space>{name}<Tag color={status === 'COMPLETE' ? 'success' : (status == 'FAILED') ? 'error' : 'processing'}>{status}</Tag></Space>} key="1">
                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label="Start Time" span={4}>
                                            <div className="datetime">
                                                <div className="date">
                                                    <span id="dayname">{startTime.dayName} </span>
                                                    <span id="month">{startTime.monthName} </span>
                                                    <span id="daynum">{startTime.dayNum} </span>
                                                </div>
                                                <div className="time">
                                                    <span id="hour">{startTime.hours} </span>:
                                                    <span id="minutes">{startTime.minutes}</span>:
                                                    <span id="seconds">{startTime.seconds}</span>
                                                    <span id="period">{startTime.period}</span>
                                                </div>
                                                <div className="date">
                                                    <span id="year">{startTime.year} </span>
                                                </div>
                                            </div></Descriptions.Item>
                                        <Descriptions.Item label="End Time" span={4}>
                                            <div className="datetime">
                                                <div className="date">
                                                    <span id="dayname">{endTime.dayName} </span>
                                                    <span id="month">{endTime.monthName} </span>
                                                    <span id="daynum">{endTime.dayNum} </span>,
                                                </div>
                                                <div className="time">
                                                    <span id="hour">{endTime.hours} </span>:
                                                    <span id="minutes">{endTime.minutes} </span>:
                                                    <span id="seconds">{endTime.seconds} </span>
                                                    <span id="period">{endTime.period} </span>
                                                </div>
                                                <div className="date">
                                                    <span id="year">{endTime.year} </span>
                                                </div>
                                            </div></Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        </div>
                        <div className={`flipping-card-back ${flipped ? 'active' : ''}`} title="Filters">
                            <Collapse defaultActiveKey={['0']} accordion>
                                <Panel header="Filters" key="1">
                                    <Descriptions layout="vertical">
                                        <Descriptions.Item label={"Email"} span={4}>{filters.email || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"MSISDN"} span={4}>{filters.msisdn || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Device ID"} span={4}>{filters.device_id || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"IP"} span={4}>{filters.ip || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"SSID"} span={4}>{filters.ssid || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"BSSID"} span={4}>{filters.bssid || 'N/A'}</Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        </div>
                    </Content>
                    <Button type={flipped ? "primary" : "default"} style={{ margin: '16px 0' }} onClick={toggleFlip}>
                        {flipped ? "Show Info" : "Show Filters"}
                    </Button>
                </Card>
            </Badge.Ribbon>
        </>
    );
};

export default IdentifyFlippingCard;
