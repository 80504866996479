import React, { useEffect, useState, useMemo } from 'react';
import { Card, Row, Col, Statistic, Typography, Layout, Spin, Carousel, Empty } from 'antd';
import { BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid } from 'recharts';
import { AppDispatch, RootState } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { Content } from 'antd/es/layout/layout';
import Navigation from '../components/Navigation';
import SessionList from '../components/SessionList';
import Sider from 'antd/es/layout/Sider';
import { selectAddedSession, selectAnalyticsGraphData } from '../store/selectors/sessionSelectors';
import { fetchAnalyticsData } from '../store/slices/sessionSlice';

const { Title } = Typography;

const Analytics: React.FC<any> = React.memo(({ tableWidth, tabHeight }) => {
    const dispatch = useDispatch<AppDispatch>();
    const current_session = useSelector(selectAddedSession);
    const { analyticsGraphData, analyticsGraphLoading } = useSelector(
        (state: RootState) => state.sessions
    );

    // Memoize the chart data to prevent unnecessary recalculations
    const chartData = useMemo(() => {
        if (analyticsGraphData && current_session) {
            return [
                { name: 'Total Events', value: analyticsGraphData.total_events },
                { name: 'Unique Devices', value: analyticsGraphData.total_device_ids },
                { name: 'Countries', value: analyticsGraphData.countries_covered },
                { name: 'Unique SSIDs', value: analyticsGraphData.total_unique_ssids },
                { name: 'Unique IPs', value: analyticsGraphData.total_unique_ips },
            ];
        }
        return [];
    }, [analyticsGraphData, current_session]);

    useEffect(() => {
        if (current_session && !analyticsGraphData) {
            dispatch(fetchAnalyticsData({sessionId: current_session.session_id.toString(), sessionType: current_session.session_type.toString()}));
        }
    }, [dispatch, analyticsGraphData, current_session]);
    return (
        <>
            {analyticsGraphLoading && <Spin />}
            {!analyticsGraphLoading && analyticsGraphData && (
                
                <div className="carousel-container" style={{ height: tabHeight, width: tableWidth-50 }}                         key={`${tableWidth}-${tabHeight}`} // Force re-render when dimensions change
                >
                    <Carousel style={{ height: tabHeight-50, width: tableWidth-50  }} arrows autoplay dots={false}infinite={false}>
                        <div><Statistic title="Total Events" value={analyticsGraphData.total_events} /></div>
                        <div><Statistic title="Total Device IDs" value={analyticsGraphData.total_device_ids} /></div>
                        <div><Statistic title="Total Area Sessions" value={analyticsGraphData.total_area_sessions} /></div>
                        <div><Statistic title="Total Identify Sessions" value={analyticsGraphData.total_identify_sessions} /></div>
                        <div><Statistic title="Total Unique SSIDs" value={analyticsGraphData.total_unique_ssids} /></div>
                        <div><Statistic title="Total Unique IPs" value={analyticsGraphData.total_unique_ips} /></div>
                        {chartData.length > 0 && (
                            <div style={{  width: tableWidth-50 }}>
                                <BarChart width={tableWidth-50 } height={150} data={chartData}>
                                    <CartesianGrid strokeDasharray="1 1" />
                                    <XAxis hide={true}  dataKey="name" />
                                    <YAxis />
                                    <Tooltip />
                                    <Bar dataKey="value" fill="#03dcdc" />
                                </BarChart>
                            </div>
                        )}
                    </Carousel>
                </div>
            )}
            {!current_session && (
                <div style={{ height: '100%', width: '100%' }}>
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Please Select a Session'} />
                </div>
            )}
        </>
    );
});

export default Analytics;
