import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MapSettingsState {
    markerStyle: string;
    showPolygons: boolean;
    pathTracerStyle: string;
}

const initialState: MapSettingsState = {
    markerStyle: 'heatmap',
    showPolygons: false,
    pathTracerStyle: 'animated'
};

const MapSettingsSlice = createSlice({
    name: 'mapSettings',
    initialState,
    reducers: {
        setMarkerStyle: (state, action: PayloadAction<string>) => {
            state.markerStyle = action.payload;
        },
        setShowPolygons: (state, action: PayloadAction<boolean>) => {
            state.showPolygons = action.payload;
        },
        setPathTracerStyle: (state, action: PayloadAction<string>) => {
            state.pathTracerStyle = action.payload;
        },
    },
});

export const { setMarkerStyle, setShowPolygons, setPathTracerStyle } = MapSettingsSlice.actions;
export default MapSettingsSlice.reducer;
