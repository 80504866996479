import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { RootState } from "..";
import {
  setActiveSession,
  setPolygon,
  setPanningAreas,
  setSelectedSessionId,
  toggleAnalyticsSidebar,
  toggleBottomSidebar,
  toggleRightSidebar,
} from "./uiSlice";

// Define interfaces for the data based on the API structure

interface Trajectory {
  device_id: string;
  geojson: any;
}

interface Timeframe {
  start: string
  end: string
}

interface Marker {
  lat: number;
  lon: number;
  ts: string;
  device_id: string;
}
interface ResponeSessionArea {
  items: any[] | null;
  total: number;
  page: number;
  size: number;
  pages: number;
}


interface SessionTask {
  run_until: string;
  session_id: string;
  status: string;
  created_at: string;
  timeframe: Timeframe;
  updated_at: string;
  country: string;
  run_counter: number;
  data_count: number;
  user_viewed: boolean;
  task_config: any;
  next_run: string;
  ended_on: string;
  filters: {
    gender: Array<string> | [];
    rat: Array<string> | [];
    device_id: Array<string> | [];
    device_brand: Array<string> | [];
    device_model: Array<string> | [];
    ip: Array<string> | [];
    app: Array<string> | [];
    carrier: Array<string> | [];
    ssid: Array<string> | [];
    bssid: Array<string> | [];
    id_type: Array<string> | [];
    location_type: Array<string> | [];
  };
  session_type: string;
  panning_areas: any[];
}

interface ResponeTasks {
  items: SessionTask[] | [];
  total: number;
  page: number;
  size: number;
  pages: number;
}

interface SessionArea {
  session_id: string;
  name: string;
  status: string;
  timeframe: Timeframe;
  data_count: number;
  user_viewed: boolean;
  area_search: Array<{
    type: string;
    geometry: {
      type: string;
      coordinates: number[][][];
    };
    properties: Record<string, any>;
  }>;
  filters: {
    gender: string | null;
    rat: string | null;
    device_id: string | null;
    device_brand: string | null;
    device_model: string | null;
    ip: string | null;
    app: string | null;
    carrier: string | null;
    ssid: string | null;
    bssid: string | null;
    id_type: string | null;
    location_type: string | null;
    lat: string | null;
    lon: string | null;
  };
  type: string;
  session_type: string;
  panning_areas: any[];
}
interface ResponeSessionAnalyticsData {
  items: AnalyticsData[] | null;
  total: number;
  page: number;
  size: number;
  pages: number;
}
export interface AnalyticsData {
  id_type: string;
  rat: string;
  app: string | null;
  device_brand: string;
  device_id: string;
  lat: number;
  lon: number;
  ts: string;
  gender: string;
  ip: string;
  location_type: string;
  ssid: string;
  bssid: string;
  device_model: string;
  ua: string | null;
  age: number | null;
  plmn: number | null;
  long_cellid: number | null;
  hashed_email: string | null;
  device_type: string | null;
  country: string | null;
  ip_type: string | null;
  isp: string | null;

}
export interface AnalyticsGraphData {
  total_events: any;
  countries_covered: any[];
  total_device_ids: any;
  total_area_sessions: any;
  total_identify_sessions: any;
  total_unique_ssids: any;
  total_unique_ips: any;
}

//State
interface SessionState {
  drawOptions: any;
  taskType: any;
  sessions: SessionArea[];
  tasksCompleted: ResponeTasks | null;
  tasksPending: ResponeTasks | null;
  tasksRunning: ResponeTasks | null;

  addedSession: SessionArea | null;
  currentSession: SessionArea | null;
  currentTask: SessionTask | null;
  paginationSessions: ResponeSessionArea | null;

  sessionLoading: boolean;
  taskLoading: boolean;

  //Markers
  markers: Marker[]; // Added state for markers
  trajectory: Trajectory | null;
  initialMarkers: Marker[]; // Corrected spelling from 'inititialMarkers'
  filteredMarkers: Marker[];
  markerLoading: boolean;
  trajectoryLoading: boolean;
  initialMarkerLoading: boolean;
  //Analytics Data
  analyticsData: AnalyticsData[]; // Add analyticsData to the state
  analyticsLoading: boolean;
  paginationAnalytics: ResponeSessionAnalyticsData | null;
  //Graph Data
  analyticsGraphData: AnalyticsGraphData| null; // Add analyticsData to the state
  analyticsGraphLoading:boolean;
  error: string | null;
  advancedAnalyticsData: {
    top_device_ids: any[];
    carrier_usage: any[];
    rat_usage: any[];
    ssid_usage: any[];
    top_apps: any[];
    browser_usage: any[];
    total_apple_devices: number;
    total_android_devices:number;
    total_pc_devices: number;
    total_unique_ids: number;
    total_ids: number;
  } | null;
  advancedAnalyticsLoading: boolean;
  selectedRecord:any;
  zoomTo: any;
}

// Initial state
const initialState: SessionState = {
  //Sessions
  drawOptions: [],
  sessions: [],
  tasksCompleted: null,
  tasksPending: null,
  tasksRunning: null,

  taskType: '',
  paginationSessions: null,

  addedSession: null,
  currentSession: null,
  currentTask: null,
  taskLoading: false,
  sessionLoading: false,

  //Markers
  initialMarkers: [],
  filteredMarkers: [],
  markerLoading: false,
  trajectoryLoading: false,
  initialMarkerLoading: false,

  markers: [], // Initialize markers as an empty array
  trajectory: null,
  //Analytics Data
  paginationAnalytics: null,
  analyticsData: [], // Initialize analyticsData as an empty array
  analyticsLoading: false,
  //Graph Data
  analyticsGraphData: null,
  analyticsGraphLoading: false,
  error: null,
  advancedAnalyticsData: null,
  advancedAnalyticsLoading: false,
  selectedRecord: null,
  zoomTo: null,
};
// Async thunk for fetching analytics data
export const fetchAnalyticsData = createAsyncThunk(
  "data/fetchAnalyticsData",
  async (
    {
      sessionId = "",
      sessionType = "",
    }: { sessionId: string; sessionType: string },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No token available");
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/analytics/basic?session_id=${sessionId}&session_type=${sessionType}`,
        "",
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      return response.data; // Assuming response.data is in the AnalyticsGraphData format
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.detail || "Failed to fetch analytics data"
      );
    }
  }
);
export const fetchData = createAsyncThunk(
  "data/fetchData",
  async (
    {
      page = 1,
      pageSize = 10,
      sessionId = "",
      filters = "",
      sessionType = "",
      timeframe,
      orderBy = null
    }: {
      page: number;
      pageSize: number;
      sessionId: string;
      filters: any;
      sessionType: string;
      timeframe: any;
      orderBy: any;
    },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token available");
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/datatables/data?page=${page}&size=${pageSize}`,
        {
          session_id: sessionId,
          filters: filters,
          session_type: sessionType,
          timeframe: timeframe,
          order_by: orderBy
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      // Map the response data to the AnalyticsData interface

      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.message || "No data found"
      );
    }
  }
);

// Async thunk for fetching sessions with dynamic page and pageSize
export const fetchSessions = createAsyncThunk(
  "data/fetchSessions",
  async (
    { page = 1, pageSize = 10, search_filter = "" }: { page: number; pageSize: number; search_filter: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token available");
    }
    const params = {
      page: page,
      size: pageSize,
      search_filter: search_filter
    }
    try {
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/area/`,
        {
          params: params,
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );


      // if (!state.sessions.currentSession) {
      //   const currentSession: SessionArea = response.data.items[0];
      //   dispatch(setCurrentSession(currentSession));
      //   dispatch(setPolygon(currentSession.area_search[0]));
      //   await dispatch(fetchMarkers({sessionId:currentSession.session_id,filters:currentSession.filters})); // Await the dispatch to ensure markers are fetched before proceeding
      //   dispatch(toggleBottomSidebar());
      //   dispatch(setActiveSession('area'));
      //   dispatch(toggleAnalyticsSidebar(true));
      //   dispatch(toggleRightSidebar(false));
      // }
      //   return response.data;
      // } else {
      return response.data;
      //}
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.message || "Failed to fetch protected data"
      );
    }
  }
);
// Async thunk for fetching sessions with dynamic page and pageSize
export const fetchTasks = createAsyncThunk(
  "data/fetchTasks",
  async (
    { page = 1, pageSize = 10, search_filter = "", status = "" }: { page: number; pageSize: number; search_filter: string, status: string },
    { getState, dispatch, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");
    if (!token) {
      return rejectWithValue("No token available");
    }
    const params = {
      page: page,
      size: pageSize,
      search_filter: search_filter,
      status: status
    }
    try {
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/monitor/`,
        {
          params: params,
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );


      // if (!state.sessions.currentSession) {
      //   const currentSession: SessionArea = response.data.items[0];
      //   dispatch(setCurrentSession(currentSession));
      //   dispatch(setPolygon(currentSession.area_search[0]));
      //   await dispatch(fetchMarkers({sessionId:currentSession.session_id,filters:currentSession.filters})); // Await the dispatch to ensure markers are fetched before proceeding
      //   dispatch(toggleBottomSidebar());
      //   dispatch(setActiveSession('area'));
      //   dispatch(toggleAnalyticsSidebar(true));
      //   dispatch(toggleRightSidebar(false));
      // }
      //   return response.data;
      // } else {
      return {data:response.data, status:status};
      //}
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.message || "Failed to fetch protected data"
      );
    }
  }
);

// Async thunk for creating a session
export const createSession = createAsyncThunk(
  "data/createSession",
  async (sessionData: any, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No token available");
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/area/create`,
        sessionData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const merged = Object.assign({}, response.data, sessionData);
      const currentSession: SessionArea = merged;
      dispatch(setCurrentSession(currentSession));
      dispatch(setPolygon(currentSession.area_search[0]));
      dispatch(setPanningAreas(currentSession.panning_areas));
      dispatch(setSelectedSessionId(currentSession.session_id));
      await dispatch(
        fetchMarkers({
          sessionId: currentSession.session_id,
          sessionType: "AREA",
          filters: currentSession.filters,
          timeframe: null,
        })
      ); // Await the dispatch to ensure markers are fetched before proceeding
      dispatch(toggleBottomSidebar());
      dispatch(toggleAnalyticsSidebar(true));
      dispatch(toggleRightSidebar(false));

      return merged;
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.message || "Failed to create session"
      );
    }
  }
);


// Async thunk for creating a session
export const createTask = createAsyncThunk(
  "data/createTask",
  async (sessionData: any, { getState, dispatch, rejectWithValue }) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No token available");
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/monitor/create`,
        sessionData,
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      dispatch(fetchTasks({page: 1, pageSize:10, search_filter: "", status:'PENDING'}));
      dispatch(toggleRightSidebar(false));
      return response.data;
    } catch (err: any) {
      return rejectWithValue(
        err.response?.data?.message || "Failed to create session"
      );
    }
  }
);
// Async thunk for fetching trajectory of DeviceId based on the provided curl command
export const fetchTrajectory = createAsyncThunk(
  "data/fetchTrajectory",
  async (
    {
      sessionId,
      deviceId,
      sessionType,
      timeframe
    }: { sessionId: string; deviceId: string; sessionType: string; timeframe: any; },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No token available");
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/map/target/trajectory`,
        {
          session_id: sessionId,
          device_id: deviceId,
          session_type: sessionType,
          timeframe: timeframe
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`
          },
        }

      );
      return response.data;
    } catch (err: any) {
      return rejectWithValue('Not enough data for trajectory');
    }
  }
);

// Async thunk for fetching markers based on the provided curl command
export const fetchMarkers = createAsyncThunk(
  "data/fetchMarkers",
  async (
    {
      sessionId,
      filters,
      sessionType,
      timeframe,
    }: { sessionId: string; filters: any; sessionType: string; timeframe: any },
    { getState, rejectWithValue }
  ) => {
    const state = getState() as RootState;
    const token =
      state.auth.token ||
      localStorage.getItem("token") ||
      sessionStorage.getItem("token");

    if (!token) {
      return rejectWithValue("No token available");
    }

    try {
      const response = await axios.post(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/map/markers`,
        {
          session_id: sessionId,
          filters,
          session_type: sessionType,
          timeframe: timeframe,
        },
        {
          headers: {
            accept: "application/json",
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data;
    } catch (err: any) {
      return [];
    }
  }
);
export const fetchAdvancedAnalyticsData = createAsyncThunk(
  'data/fetchAdvancedAnalyticsData',
  async (sessionId: string, { getState, rejectWithValue }) => {
    const state = getState() as RootState;
    const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');

    if (!token) {
      return rejectWithValue('No token available');
    }

    try {
      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/analytics/advanced?session_id=${sessionId}`,
        {
          params: {
            session_id: sessionId,
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      return response.data; // Assuming response.data is in the expected format
    } catch (err: any) {
      return rejectWithValue(err.response?.data?.message || 'Failed to fetch advanced analytics data');
    }
  }
);

// Session slice
const sessionSlice = createSlice({
  name: "sessions",
  initialState,
  reducers: {
    clearZoomTo(state) {
      state.zoomTo = null;
    },
    setZoomTo(state, action: PayloadAction<any>) {
      state.zoomTo = action.payload;
    },
    clearTrajectory(state) {
      state.trajectory = null;
    },
    setSelectedRecord(state, action: PayloadAction<any>) {
      state.selectedRecord = action.payload;
    },
    setSessionTaskType(state, action: PayloadAction<any>) {
      state.taskType = action.payload;
    },
    setDrawOptions(state, action: PayloadAction<any>) {
      state.drawOptions = action.payload;
    },
    setCurrentTask(state, action: PayloadAction<SessionTask>) {
      state.currentTask = action.payload;
    },
    setCurrentSession(state, action: PayloadAction<SessionArea>) {
      state.currentSession = action.payload;
    },
    setMarkers(state, action: PayloadAction<any[]>) {
      state.markers = action.payload;
    },
    setFilteredMarkers(state, action: PayloadAction<Marker[]>) {
      state.filteredMarkers = action.payload;
    },
    setInitialMarkers(state, action: PayloadAction<Marker[]>) {
      state.initialMarkers = action.payload;
    },
    setTrajectory(state, action: PayloadAction<Trajectory>) {
      state.trajectory = action.payload;
    },
    removeMarkers(state) {
      state.markers = [];
    },
    clearSession(state) {
      state.markers = [];
      state.trajectory = null;
      state.currentSession = null;
      state.currentTask = null;
      state.initialMarkers = [];
      state.analyticsData = [];
      state.paginationAnalytics = null;
      state.analyticsGraphData = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch Analytics Data
      .addCase(fetchAnalyticsData.pending, (state) => {
        state.analyticsGraphLoading = true;
        state.error = null;
      })
      .addCase(
        fetchAnalyticsData.fulfilled,
        (state, action: PayloadAction<AnalyticsGraphData>) => {
          state.analyticsGraphLoading = false;
          state.analyticsGraphData = action.payload;
        }
      )
      .addCase(fetchAnalyticsData.rejected, (state, action) => {
        state.analyticsGraphLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch analytics data";
      })
      // Fetch Analytics Data
      .addCase(fetchData.pending, (state) => {
        state.analyticsLoading = true;
        state.error = null;
      })
      .addCase(fetchData.fulfilled, (state, action: PayloadAction<any>) => {
        state.analyticsLoading = false;
        state.analyticsData = action.payload.items;
        const response = action.payload;
        response["items"] = null;
        state.paginationAnalytics = response;
      })
      .addCase(fetchData.rejected, (state, action) => {
        state.analyticsLoading = false;
        state.error =
          (action.payload as string) || "Failed to fetch analytics data";
      })

      // Fetch Sessions
      .addCase(fetchSessions.pending, (state) => {
        state.sessionLoading = true;
        state.error = null;
      })
      .addCase(fetchSessions.fulfilled, (state, action: PayloadAction<any>) => {
        state.sessionLoading = false;
        state.sessions = action.payload.items;
        const response = action.payload;
        response["items"] = null;
        state.paginationSessions = response;
      })
      .addCase(fetchSessions.rejected, (state, action) => {
        state.sessionLoading = false;
        state.error = (action.payload as string) || "Failed to fetch sessions";
      })

      // Fetch Tasks
      .addCase(fetchTasks.pending, (state) => {
        state.taskLoading = true;
        state.error = null;
      })
      .addCase(fetchTasks.fulfilled, (state, action: PayloadAction<any>) => {
        state.taskLoading = false;
        if (action.payload.status == 'COMPLETED'){
          state.tasksCompleted = action.payload.data;
        }else if (action.payload.status == 'PENDING'){
          state.tasksPending = action.payload.data;
        }else if (action.payload.status == 'RUNNING'){
          state.tasksRunning = action.payload.data;
        }
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.taskLoading = false;
        state.error = (action.payload as string) || "Failed to fetch tasks";
      })
      // Create Session
      .addCase(createSession.pending, (state) => {
        state.sessionLoading = true;
        state.error = null;
      })
      .addCase(
        createSession.fulfilled,
        (state, action: PayloadAction<SessionArea>) => {
          state.sessionLoading = false;
          state.sessions.unshift(action.payload);
          state.currentSession = action.payload;
        }
      )
      .addCase(createSession.rejected, (state, action) => {
        state.sessionLoading = false;
        state.error = (action.payload as string) || "Failed to create session";
      })
      // Create Task
      .addCase(createTask.pending, (state) => {
        state.taskLoading = true;
        state.error = null;
      })
      .addCase(
        createTask.fulfilled,
        (state, action: PayloadAction<SessionTask>) => {
          state.taskLoading = false;
          // state.tasks.unshift(action.payload);
        }
      )
      .addCase(createTask.rejected, (state, action) => {
        state.taskLoading = false;
        state.error = (action.payload as string) || "Failed to create task";
      })
      
      // Fetch Markers
      .addCase(fetchMarkers.pending, (state) => {
        state.markerLoading = true;
        state.error = null;
        // Update initialMarkers only if it doesn't already have a value
        if (!state.initialMarkers || state.initialMarkers.length === 0) {
          state.initialMarkerLoading = true;
        }
      })
      .addCase(
        fetchMarkers.fulfilled,
        (state, action: PayloadAction<any[]>) => {
          state.markerLoading = false;
          state.markers = action.payload;
          // Update initialMarkers only if it doesn't already have a value
          if (!state.initialMarkers || state.initialMarkers.length === 0) {
            state.initialMarkers = action.payload;
            state.initialMarkerLoading = false;
          }
        }
      )
      .addCase(fetchMarkers.rejected, (state, action) => {
        state.markerLoading = false;
        // Update initialMarkers only if it doesn't already have a value
        state.initialMarkerLoading = false;

        state.error = (action.payload as string) || "Failed to fetch markers";
      })
      // Fetch Trajectory
      .addCase(fetchTrajectory.pending, (state) => {
        state.trajectoryLoading = true;
        state.error = null;

      })
      .addCase(
        fetchTrajectory.fulfilled,
        (state, action: PayloadAction<Trajectory>) => {
          state.trajectoryLoading = false;
          state.trajectory = action.payload;
        }
      )
      .addCase(fetchTrajectory.rejected, (state, action) => {
        state.trajectoryLoading = false;
        state.error = (action.payload as string) || "Not enough points for trajectory";
      })
                // Fetch Advanced Analytics Data
    .addCase(fetchAdvancedAnalyticsData.pending, (state) => {
      state.advancedAnalyticsLoading = true;
      state.error = null;
    })
    .addCase(fetchAdvancedAnalyticsData.fulfilled, (state, action: PayloadAction<any>) => {
      state.advancedAnalyticsLoading = false;
      state.advancedAnalyticsData = action.payload;
    })
    .addCase(fetchAdvancedAnalyticsData.rejected, (state, action) => {
      state.advancedAnalyticsLoading = false;
      state.error = action.payload as string || 'Failed to fetch advanced analytics data';
    });
  },
});

export const {
  setCurrentSession,
  setMarkers,
  setDrawOptions,
  removeMarkers,
  setInitialMarkers,
  clearSession,
  setFilteredMarkers,
  setCurrentTask,
  setSessionTaskType,
  clearTrajectory,
  setSelectedRecord,
  setZoomTo,
  clearZoomTo,
} = sessionSlice.actions;

export default sessionSlice.reducer;
