import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, message, Modal, Tooltip, Dropdown, MenuProps, Space, Descriptions, Avatar, Select } from 'antd';
import { ProTable, ProColumns } from '@ant-design/pro-components';
import { AppDispatch, RootState } from '../store';
import { AnalyticsData, fetchData, fetchMarkers, fetchTrajectory, setZoomTo, clearTrajectory, setSelectedRecord } from '../store/slices/sessionSlice';
import mapboxgl from 'mapbox-gl';
import { selectData, selectPaginationData, selectSelectedRecord } from '../store/selectors/sessionSelectors';
import { selectFilters, selectMapRef, selectPolygon } from '../store/selectors/uiSelectors';
import { setCurrentFilters, setFilters } from '../store/slices/uiSlice';
import { selectBrushTimeRange } from '../store/selectors/uiSelectors';
import rectangle from "../assets/rect.svg"

import { CopyOutlined, DownloadOutlined } from '@ant-design/icons';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import { downloadReport } from '../store/slices/reportSlice';
import { downloadDataExport } from '../store/slices/exportSlice';
import dayjs from 'dayjs';

import {
  PlusCircleOutlined,
  DownOutlined,
  UserOutlined,
  ZoomInOutlined,
  ShrinkOutlined,
  RiseOutlined,
  DeleteOutlined
} from '@ant-design/icons';
import DeanModal from './DeanModal';
import { shortenText } from '../utils/mapUtils';
import ProfileModal from './profileModal';
import { setProfileVisible, setDeviceId, setSessionType, setSessionId }  from '../store/slices/profileSlice'
import { selectProfileVisible } from '../store/selectors/profileSelectors';

const AnalyticsTable: React.FC<{ tableSize: 'small' | 'medium' | 'large' }> = ({ tableSize }) => {
  const dispatch = useDispatch<AppDispatch>();
  const { analyticsData, analyticsLoading, currentSession } = useSelector(
    (state: RootState) => state.sessions
  );
  const data = useSelector(selectData);
  const currentPolygon = useSelector(selectPolygon);
  const selectedFilters = useSelector(selectFilters);
  const [modalVisibleDean, setModalVisibleDean] = useState(false);
  const [trajectoryLoad, setTrajectoryLoading] = useState<boolean>(false);
  const [trajectoryType, setTrajectoryType] = useState<string>('');
  const selectedRecord = useSelector(selectSelectedRecord);

  const timeframe = useSelector(selectBrushTimeRange);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const profileVisible = useSelector(selectProfileVisible); 

  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lon: number } | null>(null);
  const mapContainer = useRef<HTMLDivElement | null>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(50); // Number of cards per page
  const paginData = useSelector(selectPaginationData);
  const { height, width } = useWindowDimensions();
  const { loading } = useSelector((state: RootState) => state.report); // Loading state for the report
  const [isFiltersApplied, setAiReport] = useState<boolean>(true);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>('');
  const [orderBy, setOrderBy] = useState<any>(null);
  

  useEffect(() => {
    if (currentPage && currentSession) {
      dispatch(fetchData({ sessionId: currentSession.session_id.toString(), sessionType: 'AREA', timeframe: null, pageSize: pageSize, page: currentPage, filters: selectedFilters, orderBy: orderBy }));
    }
  }, [dispatch, currentSession, currentPage]);

  useEffect(() => {
    if (pageSize && currentSession) {
      dispatch(fetchData({ sessionId: currentSession.session_id.toString(), sessionType: 'AREA', timeframe: null, pageSize: pageSize, page: currentPage, filters: selectedFilters, orderBy: orderBy }));
    }
  }, [dispatch, currentSession, pageSize]);

  
  const handleOpenModal = (record: AnalyticsData) => {
    if (tableSize !== "large"){
      dispatch(setZoomTo({lat: record.lat, lon: record.lon}));

    }else{
      setSelectedLocation({ lat: record.lat, lon: record.lon });
      setIsModalVisible(true);
    }
  };


  const handleCloseModal = () => {
    setIsModalVisible(false);
    setSelectedLocation(null);
    if (map.current) {
      map.current.remove();
      map.current = null;
    }
  };
  // Helper function to copy text to clipboard
  const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    message.success('Copied ' + text + ' to clipboard!');
  };
  const handleClickDean = (device_id: any) => {
    setSelectedDeviceId(device_id);
    setModalVisibleDean(true);
  };
  const handleCloseModalDean = () => {
    setModalVisibleDean(false);
    setSelectedDeviceId('');
  };
  const handleCloseModalProfile = () => {
    setProfileVisible(false);
    setSelectedDeviceId('');
  };


  useEffect(() => {
    mapboxgl.accessToken = window.__RUNTIME_CONFIG__.ADINT_UI_MAPBOX_TOKEN;

    if (selectedLocation && isModalVisible && mapContainer.current) {
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/satellite-streets-v12',
        center: [selectedLocation.lon, selectedLocation.lat],
        zoom: 18,
      });

      new mapboxgl.Marker({ color: 'blue' })
        .setLngLat([selectedLocation.lon, selectedLocation.lat])
        .addTo(map.current);

      if (map.current) {
        const maptemp = map.current;
        if (currentPolygon && maptemp) {
          map.current.on('load', () => {
            maptemp.addSource('polygon', {
              type: 'geojson',
              data: currentPolygon,
            });
            maptemp.addLayer({
              id: 'polygon-layer',
              type: 'fill',
              source: 'polygon',
              layout: {},
              paint: {
                'fill-color': 'red',
                'fill-opacity': 0.1,
              },
            }, 'clusters');
            maptemp.addLayer({
              id: 'gl-draw-polygon-stroke',
              type: 'line',
              paint: {
                'line-color': '#dc0303',
                'line-width': 2,
              }
            }, 'clusters');
          });
        }
      }
    }
  }, [selectedLocation, isModalVisible, currentPolygon]);

  const handleProfiler = () => {
    if (!currentSession){
      return;
    }
    dispatch(setSessionId(currentSession.session_id.toString()));
    dispatch(setDeviceId(selectedRecord.device_id));
    dispatch(setSessionType('AREA'));
    dispatch(setProfileVisible(true));
  }

  const handleMenuClick: MenuProps['onClick'] = (e) => {
    dispatch(setProfileVisible(false));

    if (e.key == '1'){
      if (currentSession){
        dispatch(fetchTrajectory({sessionId: currentSession?.session_id, timeframe: null, deviceId: selectedRecord.device_id, sessionType: 'AREA' }));
      }
    }
    else if (e.key == '2'){
      dispatch(clearTrajectory());
    }
    else if (e.key == '3'){
      handleOpenModal(selectedRecord);
    }
    else if (e.key == '4'){
      handleClickDean(selectedRecord.device_id);
    }
    else if (e.key == '5'){
      handleProfiler();
    }
    else if (e.key == '6'){
      setTrajectoryType('follow');
    }

  };

  const items: MenuProps['items'] = [
    {
      label: 'Add Path Tracer',
      key: '1',
      icon: <RiseOutlined />,
    },
    {
      label: 'Remove Path Tracer',
      key: '2',
      icon: <DeleteOutlined />,
    },
    {
      label: 'Map Finder',
      key: '3',
      icon: <ShrinkOutlined />,
    },
    {
      label: 'Dean Request',
      key: '4',
      icon: <ZoomInOutlined />,
    },
    {
      label: 'Profiler',
      key: '5',
      icon: <UserOutlined />,
    },
    // {
    //   label: 'Follow Path tracer',
    //   key: '6',
    //   icon: <UserOutlined />,
    // }
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const columns: ProColumns<AnalyticsData>[] = [
    {
      dataIndex: 'index',
      valueType: 'indexBorder',
      width: 50,
      render: (text, record) => (<span className="td-content"
        style={{ display: 'block', alignItems: 'center', cursor: 'pointer', width: 50 }}
        onClick={() => dispatch(setSelectedRecord(record))} // Passing the device_id of the current row
      >
        {/* <PlusCircleOutlined twoToneColor="#52c41a" width={20} /> */}
        <Dropdown menu={menuProps} trigger={['click']}>
          <PlusCircleOutlined style={{ fontSize: 20 }}>
          </PlusCircleOutlined>
      </Dropdown>
      </span>
      ),
    },
    {
      title: 'Device ID',
      dataIndex: 'device_id',
      // copyable: true,
      // ellipsis: true,
      width: 120,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Lat/Lon',
      dataIndex: 'lat_lon',
      width: 90,
      ellipsis: true,
      render: (text, record) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(record.lat + "," +record.lon)}
        >
          {record.lat.toFixed(2)},{record.lon.toFixed(2)}
        </span>
      ),
    },
    {
      title: 'Time',
      dataIndex: 'ts',
      width: 160,
      render: (text, record) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(record.ts)}
        >
          {shortenText(record.ts)}
        </span>
      ),
    },
    {
      title: 'Device Type',
      dataIndex: 'device_type',
      // copyable: true,
      // ellipsis: true,
      width: 130,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'Brand',
      dataIndex: 'device_brand',
      width: 80,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'Model',
      dataIndex: 'device_model',
      ellipsis:true,
      width: 160,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'SSID',
      dataIndex: 'ssid',
      ellipsis:true,
      width: 160,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'BSSID',
      dataIndex: 'bssid',
      ellipsis:true,
      width: 160,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'RAT',
      dataIndex: 'rat',
      width: 90,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'ID Type',
      dataIndex: 'id_type',
      width: 80,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'App',
      dataIndex: 'app',
      ellipsis:true,
      width: 180,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text || '')}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'Ad URL',
      dataIndex: 'ad_url',
      ellipsis:true,
      width: 80,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text || '')}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      width: 60,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Age',
      dataIndex: 'age',
      width: 60,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Country',
      dataIndex: 'country',
      width: 80,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'IP',
      dataIndex: 'ip',
      width: 120,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'IP Type',
      dataIndex: 'ip_type',
      ellipsis: true,
      width: 100,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'ISP',
      dataIndex: 'isp',
      ellipsis: true,
      width: 100,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Browser',
      dataIndex: 'ua',
      width: 100,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text || '')}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'Carrier',
      dataIndex: 'carrier',
      width: 120,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text || '')}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'PLMN',
      dataIndex: 'plmn',
      width: 80,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'LAC/CID',
      dataIndex: 'long_cellid',
      width: 100,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {shortenText(text)}
        </span>
      ),
    },
    {
      title: 'Location Type',
      dataIndex: 'location_type',
      width: 100,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
  ];


  // Dynamically slice columns based on tableSize and ensure the action column is always last
  const visibleColumns = [
    ...(
      tableSize === 'small' ? columns.slice(0, 4)
        : tableSize === 'medium' ? columns.slice(0, 6)
          : tableSize === 'large' ? columns
            : columns.slice(0, 2)
    ),
  ];


  const handleGenerateGraphs = () => {
    if (currentSession) {
      dispatch(downloadReport({ sessionId: currentSession.session_id.toString(), sessionType: currentSession.session_type.toString() }));
    } else {
      message.error('No active session found');
    }
  };

  const handleDownloadData = () => {
    if (currentSession) {
      dispatch(downloadDataExport({ sessionId: currentSession.session_id.toString(), sessionType: currentSession.session_type.toString() }));
    } else {
      message.error('No active session found');
    }
  };

  return (
    <>
      <ProTable<AnalyticsData>
        columns={visibleColumns}
        dataSource={data}

        rowKey={(data: any, index: any) => index} // Use index as the key
        loading={analyticsLoading}
        scroll={tableSize == 'small' ? { y: height - 600 } : (tableSize == 'large') ? { y: height - 500 } : (tableSize == 'medium') ? { y: height - 500 } : {}}

        pagination={{
          position: ["topCenter"],
          current: currentPage, total: paginData?.total, pageSize: pageSize, onChange: (page, size) => {
            setCurrentPage(page);
            setPageSize(size); // Update pageSize state
          }
        }}
        search={{
          labelWidth: 'auto',
        }}
        request={async (params, sort, filter) => {
          const filters_temp = {
            gender: params.gender ? params.gender : null,
            rat: params.rat ? params.rat : null,
            device_id: params.device_id ? params.device_id : null,
            device_brand: params.device_brand ? params.device_brand : null,
            device_model: params.device_model ? params.device_model : null,
            ip: params.ip ? params.ip : null,
            app: params.app ? params.app : null,
            carrier: params.carrier ? params.carrier : null,
            ssid: params.ssid ? params.ssid : null,
            bssid: params.bssid ? params.bssid : null,
            id_type: params.id_type ? params.id_type : null,
            location_type: params.location_type ? params.location_type : null,
            lat: params.lat ? params.lat : null,
            lon: params.lon ? params.lon : null

          };
          setFilters(filters_temp);
          const hasActiveFilters = Object.values(filters_temp).some(value => value !== null);
          dispatch(setCurrentFilters(filters_temp));
          setAiReport(hasActiveFilters);
          if (currentSession) {
            dispatch(fetchData({ sessionId: currentSession.session_id.toString(), sessionType: currentSession.session_type.toString(), timeframe: null, pageSize: pageSize, page: currentPage, filters: filters_temp, orderBy:orderBy }));
            dispatch(fetchMarkers({ sessionId: currentSession.session_id.toString(), sessionType: currentSession.session_type.toString(), timeframe: null, filters: filters_temp }));

          }
          return data;
        }}
        options={{
          setting: {
            listsHeight: 400,
          },
        }}

        toolBarRender={() => [
          <>
            {
              (
                <Select
                  mode="tags"
                  style={{ width: 200 }}
                  placeholder="Order By"
                  onChange={setOrderBy}
                  options={[
                    {label: 'Time', value: 'ts'},
                    {label: 'RAT', value: 'rat'},
                    {label: 'APP', value: 'app'},
                    {label: 'Brand', value: 'device_brand'},
                    {label: 'Model', value: 'device_model'},
                    {label: 'IP', value: 'ip'},
                    {label: 'Gender', value: 'gender'},
                    {label: 'SSID', value: 'ssid'},
                    {label: 'BSSID', value: 'bssid'},
                    {label: 'PLMN', value: 'plmn'},
                    {label: 'Age', value: 'age'},
                    {label: 'Cellid', value: 'long_cellid'},
                    {label: 'ISP', value: 'isp'},
                    {label: 'IP Type', value: 'ip_type'},
                    {label: 'ID Type', value: 'id_type'},
                    {label: 'UA', value: 'ua'}
                  ]}
                />
              )
            }
            {isFiltersApplied ? (
              <Tooltip title="Generate AI Report based on Session's Data">
                <Button
                  key="generate-report"
                  loading={loading}
                  icon={<DownloadOutlined />}
                  type="primary"
                  onClick={handleGenerateGraphs}
                >
                  AI
                </Button>
              </Tooltip>
            ) : (
              <Tooltip title="No filters applied, unable to generate AI report">
                <Button
                  key="no-report"
                  type="primary"
                  icon={<DownloadOutlined />}

                  disabled={true}
                >
                  AI

                </Button>
              </Tooltip>
            )}
            {
              (
                <Tooltip title="Export Data">
                  <Button
                    key="export-data"
                    loading={loading}
                    icon={<DownloadOutlined />}
                    type="primary"
                    onClick={handleDownloadData}
                  >
                    
                  </Button>
                </Tooltip>
              )
            }
            
          </>,
        ]}
      />
      <Modal
        title="Location Details"
        open={isModalVisible}
        onCancel={handleCloseModal}
        footer={null}
        width={400}
      >
        <div
          ref={mapContainer}
          style={{ width: '100%', height: '400px', position: 'relative' }}
        />
      </Modal>
      <ProfileModal/>

      <DeanModal
        visible={modalVisibleDean}
        onClose={handleCloseModalDean}
        device_id={selectedDeviceId}
      />
    </>
  );
};

export default AnalyticsTable;