import { configureStore } from '@reduxjs/toolkit';
import userReducer from './slices/userSlice';
import uiReducer from './slices/uiSlice';
import authReducer from './slices/authSlice';
import sessionReducer from './slices/sessionSlice';
import identifyReducer from './slices/identifySlice';
import reportReducer from './slices/reportSlice';
import widgetReducer from './slices/widgetSlice';
import deanReducer from './slices/deanSlice';
import mapSettingsReducer from './slices/MapSettingsSlice';
import timeframeReducer from './slices/timeframeSlice';
import profileReducer from './slices/profileSlice';

export const store = configureStore({
  reducer: {
    queries: userReducer,
    ui: uiReducer,
    auth: authReducer,
    sessions: sessionReducer,
    identifySessions: identifyReducer,
    report: reportReducer,
    widgets: widgetReducer,
    dean:deanReducer,
    mapSettings: mapSettingsReducer,
    timeline: timeframeReducer,
    profile: profileReducer

  },
});
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectAuthLoading = (state: RootState) => state.auth.loading;
export const selectAuthError = (state: RootState) => state.auth.error;
export const selectUser = (state: RootState) => state.auth.user;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
