import React, { useState } from 'react';
import { Layout, Tabs } from 'antd';
import QueryForm from './QueryForm';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
const { Sider } = Layout;

const RightSidebar: React.FC<{ collapsed: boolean; onCollapse: (collapsed: boolean) => void;children:React.ReactNode; onFilterChange: (value: any) => void; }> = ({ collapsed,children, onCollapse, onFilterChange }) => {
  // State to keep track of the current tab
  const [currentTab, setCurrentTab] = useState<string>('1');

  // Handler to update the current tab
  const handleTabChange = (key: string) => {
    setCurrentTab(key);
  };

  // Determine the width based on the current tab
  const siderWidth = currentTab === '2' ? 'calc(100% - 300px)' : 400;

  return (
    <Sider
      width={siderWidth}
      style={{
        backgroundColor: 'rgba(16, 24, 39, 0.8)',
        padding: '10px',
        position: 'absolute',
        top: 45,
        right: 0,
        bottom: 0,
        zIndex: 10,
        overflowY: 'auto',
        opacity: !collapsed ? "0" : "1",
        transition: "all .2s",
        visibility: !collapsed ? "hidden" : "visible",
      }}
    >
      <Tabs
        defaultActiveKey="1"
        activeKey={currentTab}  // Set the active tab based on the state
        onChange={handleTabChange}  // Update state when tab changes
        items={[
          {
            label: 'Filters',
            key: '1',
            children: [children],
          }
        ]}
      />
    </Sider>
  );
};

export default RightSidebar;
