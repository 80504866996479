import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface UISliceState {
  rightSidebar: boolean;
  leftSidebar: boolean;
  bottomSidebar:boolean;
  taskSidebar: boolean;
  polygon: any;
  panningAreas: any;
  multipolygon:any;
  filters: any;
  identifySidebar:boolean;
  timerange: any;
  analyticsSidebar:any;
  createType:string | null;
  currentFilters:any;
  activeSession:any;
  mapref:any;
  queryForm:boolean;
  featureId:string;
  activeseg:string;
  activeDraw: string;
  zoomLvl: number;
  sessionId: any;
  brushTimeRange: any;
  enableDrawing: boolean;
}

const initialState: UISliceState = {
  enableDrawing: false,
  rightSidebar: false,
  leftSidebar: false,
  bottomSidebar: false,
  analyticsSidebar: false,
  identifySidebar:false,
  taskSidebar: false,
  createType:null,
  polygon: null,
  multipolygon:null,
  filters: null,
  timerange: null,
  activeSession: 'Area Search',
  currentFilters: null,
  mapref:null,
  queryForm:false,
  zoomLvl:3,
  featureId:'',
  activeseg:'Static',
  activeDraw: 'Single Area',
  panningAreas: null,
  sessionId: null,
  brushTimeRange: null
};

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    setEnableDrawing(state, action: PayloadAction<any>) {
      state.enableDrawing = action.payload;
    },
    setBrushTimeRange(state, action: PayloadAction<any>) {
      state.brushTimeRange = action.payload;

    },
    toggleRightSidebar(state,action: PayloadAction<boolean>) {
      state.rightSidebar = action.payload;
    },
    toggleLeftSidebar(state) {
      state.leftSidebar = !state.leftSidebar;
    },
    toggleBottomSidebar(state) {
      state.bottomSidebar = !state.bottomSidebar;
    },
    toggleAnalyticsSidebar(state,action: PayloadAction<boolean>) {
      state.analyticsSidebar =  action.payload;
    },
    toggleIdentifySidebar(state,action: PayloadAction<boolean>) {
      state.identifySidebar =  action.payload;
    },
    toggleTaskSidebar(state,action: PayloadAction<boolean>) {
      state.taskSidebar =  action.payload;
    },
    setPolygon(state, action: PayloadAction<any>) {
      state.polygon = action.payload;
    },
    setPanningAreas(state, action: PayloadAction<any>) {
      state.panningAreas = action.payload;
    },
    setSelectedSessionId(state, action: PayloadAction<any>) {
      state.sessionId = action.payload;
    },
    setMultiPolygon(state, action: PayloadAction<any>) {
      state.multipolygon = action.payload;
    },
    setCurrentFilters(state, action: PayloadAction<any>) {
      state.currentFilters = action.payload;
    },
    setSegm(state, action: PayloadAction<any>) {
      state.activeseg = action.payload;
    },
    setZoomLvl(state, action: PayloadAction<any>) {
      state.zoomLvl = action.payload;
    },
    setFilters(state, action: PayloadAction<any>) {
      state.filters = action.payload;
    },
    setFeatureId(state, action: PayloadAction<any>) {
      state.featureId = action.payload;
    },
    setTimeRange(state, action: PayloadAction<any>) {
      state.timerange = action.payload;
    },
    setMapRef(state, action: PayloadAction<any>) {
      state.mapref = action.payload;
    },
    setActiveSession(state,action: PayloadAction<any>) {
      state.activeSession = action.payload;
    },
    setDraw(state,action: PayloadAction<any>) {
      state.activeDraw = action.payload;
    },
    setCreateType(state,action: PayloadAction<any>) {
      state.createType = action.payload;
    },
    resetUI(state) {
      state.polygon = null;
      state.panningAreas = null;
      state.sessionId = null;
    },
    resetQueryForm(state,action: PayloadAction<boolean>) {
      state.queryForm =  action.payload;
    },
    resetSession(state) {
      state.polygon = null;
      state.sessionId = null;
      state.panningAreas = null;
      state.identifySidebar = false;
      state.queryForm = false;
      state.analyticsSidebar = false;
      state.currentFilters=null;
      state.createType = null;
      state.activeseg = 'Static';
      state.activeDraw = 'Single Area';
      state.multipolygon = null;
    },
  },
});

export const { 
  toggleRightSidebar, 
  toggleLeftSidebar, 
  setPolygon, 
  setPanningAreas,
  setMultiPolygon,
  setDraw,
  setFilters, 
  setZoomLvl,
  resetSession,
  setTimeRange, 
  setFeatureId,
  setCurrentFilters,
  setSegm,
  resetQueryForm,
  setCreateType,
  toggleBottomSidebar,
  toggleIdentifySidebar,
  toggleAnalyticsSidebar,
  setActiveSession,
  setEnableDrawing,
  setMapRef,
  toggleTaskSidebar,
  resetUI,
  setSelectedSessionId,
  setBrushTimeRange
} = uiSlice.actions;

export default uiSlice.reducer;
