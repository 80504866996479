import React, { useState, useEffect, useRef } from 'react';
import { Flex, List, Progress, Row, Divider ,Card, Statistic, Table, Col, Drawer, Tag, Button, message, Space, Descriptions, Avatar, Tooltip } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import { selectProfileData, selectProfileVisible } from '../store/selectors/profileSelectors';
import { fetchProfile, setProfileVisible, setLoading } from '../store/slices/profileSlice';
import { setZoomTo } from '../store/slices/sessionSlice';

import {
    BarChart, Bar, LineChart, Line, ScatterChart, Scatter, Treemap,
    CartesianGrid, XAxis, YAxis, Legend, PieChart, Pie, PolarAngleAxis, PolarGrid, PolarRadiusAxis, Radar, RadarChart, Cell
} from 'recharts';
import type { StatisticProps } from 'antd';
import CountUp from 'react-countup';
import { AntDesignOutlined, UserOutlined } from '@ant-design/icons';
import VirtualList from 'rc-virtual-list';
import mapboxgl from 'mapbox-gl';
import { selectMapRef } from '../store/selectors/uiSelectors';
import { shortenText } from '../utils/mapUtils';
import facebook_logo from "../assets/services/facebook.png"
import instagram_logo from "../assets/services/instagram.png"
import whatsapp_logo from "../assets/services/whatsapp.png"
import linkedin_logo from "../assets/services//linkedin.png"
import google_logo from "../assets/services/google.png"
import microsoft_logo from "../assets/services/microsoft.png"
import telegram_logo from "../assets/services/telegram.png"
import DeanModal from './DeanModal';

const ProfileModal: React.FC = ({ }) => {
const { height, width } = useWindowDimensions();
const visible = useSelector((state: RootState) => state.profile.visible); 
const loading = useSelector((state: RootState) => state.profile.loading); // Loading state for the report
const deviceId = useSelector((state: RootState) => state.profile.deviceId); 
const sessionId = useSelector((state: RootState) => state.profile.sessionId); 
const sessionType = useSelector((state: RootState) => state.profile.sessionType); 
const map = useRef<mapboxgl.Map | null>(null);
const mapContainer = useRef<HTMLDivElement | null>(null);
const [modalVisibleDean, setModalVisibleDean] = useState(false);

const comap = useRef<mapboxgl.Map | null>(null);
const comapContainer = useRef<HTMLDivElement | null>(null);

const analyticsSidebar = useSelector((state: RootState) => state.ui.analyticsSidebar);
const identifySidebar = useSelector((state: RootState) => state.ui.identifySidebar);
const profileData = useSelector(selectProfileData);

const dispatch = useDispatch<AppDispatch>();
const [appsUsed, setAppsUsed] = useState<any>(null);
const barWidth = width / 3.5;
const barHeight = height / 1.23;
var currentMarkers:Array<any> = [];
let lat = 3.13;
let lon = 34.13;

const formatter: StatisticProps['formatter'] = (value) => (
    <CountUp end={value as number} separator="," />
);

const handleClickDean = (device_id: any) => {
  setModalVisibleDean(true);
};
const handleCloseModalDean = () => {
  setModalVisibleDean(false);
};
const copyToClipboard = (text: any) => {
    navigator.clipboard.writeText(text);
    message.success('Copied ' + text + ' to clipboard!');
};


const openInNewTab = (url:any) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
}

const handleFindOnMap = (lat: number, lon:number) => {
    dispatch(setZoomTo({lat:lon, lon:lat}))
  };


const activityColumns = [
    {
      title: 'Coords',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(record.lat + "," + record.lon)}
        >
          {record.lat.toFixed(2) + "," + record.lon.toFixed(2)}
        </span>
      ),
    },
    {
        title: 'Type',
        dataIndex: 'possible_activity',
        render: (text: any, record: any) => (
          <span className="td-content"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => copyToClipboard(text)}
          >
            {text}
          </span>
        ),
      },
    {
      title: 'View',
      key: 'device_id',
      render: (_: any, record: any) => (
        <Button
          type="primary"
          onClick={() => handleFindOnMap(record.lon, record.lat)}
        >
          Find
        </Button>
      ),
    },
  ];

  const travellerColumns = [
    {
        title: 'Device Id',
        render: (text: any, record: any) => (
        <span className="td-content"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => copyToClipboard(record.device_id)}
          >
            {record.device_id}
          </span>
        ),
      },
    {
      title: 'Coords',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(record.lat + "," + record.lon)}
        >
          {record.lat.toFixed(2) + "," + record.lon.toFixed(2)}
        </span>
      ),
    },
    {
        title: 'Timestamp',
        dataIndex: 'ts',
        render: (text: any, record: any) => (
          <span className="td-content"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            onClick={() => copyToClipboard(record.ts)}
          >
            {text}
          </span>
        ),
    },
    {
      title: 'View',
      render: (_: any, record: any) => (
        <Button
          type="primary"
          onClick={() => handleFindOnMap(record.lon, record.lat)}
        >
          Find
        </Button>
      ),
    },
  ];

  const socialColumns = [
    {
        title: 'Avatar',
        key: 'avatar',
        width: 100,
        render: (text: any, record: any) => (
            <Avatar.Group>
            <Avatar size={48} src={record.avatar} />
            </Avatar.Group>
        ),
      },
    {
      title: 'Profile URL',
      dataIndex: 'profile_url',
      ellipsis: true,
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
        title: 'Service',
        dataIndex: 'service',
        width: 100,
        key: 'username',
        render: (text: any) => {
          if (text.toLowerCase() == 'instagram'){
            return (<Avatar size={38} src={instagram_logo} alt="instagram"/>)
          }else if (text.toLowerCase() == 'facebook'){
            return (<Avatar size={38} src={facebook_logo} alt="instagram"/>)
          }else if (text.toLowerCase() == 'telegram'){
            return (<Avatar size={38} src={telegram_logo} alt="instagram"/>)
          }else if (text.toLowerCase() == 'whatsapp'){
            return (<Avatar size={38} src={whatsapp_logo} alt="instagram"/>)
          }else if (text.toLowerCase() == 'microsoft' || text.toLowerCase() == 'micrososft'){
            return (<Avatar size={38} src={microsoft_logo} alt="instagram"/>)
          }else if (text.toLowerCase() == 'linkedin'){
            return (<Avatar size={38} src={linkedin_logo} alt="instagram"/>)
          }else if (text.toLowerCase() == 'google'){
            return (<Avatar size={38} src={google_logo} alt="instagram"/>)
          }          
        }
    },
    {
        title: 'Username',
        dataIndex: 'username',
        render: (text: any, record: any) => (
          <span className="td-content"
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          >
            {text}
          </span>
        ),
    },
    {
      title: 'View',
      width: 160,
      render: (_: any, record: any) => (
        <Button
          type="primary"
          onClick={() => openInNewTab(record.profile_url)}
        >
          Visit Profile
        </Button>
      ),
    },
  ];

  
  const ipColumns = [
    {
      title: 'IP',
      dataIndex: 'field',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Total Events',
      dataIndex: 'count',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {text}
        </span>
      ),
    }
  ];
  const ssidColumns = [
    {
      title: 'SSID',
      dataIndex: 'field',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
          onClick={() => copyToClipboard(text)}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Total Events',
      dataIndex: 'count',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {text}
        </span>
      ),
    }
  ];
  const keywordColumns = [
    {
      title: 'Name',
      dataIndex: 'field',
      render: (text: any, record: any) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {record}
        </span>
      ),
    },
  ];
// useEffect(() => {    
//     mapboxgl.accessToken = window.__RUNTIME_CONFIG__.ADINT_UI_MAPBOX_TOKEN;
    
//     if (!map.current && mapContainer.current){
//         if (profileData&&profileData?.activities.length != 0){
//             lat = profileData?.activities[0]?.lat;
//             lon = profileData?.activities[0]?.lon;
//         }
//         map.current = new mapboxgl.Map({
//             container: mapContainer.current,
//             style: 'mapbox://styles/mapbox/satellite-streets-v12',
//             center: [lat, lon],
//             zoom: 12,
//         });
//     }
    
//     if (!comap.current && comapContainer.current){
//         if (profileData&&profileData?.co_travellers.length != 0){
//         lat = profileData?.co_travellers[0]?.lat;
//         lon = profileData?.co_travellers[0]?.lon;
//     }
//         comap.current = new mapboxgl.Map({
//             container: comapContainer.current,
//             style: 'mapbox://styles/mapbox/satellite-streets-v12',
//             center: [lat, lon],
//             zoom: 12,
//         });
//     }
// }, []);


useEffect(() => {    
    if (deviceId && deviceId != '') {
        dispatch(setLoading(true));
        dispatch(fetchProfile({ deviceId: deviceId, sessionId: sessionId, sessionType: sessionType }));
        cleanUpMarkers();
    }
}, [deviceId]);


const addActivityMarkers = (map: any) => {
    profileData?.activities.forEach(activity => {     
        var popup = new mapboxgl.Popup()
        .setText(activity.possible_activity)
        .addTo(map.current);
        var marker = new mapboxgl.Marker({ color: 'cyan' })
        .setLngLat([activity.lat, activity.lon])
        .addTo(map.current).setPopup(popup);
        currentMarkers.push(marker);
    });
}

const addCoTravellersMarkers = (map: any) => {
    profileData?.co_travellers.forEach(traveller => {     
        var popup = new mapboxgl.Popup()
        .setText(traveller.device_id)
        .addTo(map.current);
        var marker = new mapboxgl.Marker({ color: 'cyan' })
        .setLngLat([traveller.lat, traveller.lon])
        .addTo(map.current).setPopup(popup);
        currentMarkers.push(marker);
    });
}

const cleanUpMarkers = () => {
    currentMarkers.forEach(item => {
        item.remove();
    })
    currentMarkers = [];
}

const COLORS = ['#DB5202', '#289C9C', '#864F2E','#2F5C5C'];

return (
  
        <Drawer 
            title={"Device Profile"}
            open={visible}
            maskClosable={true}
            onClose={()=> {dispatch(setProfileVisible(false))}}        
            mask={false}
            placement='right'
            loading={loading}
            closable
            zIndex={2}
            contentWrapperStyle={{ boxShadow: 'none' }}
            size='default'
            autoFocus={true}
            destroyOnClose
            style={{
                backgroundColor: 'rgba(16, 24, 39, 1)',
                // padding: '10px',
                position: 'absolute',
                top: 45,
                right: (analyticsSidebar || identifySidebar ? + 490 : 0),
                bottom: 0,
                width: barWidth,
                boxShadow: 'none',
                opacity: 1,
                height: barHeight,
                overflowY: 'hidden',
                transition: "all .1s",
            }}
            footer={[
            ]}
            >
            <Descriptions layout="horizontal">
            <Descriptions.Item  span={4}>
                <Space direction="horizontal" size={16}>
                    <Space wrap size={2}>
                        <Card title="User" style={{fontSize:18}}>
                            <Col>
                                <Avatar.Group>
                                {profileData&& profileData?.social_media.length !=0 && <Avatar size={100} src={profileData.social_media[0].avatar} />}
                                {profileData&& profileData?.social_media.length ==0 && <Avatar size={100} icon={<UserOutlined />} />}
                                </Avatar.Group>
                            </Col>
                            <Col style={{marginTop: 20}}>
                            <Tag style={{right: 5}}>Age</Tag>
                                {profileData && <Tag style={{fontSize: '1.3em'}} color="cyan">{profileData.age} </Tag>}
                                
                                
                            </Col>
                            <Col style={{marginTop: 10}}>
                            <Tag style={{right: 5}}>Gender</Tag>
                                {profileData &&  <Tag style={{fontSize: '1.3em'}} color="cyan">{profileData.gender} </Tag>}
                            </Col>

                            <Col style={{marginTop: 10}}>
                            <Tag style={{right: 5}}>Total Events</Tag>
                                <Statistic value={profileData?.total_events} formatter={formatter} style={{marginTop: 10}}/>
                            </Col>

                        </Card>
                    </Space>
                    <Space wrap size={8} style={{fontSize:18}}>
                        <Card title="Personal Information" >
                            <Row >
                                <Space wrap size={4} >
                                <Tag>Name</Tag>
                                    {profileData?.name}   
                                </Space>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Space wrap size={4} style={{marginTop: 20}} onClick={() => copyToClipboard(profileData?.device_id)}
                                >
                                    <Tag>Device ID</Tag>
                                    {profileData?.device_id}
                                </Space>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Space wrap size={4} style={{marginTop: 20}}>
                                <Tag>Phone Number</Tag>
                                    {profileData?.msisdn}
                                </Space>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Space wrap size={4} style={{marginTop: 20}}>
                                <Tag>Email</Tag>
                                    {profileData?.email}
                                </Space>
                            </Row>
                            <Row style={{marginTop: 10}}>
                                <Space wrap size={4}>
                                <Flex gap="4px 0" wrap >
                                    {
                                    profileData?.dean_status == 'COMPLETED' &&
                                    <Progress style={{marginTop: 0, fontSize: 18, fontWeight: 300}} strokeColor='#03dcdc' percent={100} percentPosition={{ align: 'center', type: 'inner' }} size={[barWidth/3, 20]} format={() => 'De-anonymized'}/>
                                    }
                                    {
                                    profileData?.dean_status == 'PENDING' &&
                                    <Progress style={{marginTop: 0, fontSize: 18, fontWeight: 300}} percent={50} percentPosition={{ align: 'center', type: 'inner' }} size={[barWidth/3, 20]} format={() => 'Pending Dean'}/>
                                    }
                                    {
                                    !profileData?.dean_status &&
                                    <Button
                                      key="dean-modal"
                                      type="primary"
                                      onClick={handleClickDean}
                                    >
                                      Create a Dean Request
                                    </Button>
                                    }
                                  </Flex>
                                </Space>
                            </Row>
                            <Row style={{marginTop: 0}}>
                                <Space wrap size={4} style={{marginTop: 18}}>
                                <Flex gap="4px 0" wrap >

                                  <Tag>Carrier</Tag>
                                    {profileData?.carriers.length !=0 && profileData?.carriers[0].field}
                                 <Tag>Browser</Tag>
                                    {profileData?.browsers.length !=0 && profileData?.browsers[0].field}
                                <Tag>Device</Tag>
                                    {profileData?.devices.length !=0 && profileData?.devices[0].field}
                                </Flex>

                                </Space>
                            </Row>
                        </Card>
                    </Space>
                    <Space direction="horizontal" size={8}>
                        <Card title="Activity Identification">
                            <Table
                             style={{position: 'relative',  height: 220, width: width/14}}
                            dataSource={profileData?.activities}
                            columns={activityColumns}
                            rowKey="device_id" 
                            pagination={false} 
                            />

                        </Card>
                    </Space>
                </Space>
            </Descriptions.Item>
            <Descriptions.Item span={4}>
                <Space direction="horizontal" size={16}>
                <Card title="Social Media">
                    <Table
                        style={{position: 'relative', width: width / 4}}
                    dataSource={profileData?.social_media}
                    columns={socialColumns}
                    pagination={false}
                    />

                </Card>
                </Space>
            </Descriptions.Item>
            <Descriptions.Item span={4}>
                <Space direction="horizontal" size={16}>
                <Card title="Relations">
                    <Table
                        style={{position: 'relative', width: width / 4}}
                    dataSource={profileData?.relationships}
                    columns={socialColumns}
                    pagination={false}
                    />

                </Card>
                </Space>
            </Descriptions.Item>
            <Descriptions.Item span={4}>
                <Space direction="horizontal" size={16}>
                <Card title="Co Travellers View">
                    <Table
                        style={{position: 'relative', width: width / 6}}
                    dataSource={profileData?.co_travellers}
                    columns={travellerColumns}
                    pagination={false} 
                    />

                </Card>
                </Space>
                <Space direction="horizontal" size={16}>
                <Card title="Keywords & Hobbies">
                    <Table
                        style={{position: 'relative', width: width / 14}}
                    dataSource={profileData?.keywords}
                    columns={keywordColumns}
                    pagination={false} 
                    />

                </Card>
                </Space>
            </Descriptions.Item>
            <Descriptions.Item span={4}>
                <Space direction="horizontal" size={8}>
                <Card title="IP Usage">
                    <Table
                        style={{position: 'relative', width: width / 8.4}}
                    dataSource={profileData?.ips}
                    columns={ipColumns}
                    rowKey="field" 
                    pagination={false} 
                    />

                </Card>
                </Space>
                <Space direction="horizontal" size={8}>
                <Card title="SSID Usage">
                    <Table
                        style={{position: 'relative', width: width / 8.4}}
                    dataSource={profileData?.ssids}
                    columns={ssidColumns}
                    rowKey="field" 
                    pagination={false} 
                    />

                </Card>
                </Space>
            </Descriptions.Item>
            <Descriptions.Item span={4}>
                <Space direction="horizontal" size={16}>
                    <Card title="APP Usage" >
                    <PieChart width={width / 8.4} height={350}>
                      <Tooltip />
                      <Legend />
                        <Pie
                        data={profileData?.apps}
                        dataKey="count"
                        nameKey="field"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                        >
                        {profileData?.apps.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    </Card>
                </Space>
                <Space direction="horizontal" size={16}>

                    <Card title="CellId Usage" >
                    <PieChart width={width / 8.4} height={350} >
                        <Tooltip  />
                        <Legend />
                        <Pie
                        data={profileData?.cellids}
                        dataKey="count"
                        nameKey="field"
                        cx="50%"
                        cy="50%"
                        outerRadius={100}
                        label
                        >
                        {profileData?.cellids.map((entry, index) => (
                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                        ))}
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    </Card>
                </Space>
            </Descriptions.Item>
          
            </Descriptions>
            {profileData?.device_id && <DeanModal
        visible={modalVisibleDean}
        onClose={handleCloseModalDean}
        device_id={profileData?.device_id}
      />}
        </Drawer>
        
    );
};

export default ProfileModal;
