import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { dummydata } from './dummydata';
import { DataQuery } from '../../interfaces';
// export const fetchDataQuerys = createAsyncThunk('queries/fetchDataQuerys', async () => {
//   const response = await axios.get('https://dummyjson.com/queries');
//   console.log(response,response.data,response.data['queries'])
//   return response.data['queries'];
// });

// Define the interface for the user data based on the provided structure


interface DataQueryState {
  queries: DataQuery[];
  loading: boolean;
  error: string | null;
}

// Modify the initial state to match the new data structure
const initialState: DataQueryState = {
  queries: [],
  loading: false,
  error: null,
};

// Modify the fetchDataQuerys thunk to use the dummydata variable
export const fetchDataQuerys = createAsyncThunk('queries/fetchDataQuerys', async () => {
  // Simulate fetching data from the dummydata variable
  return dummydata.map((user,index) => ({
    id: index,
    name: user.name,
    timeframe: user.timeframe,
    area_search: user.area_search,
    filters: user.filters,
  }));
});

const userSlice = createSlice({
  name: 'queries',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchDataQuerys.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDataQuerys.fulfilled, (state, action) => {
        state.loading = false;
        state.queries = action.payload;
      })
      .addCase(fetchDataQuerys.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch queries';
      });
  },
});

export default userSlice.reducer;