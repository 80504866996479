import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Form, Input, Typography, Alert, Layout, Card, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { AppDispatch, RootState } from '../store';
import { fetchUserInfo, login } from '../store/slices/authSlice';
import logo from '../assets/edge-logo2.png';

const { Title } = Typography;

const Login: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const { token, loading, error } = useSelector((state: RootState) => state.auth);

    useEffect(() => {
        if (token) {
            navigate('/');
        }
    }, [token, navigate]);

    const onFinish = async (values: { username: string; password: string; remember: boolean }) => {
        const result = await dispatch(login(values));
        if (login.fulfilled.match(result)) {
            // Save token to localStorage if "Remember Me" is checked
            if (values.remember) {
                localStorage.setItem('token', result.payload);
            } else {
                sessionStorage.setItem('token', result.payload);
            }
            navigate('/');
        }
    };

    return (
        <Layout style={{ height: '100vh', width: '100%', position: 'relative' }}>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Card style={{ width: '350px' }}>
                    <img width={200} style={{ display: 'flex', padding: '16px 0', margin: '0 auto' }} src={logo} alt="Logo" />

                    {error && <Alert message={error} type="error" showIcon style={{ marginBottom: 24 }} />}
                    
                    <Form
                        name="login"
                        onFinish={onFinish}
                        layout="vertical"
                        autoComplete="off"
                    >
                        <Form.Item
                            name="username"
                            label="Username"
                            style={{marginBottom:6}}
                        >
                            <Input required placeholder="Username" />
                        </Form.Item>
                        
                        <Form.Item
                            name="password"
                            label="Password"
                            style={{marginBottom:6}}

                        >
                            <Input.Password required placeholder="Password" />
                        </Form.Item>
                        
                        <Form.Item name="remember" valuePropName="checked" style={{ marginBottom: '15px' }}>
                            <Checkbox>Remember Me</Checkbox>
                        </Form.Item>
                        
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={loading} block>
                                {loading ? 'Logging in...' : 'Login'}
                            </Button>
                        </Form.Item>
                    </Form>
                </Card>
            </div>
        </Layout>
    );
};

export default Login;
