import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Badge, Space, Avatar, message, Input } from 'antd';
import { ProTable, ProColumns } from '@ant-design/pro-components';
import { fetchAlerts } from '../store/slices/authSlice';
import { selectAlertData, selectAlertLoading } from '../store/selectors/authSelectors';
import { AppDispatch } from '../store';

import useWindowDimensions from "../hooks/useWindowsDimensions";

import {
    PlusCircleOutlined
  } from '@ant-design/icons';
const AlertsTable: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();
  const data = useSelector(selectAlertData);
  const loading = useSelector(selectAlertLoading);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [pageSize, setPageSize] = useState<number>(100); // Number of cards per page
  const { height } = useWindowDimensions();

  useEffect(() => {
    dispatch(fetchAlerts({page: currentPage, pageSize: pageSize}));
  }, [currentPage, pageSize]);


  const columns: ProColumns[] = [
    {
      title: 'Event',
      dataIndex: 'event',
      // copyable: true,
      // ellipsis: true,
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Message',
      dataIndex: 'msg',
      key: 'msg',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'Created At',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {text}
        </span>
      ),
    },
    {
      title: 'User',
      dataIndex: 'user',
      key: 'user',
      render: (text) => (
        <span className="td-content"
          style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
        >
          {text}
        </span>
      ),
    }
  ];

  return (
      <>
    <ProTable<any>
      columns={columns}
      dataSource={data?.items}
      loading={loading}
      scroll={{ y: height /8 }}
      rowKey={(data: any, index: any) => index} // Use index as the key
      search={false}
      pagination={{
        current: currentPage, total: data?.total, pageSize: pageSize, onChange: (page, size) => {
          setCurrentPage(page);
          setPageSize(size); // Update pageSize state
        }
      }}
    />
    </>
  );
};

export default AlertsTable;
