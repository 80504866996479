import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  fetchSessions,
  setCurrentSession,
  fetchMarkers,
  setInitialMarkers,
  fetchAnalyticsData,
  clearSession,
  setMarkers,
  fetchData,
  removeMarkers,
  fetchTasks,
  setDrawOptions,
  setSessionTaskType,
  setCurrentTask,
} from "../store/slices/sessionSlice";
import {
  clearIdentifySession,
  fetchIdentifySessionData,
  fetchIdentifySessions,
  setCurrentIdentifySession,
  setInitialIdentifyMarker,
} from "../store/slices/identifySlice"; // New imports for identify sessions
import {
  selectSessions,
  selectSessionsLoading,
  selectSessionsError,
  selectAddedSession,
  selectMarkers,
  selectPaginationSessions,
  selectTaskLoading,
  selectCompletedTasks,
  selectRunningTasks,
  selectPendingTasks,
  selectCurrentTask
} from "../store/selectors/sessionSelectors";
import {
  selectIdentifySessions,
  selectIdentifySessionsLoading,
  selectIdentifySessionsError,
  selectCurrentIdentify,
  selectIdentifySessionsPagination,

} from "../store/selectors/identifySelectors"; // New selectors for identify sessions
import { RootState, AppDispatch } from "../store";
import FlippingCard from "./FlippingCard";
import TaskCard from "./TaskCard";

import { Tag, Card, Row, Col, Spin, Tabs, Pagination, notification, Button, Select, Space, Input } from "antd";
import {
  PlusCircleOutlined,
  ReloadOutlined
} from '@ant-design/icons';
import {
  resetSession,
  resetUI,
  setActiveSession,
  setCreateType,
  setFeatureId,
  setPolygon,
  setSegm,
  toggleAnalyticsSidebar,
  toggleBottomSidebar,
  toggleIdentifySidebar,
  toggleRightSidebar,
  setPanningAreas,
  setEnableDrawing,
  setSelectedSessionId,
  toggleTaskSidebar,
} from "../store/slices/uiSlice";
import { selectEnableDrawing } from '../store/selectors/uiSelectors';
import useWindowDimensions from "../hooks/useWindowsDimensions";
import IdentifyFlippingCard from "./IdentifyFlippingCard";
import { activeMapBtn, currentFeatureId, selectActiveSession } from "../store/selectors/uiSelectors";
import { setMarkerStyle } from "../store/slices/MapSettingsSlice";
import { selectMarkerStyle } from "../store/selectors/mapSettingsSelectors";
import { setProfileVisible}  from '../store/slices/profileSlice'

const SessionList: React.FC = () => {
  const dispatch = useDispatch<AppDispatch>();

  // Area Search Sessions
  const sessions = useSelector(selectSessions);

  const tasksCompleted = useSelector(selectCompletedTasks);
  const tasksRunning = useSelector(selectRunningTasks);
  const tasksPending = useSelector(selectPendingTasks);

  const [pendingPage, setCurrentPendingPage] = useState<number>(1);
  const [runningPage, setCurrentRunningPage] = useState<number>(1);
  const [completedPage, setCurrentCompletedPage] = useState<number>(1);

  const current_session = useSelector(selectAddedSession);
  const paginSessions = useSelector(selectPaginationSessions);
  const currentTab = useSelector(selectActiveSession);

  // Identify Sessions
  const identifySessions = useSelector(selectIdentifySessions);
  const paginIdentifySessions = useSelector(selectIdentifySessionsPagination);

  const markers = useSelector(selectMarkers);
  const loading = useSelector(selectSessionsLoading);

  const currentTaskSession = useSelector(selectCurrentTask);
  const loadingIdentify = useSelector(selectIdentifySessionsLoading); // Loading state for identify sessions
  const loadingTask = useSelector(selectTaskLoading);

  const error = useSelector(selectSessionsError);
  const errorIdentify = useSelector(selectIdentifySessionsError); // Error state for identify sessions
  const segActive = useSelector(activeMapBtn); // Error state for identify sessions
  const markerStyle = useSelector(selectMarkerStyle); // Error state for identify sessions

  const [active, setActive] = useState<string>("");
  const [activeIdentify, setActiveIdentify] = useState<string>("");

  const featureId = useSelector(currentFeatureId); // Error state for identify sessions

  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentIdentifyPage, setCurrentIdentifyPage] = useState<number>(1); // Current page for identify sessions
  const [taskType, setTaskType] = useState<string | null>(null); // Current page for Task Geofence etc sessions

  const [searchText, setSearchText] = useState<string>("");
  const [searchIdentifyText, setIdentifySearchText] = useState<string>("");
  const [searchTaskText, setTaskSearchText] = useState<string>("");

  const { height } = useWindowDimensions();
  const [pageSize, setPageSize] = useState<number>(20); // Number of cards per page
  const [taskPageSize, setTaskPageSize] = useState<number>(Math.round(height / 300)); // Number of cards per page
  const [identifyPageSize, setIdentifyPageSize] = useState<number>(20); // Number of cards per page

  const [isButtonActive, setIsButtonActive] = useState(false);
  const enableDrawing = useSelector(selectEnableDrawing);


  const tabTitles: any = {
    1: "Area Search",
    2: "Identify",
    3: "Monitor"
  };

  useEffect(() => {
    dispatch(setEnableDrawing(true));
    dispatch(setDrawOptions(['Single', 'Multi', 'Border']));
  }, []);

  useEffect(() => {
    dispatch(fetchTasks({ page: completedPage, pageSize: taskPageSize, search_filter: searchTaskText, status: 'COMPLETED'}));

  }, [dispatch, completedPage]);

  useEffect(() => {
    dispatch(fetchTasks({ page: runningPage, pageSize: taskPageSize, search_filter: searchTaskText, status: 'RUNNING'}));

  }, [dispatch,  runningPage]);
  
  useEffect(() => {
    dispatch(fetchTasks({ page: pendingPage, pageSize: taskPageSize, search_filter: searchTaskText, status: 'PENDING'}));

  }, [dispatch, pendingPage]);

  useEffect(() => {
    dispatch(fetchSessions({ page: currentPage, pageSize: pageSize, search_filter: searchText}));
    dispatch(fetchIdentifySessions({ page: currentIdentifyPage, pageSize: pageSize, search_filter: searchIdentifyText}));
    dispatch(fetchTasks({ page: completedPage, pageSize: taskPageSize, search_filter: searchTaskText, status: 'COMPLETED'}));
    dispatch(fetchTasks({ page: runningPage, pageSize: taskPageSize, search_filter: searchTaskText, status: 'RUNNING'}));
    dispatch(fetchTasks({ page: pendingPage, pageSize: taskPageSize, search_filter: searchTaskText, status: 'PENDING'}));

  }, [dispatch, currentPage, currentIdentifyPage, pageSize]);


  useEffect(() => {
    if (height) {
      // setPageSize(Math.round(height / 300));
      setTaskPageSize(Math.round(height / 300));
      // setIdentifyPageSize(Math.round(height / 200));
    }
  }, [height]);

  const activateCard = (session_id: string, polygon: any) => {
    setActiveIdentify("");
    dispatch(clearIdentifySession());

    if (segActive == 'Drag Edit') {
      setActive('');
    }
    if (segActive == 'Static' && featureId != '') {
      setActive('');
    }
    if (active === "" || active !== session_id) {
      setActive(session_id);
      setActiveIdentify("");
      const selectedSession = sessions.find(
        (session) => session.session_id === session_id
      );
      if (selectedSession) {
        dispatch(setProfileVisible(false));
        dispatch(clearSession());
        dispatch(setCurrentIdentifySession(null));
        dispatch(setCurrentSession(selectedSession));
        dispatch(setPolygon(polygon));
        dispatch(setPanningAreas(selectedSession.panning_areas));
        dispatch(setSelectedSessionId(selectedSession.session_id));
        dispatch(fetchMarkers({ sessionId: session_id, sessionType: 'AREA', timeframe: null, filters: selectedSession.filters }));
        dispatch(fetchAnalyticsData({ sessionId: session_id, sessionType: 'AREA' }));
        dispatch(toggleBottomSidebar());
        dispatch(toggleAnalyticsSidebar(true));
        dispatch(toggleIdentifySidebar(false))
        dispatch(toggleRightSidebar(false));
        dispatch(setCreateType(null));
        setIsButtonActive(false);
        setActiveIdentify("");
        dispatch(setSegm('Static'));
        dispatch(setMarkerStyle(markerStyle));

        dispatch(setFeatureId(''));

      } else {
        return;
      }
    }
    else if (featureId != '') {
      const selectedSession = sessions.find(
        (session) => session.session_id === session_id
      );
      if (selectedSession) {
        const selectedSessions = sessions.find(
          (session) => session.session_id === session_id
        );
        dispatch(setProfileVisible(false));
        dispatch(clearSession());
        dispatch(setCurrentIdentifySession(null));
        dispatch(setCurrentSession(selectedSession));
        dispatch(setPolygon(polygon));
        dispatch(setPanningAreas(selectedSession.panning_areas));
        dispatch(setSelectedSessionId(selectedSession.session_id));
        dispatch(fetchMarkers({ sessionId: session_id, sessionType: 'AREA', timeframe: null, filters: selectedSession.filters }));
        dispatch(fetchAnalyticsData({ sessionId: session_id, sessionType: 'AREA' }));
        dispatch(toggleBottomSidebar());
        dispatch(toggleAnalyticsSidebar(true));
        dispatch(toggleIdentifySidebar(false))
        dispatch(toggleRightSidebar(false));
        dispatch(setCreateType(null));
        setIsButtonActive(false);
        setActiveIdentify("");
        dispatch(setSegm('Static'));
        dispatch(setMarkerStyle(markerStyle));
        dispatch(setFeatureId(''));

      } else {
        return;
      }

    } else {
      dispatch(setProfileVisible(false));
      setActive("");
      setActiveIdentify("");
      dispatch(clearSession());
      dispatch(resetUI());
      dispatch(removeMarkers());
      dispatch(toggleRightSidebar(false));
      dispatch(setCreateType(null));
      dispatch(setInitialIdentifyMarker(null));

      setIsButtonActive(false);
      dispatch(resetSession());
      dispatch(setSegm('Thrash'));
      dispatch(setSegm('Static'));
      dispatch(setMarkerStyle(markerStyle));

      dispatch(setFeatureId(''));
    }
  };

  const activateIdentifyCard = (session_id: string, polygon: any) => {
    setActive("");
    dispatch(clearSession());
    dispatch(setProfileVisible(false));

    if (segActive == 'Drag Edit') {
      setActiveIdentify('');
    }
    if (segActive == 'Static' && featureId != '') {
      setActiveIdentify('');
    }
    console.log(activeIdentify,session_id)
    if (activeIdentify === "" || activeIdentify !== session_id) {
      setActiveIdentify(session_id);
      setActive("");

      const selectedIdentifySession = identifySessions.find(
        (session) => session.session_id === session_id
      );

      if (selectedIdentifySession) {
        dispatch(setProfileVisible(false));
        dispatch(clearIdentifySession());
        dispatch(setCurrentIdentifySession(selectedIdentifySession));
        dispatch(resetSession());
        dispatch(removeMarkers());

        if (selectedIdentifySession.data_count > 0) {
          dispatch(fetchMarkers({ sessionId: selectedIdentifySession.session_id, sessionType: 'IDENTIFY', timeframe: selectedIdentifySession.timeframe, filters: selectedIdentifySession.filters }));

        }
        // dispatch(remove)

        dispatch(setPanningAreas(selectedIdentifySession.panning_areas));
        dispatch(setSelectedSessionId(selectedIdentifySession.session_id));
        dispatch(setSegm('Static'));
        dispatch(setFeatureId(''));
        dispatch(setMarkerStyle(markerStyle));

        dispatch(toggleIdentifySidebar(true))
        dispatch(toggleAnalyticsSidebar(false));
        setIsButtonActive(false);
        setActive("");

        dispatch(toggleRightSidebar(false));
        dispatch(setCreateType(null));

      }
    } else {
      dispatch(setProfileVisible(false));
      setActiveIdentify("");
      setActive("");
      dispatch(clearSession());
      dispatch(setSegm('Thrash'));
      dispatch(setSegm('Static'));
      dispatch(setPanningAreas([]));
      dispatch(setSelectedSessionId(null));
      dispatch(resetUI());
      dispatch(removeMarkers());
      dispatch(resetSession());
      dispatch(clearIdentifySession());

      setIsButtonActive(false);
      dispatch(setInitialIdentifyMarker(null))

      dispatch(toggleRightSidebar(false));
      dispatch(setCreateType(null));

    }    
  };


  const activateCompletedTaskCard = (session_id: string, polygon: any) => {
    const selectedTask = tasksCompleted?.items.find(
      (task: any) => task.session_id === session_id
    );
    taskCardFlow(session_id, polygon, selectedTask);
  }

  const activatePendingTaskCard = (session_id: string, polygon: any) => {
    const selectedTask = tasksPending?.items.find(
      (task: any) => task.session_id === session_id
    );
    taskCardFlow(session_id, polygon, selectedTask);
  }

  const activateRunningTaskCard = (session_id: string, polygon: any) => {
    const selectedTask = tasksRunning?.items.find(
      (task: any) => task.session_id === session_id
    );
    taskCardFlow(session_id, polygon, selectedTask);
  }

  const taskCardFlow = (session_id: string, polygon: any, selectedTask:any) => {
    dispatch(setProfileVisible(false));
    dispatch(resetSession());
    dispatch(removeMarkers());
    resetAll();
    setActive('');

    if (active && active == selectedTask.session_id){
      return;
    }

    dispatch(setCurrentTask(selectedTask));
    dispatch(setPanningAreas(selectedTask.panning_areas));
    dispatch(setSelectedSessionId(selectedTask.session_id));
    dispatch(setSegm('Static'));
    dispatch(setFeatureId(''));
    dispatch(toggleTaskSidebar(true));
    setActive(selectedTask.session_id);
    dispatch(setCreateType(null));
    
  };
  
  const handleCompletedChange = (page: number) => {
    setCurrentCompletedPage(page);
  };

  const handleRunningChange = (page: number) => {
    setCurrentRunningPage(page);
  };
  const handlePendingChange = (page: number) => {
    setCurrentPendingPage(page);
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };
  const handleClick = () => {
    dispatch(toggleAnalyticsSidebar(false));
    dispatch(setProfileVisible(false));
    dispatch(clearSession());
    dispatch(clearIdentifySession());
    dispatch(resetUI());
    dispatch(removeMarkers());
    dispatch(toggleIdentifySidebar(false));
    setIsButtonActive(true);
    dispatch(setSegm('Thrash'));
    dispatch(setMarkerStyle(markerStyle));

    dispatch(toggleRightSidebar(true));
    dispatch(setCreateType('IDENTIFY'));
  }

  const handleTaskClick = () => {
    dispatch(toggleAnalyticsSidebar(false));
    dispatch(setProfileVisible(false));
    dispatch(clearSession());
    dispatch(clearIdentifySession());
    dispatch(resetUI());
    dispatch(removeMarkers());
    dispatch(toggleIdentifySidebar(false));
    setIsButtonActive(true);
    dispatch(setSegm('Thrash'));
    dispatch(setMarkerStyle(markerStyle));

    dispatch(toggleRightSidebar(true));
    dispatch(setCreateType('TASK'));
  }

  const handleIdentifyPageChange = (page: number) => {
    setCurrentIdentifyPage(page);
  };

  // Show error notifications
  useEffect(() => {
    if (error) {
      notification.error({
        message: 'Error',
        description: error,
        placement: 'topRight',
        duration: 5,
      });
    }
    if (errorIdentify) {
      notification.error({
        message: 'Error',
        description: errorIdentify,
        placement: 'topRight',
        duration: 5,
      });
    }
  }, [error, errorIdentify]);

  const resetAll = () => {
    setActiveIdentify('');
    setActive("");
    dispatch(toggleRightSidebar(false));
    dispatch(toggleAnalyticsSidebar(false));
    dispatch(toggleBottomSidebar());
    dispatch(toggleIdentifySidebar(false));
    dispatch(toggleTaskSidebar(false));
    // dispatch(setPanningAreas([]));
    dispatch(resetUI());
    dispatch(removeMarkers());
    dispatch(clearSession());
    dispatch(clearIdentifySession());
    dispatch(setProfileVisible(false));
    

  }

  const handleTabChange = (key: any) => {
    resetAll();
    if (key){
      dispatch(setActiveSession(tabTitles[key]));
    }
    if (key == 1){
      dispatch(setDrawOptions(['Single', 'Multi', 'Border']));
      dispatch(setEnableDrawing(true));

    }    
    else if (key == 2){
      dispatch(setDrawOptions([]));
      dispatch(setEnableDrawing(false));
    }
    else if (key == 3){
      dispatch(setDrawOptions(['Single']));
      dispatch(setEnableDrawing(false));
      setTaskType(null);
    }
  };

  const handleTaskTypeChange = (type: any) => {
    if (type && type != 'Individuals Monitoring'){
      dispatch(setEnableDrawing(true));
    }else{
      dispatch(setEnableDrawing(false));
    }
    setTaskType(type);
    dispatch(setSessionTaskType(type));
    
  };



  return (
    <>
      <Tabs defaultActiveKey="1" onChange={handleTabChange} type="card" size='large'
      >
        <Tabs.TabPane tab="Area Search" key="1">
          <Row gutter={6} align='middle' justify='center'>
            <Col style={{ margin: "16px" }}>
                <Input.Search
                  placeholder="Area Name Search"
                  enterButton
                  value={searchText}
                  onChange={( e: any ) => {
                    setSearchText(e.target.value);
                  }}
                  onSearch={() => {
                    setCurrentPage(1);
                    dispatch(fetchSessions({ page: currentPage, pageSize: pageSize, search_filter: searchText}));
                  }}
                  style={{ maxWidth: '100%', width: '100%', padding: "6px" }}
                />                  
            </Col>
          </Row>
          {loading && <div style={{ textAlign: "center", padding: "50px" }}>
            <Spin size="large" />
          </div>}
          {!loading && sessions.length === 0 &&
            <div style={{ textAlign: "center", padding: "50px" }}>
              <p>No Area Search Sessions</p>
            </div>
          }
          {!loading && sessions.length != 0 &&

            <>
              <Row gutter={6} align='middle' justify='center'>
                <Col style={{ margin: "6px" }}>
                  <Pagination
                    simple
                    current={currentPage}
                    pageSize={pageSize}
                    total={paginSessions?.total}
                    onChange={handlePageChange}
                    style={{ textAlign: "center" }}
                  />
                </Col>
                
                {sessions.map((session, index) => (
                  <Col
                    key={index}
                    id={session.session_id}
                    style={{ marginBottom: "16px" }}
                    className={current_session && session.session_id === current_session.session_id ? "active-card" : "remove-card"}
                  >
                    <FlippingCard onActivated={activateCard} active={current_session && session.session_id === current_session.session_id ? session.session_id : ""} session={session} />
                  </Col>
                ))}
              </Row>
            </>
          }
        </Tabs.TabPane>

        <Tabs.TabPane tab="Identify" key="2">
          <Row gutter={6} align='middle' justify='center'>
              <Col style={{ margin: "16px" }}>
                  <Input.Search
                    placeholder="Session Search"
                    enterButton
                    value={searchIdentifyText}
                    onChange={( e: any ) => {
                      setIdentifySearchText(e.target.value);
                    }}
                    onSearch={() => {
                      setCurrentIdentifyPage(1);
                      dispatch(fetchIdentifySessions({ page: currentIdentifyPage, pageSize: pageSize, search_filter: searchIdentifyText}));
                    }}
                    style={{ maxWidth: '100%', width: '100%', padding: "6px" }}
                  />
                  {/* <ReloadOutlined 
                    style={{ maxWidth: '20%', width: '100%', padding: "14px 24px" }}
                    onClick={(e: any) => {
                      setCurrentIdentifyPage(1);
                      dispatch(fetchIdentifySessions({ page: currentIdentifyPage, pageSize: pageSize, search_filter: searchIdentifyText}));
                    }} /> */}

              </Col>
             
     
            </Row>

          <Row gutter={0} >
            <Col span={12} style={{ marginBottom: "16px", alignItems: 'middle' }}>
              <Pagination
                simple

                current={currentIdentifyPage}
                pageSize={identifyPageSize}
                total={paginIdentifySessions?.total}
                onChange={handleIdentifyPageChange}
              />
      
            </Col>                <Col span={10} style={{ marginBottom: "16px", justifyContent: 'end' }}>
              <Button

                type={isButtonActive ? 'primary' : 'default'}
                onClick={handleClick}
                className={isButtonActive ? 'ant-btn-active' : ''}>
                Deep Search <PlusCircleOutlined twoToneColor="#52c41a" />
              </Button>
              
            </Col>
          </Row>
          {loadingIdentify && <div style={{ textAlign: "center", padding: "50px" }}>
            <Spin size="large" />
          </div>}
          {!loadingIdentify && identifySessions.length === 0 &&
            <div style={{ textAlign: "center", padding: "50px" }}>
              <p>No Identify Sessions</p>
            </div>
          }
          {!loadingIdentify && identifySessions.length != 0 &&

            <>
              <Row gutter={0} >

                {identifySessions.map((sessionIdentify: any, index) => (
                  <Col
                    key={index}
                    id={sessionIdentify.session_id}
                    style={{ marginBottom: "16px" }}
                    className={activeIdentify && sessionIdentify.session_id === activeIdentify ? "active-card" : "remove-card"}
                  >
                    <IdentifyFlippingCard onActivated={activateIdentifyCard} active={activeIdentify && sessionIdentify.session_id === activeIdentify ? sessionIdentify.session_id : ""} session={sessionIdentify} />
                  </Col>
                ))}
              </Row>
            </>
          }
        </Tabs.TabPane>

        <Tabs.TabPane tab="Monitor" key="3">
          <Row gutter={6} align='middle' justify='center'>
            <Col style={{ margin: "16px" }}>
                <Input.Search
                  placeholder="Monitoring Task Search"
                  enterButton
                  value={searchTaskText}
                  onChange={( e: any ) => {
                    setTaskSearchText(e.target.value);
                  }}
                  onSearch={() => {
                    setCurrentCompletedPage(1);
                    setCurrentPendingPage(1);
                    setCurrentRunningPage(1);
                    dispatch(fetchTasks({ page: 1, pageSize: taskPageSize, search_filter: searchTaskText, status: 'COMPLETED'}));
                    dispatch(fetchTasks({ page: 1, pageSize: taskPageSize, search_filter: searchTaskText, status: 'RUNNING'}));
                    dispatch(fetchTasks({ page: 1, pageSize: taskPageSize, search_filter: searchTaskText, status: 'PENDING'}));
                  }}
                  style={{ maxWidth: '100%', width: '100%', padding: "6px" }}
                />                  
            </Col>
            <Col span={10} style={{ marginBottom: "16px", justifyContent: 'end'}}>
              <Select
                    defaultValue="Individuals Monitoring"
                    style={{ width: 160 }}
                    onChange={handleTaskTypeChange}
                    value={taskType}
                    options={[
                      { value: 'Individuals Monitoring', label: 'Atributes Monitor' },
                      { value: 'Geofence', label: 'Geofence' },
                      { value: 'Border Control', label: 'Border Control' },
                    ]}
                />
            </Col>
            <Col span={10} style={{ marginBottom: "16px", justifyContent: 'end', marginLeft: 30}}>
              <Button
                type={isButtonActive ? 'primary' : 'default'}
                onClick={handleTaskClick}
                disabled={!taskType}
                className={isButtonActive ? 'ant-btn-active' : ''}>
                Create Task<PlusCircleOutlined twoToneColor="#52c41a" />
              </Button>
              
            </Col>
          </Row>
          <Card title='Collecting Now'>
            {loadingTask && <div style={{ textAlign: "center", padding: "50px" }}>
              <Spin size="large" />
            </div>}
            {!loadingTask && tasksRunning?.total === 0 &&
              <div style={{ textAlign: "center", padding: "50px" }}>
                <p>No Running Tasks</p>
              </div>
            }
            {!loadingTask && tasksRunning?.total != 0 &&
              <>
              
                <Row gutter={6} align='middle' justify='center'>
                  <Col>
                    <Pagination
                      simple
                      current={runningPage}
                      pageSize={taskPageSize}
                      total={tasksRunning?.pages}
                      onChange={handleRunningChange}
                      style={{ textAlign: "center" }}
                    />
                  </Col>
                  {tasksRunning?.items.map((task, index) => (
                    <Col
                      key={index}
                      id={task.session_id}
                      className={active && task.session_id === active ? "active-card" : "remove-card"}
                    >
                      {task.status == 'RUNNING'&&<TaskCard onActivated={activateRunningTaskCard} active={active && active === task.session_id ? task.session_id : ""} session={task} />}
                    </Col>
                  ))}
                </Row>
              </>
            }
          </Card>

          <Card title='Pending' style={{ marginTop: 10}}>
          {loadingTask && <div style={{ textAlign: "center", padding: "50px" }}>
              <Spin size="large" />
            </div>}
            {!loadingTask && tasksPending?.total === 0 &&
              <div style={{ textAlign: "center", padding: "50px" }}>
                <p>No Pending Tasks</p>
              </div>
            }
            {!loadingTask && tasksPending?.total != 0 &&

              <>
                <Row gutter={6} align='middle' justify='center'>
                  <Col>
                    <Pagination
                      simple
                      current={pendingPage}
                      pageSize={taskPageSize}
                      total={tasksPending?.pages}
                      onChange={handlePendingChange}
                      style={{ textAlign: "center" }}
                    />
                  </Col>
                  
                  {tasksPending?.items.map((task, index) => (
                    <Col
                      key={index}
                      id={task.session_id}
                      className={active && task.session_id == active ? "active-card" : "remove-card"}
                    >
                      {task.status == 'PENDING'&&<TaskCard onActivated={activatePendingTaskCard} active={active && task.session_id === active ? task.session_id: ''} session={task} />}
                    </Col>
                  ))}
                </Row>
              </>
            }
          </Card>

          <Card title='Completed' style={{ marginTop: 10}}>
          {loadingTask && <div style={{ textAlign: "center", padding: "50px" }}>
              <Spin size="large" />
            </div>}
            {!loadingTask && tasksCompleted?.total === 0 &&
              <div style={{ textAlign: "center", padding: "50px" }}>
                <p>No Completed Tasks</p>
              </div>
            }
            {!loadingTask && tasksCompleted?.total != 0 &&
              <>
                <Row gutter={6} align='middle' justify='center'>
                  <Col>
                    <Pagination
                      simple
                      
                      current={completedPage}
                      pageSize={taskPageSize}
                      total={tasksCompleted?.pages}
                      onChange={handleCompletedChange}
                      style={{ textAlign: "center" }}
                    />
                  </Col>
                  
                  {tasksCompleted?.items.map((task, index) => (
                    <Col
                      key={index}
                      id={task.session_id}
                      className={active && task.session_id === active ? "active-card" : "remove-card"}
                    >
                      {task.status == 'COMPLETED'&&<TaskCard onActivated={activateCompletedTaskCard} active={active && task.session_id === active ? task.session_id: ''} session={task} />}
                    </Col>
                  ))}
                </Row>
              </>
            }
          </Card>
          
        </Tabs.TabPane>
      </Tabs>
    </>
  );
};

export default SessionList;


