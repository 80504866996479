import React, { useEffect, useRef, useState } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';  // <-- Import the Geocoder
import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../store";
import * as turf from "@turf/turf";
import booleanWithin from "@turf/boolean-within";
import geohash from "ngeohash";

import { Spin, Flex, Modal, Descriptions, message, Button, Popconfirm, FloatButton } from "antd";
import { resetQueryForm, resetUI, setFeatureId, setMapRef, setMultiPolygon, setPolygon, setSegm, toggleAnalyticsSidebar, toggleBottomSidebar, toggleRightSidebar } from '../store/slices/uiSlice';
import { activeDraw, activeMapBtn, currentFeatureId, selectActiveSession, selectPolygon, selectPanningAreas, selectSessionId, selectEnableDrawing } from "../store/selectors/uiSelectors";
import { selectMarkers, selectAddedSession, selectTrajectory, selectSelectedRecord, selectZoomTo } from "../store/selectors/sessionSelectors";
import { clearSession, removeMarkers, fetchTrajectory, fetchMarkers, setCurrentSession, setZoomTo } from "../store/slices/sessionSlice";
import { convertToScreenPosition, copyToClipboard, handleZoom, parseDate } from "../utils/mapUtils";
import ControlPanel from "./ControlPanel";
import { selectIdentifyInitialMarker, selectCurrentIdentify } from "../store/selectors/identifySelectors";
import SettingsComponent from "./SettingsComponent";
import { selectMarkerStyle, selectPathTracerStyle, selectShowPolygons } from "../store/selectors/mapSettingsSelectors";
import { LeftOutlined, RightOutlined, CommentOutlined } from '@ant-design/icons';
import {geohashToPolygonFeature} from 'geohash-to-geojson';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import { setProfileVisible, setDeviceId, setSessionType, setSessionId }  from '../store/slices/profileSlice'
import { selectProfileVisible } from '../store/selectors/profileSelectors';
import dayjs from 'dayjs';
import arrow_image from "../assets/arrow.png"

const MapComponent: React.FC = () => {
    const mapContainer = useRef<HTMLDivElement>(null);
    const mapRef = useRef<Map | null>(null);  // Use a ref to store the map instance
    const drawRef = useRef<MapboxDraw | null>(null);  // Use a ref to store the MapboxDraw instance
    const rightSidebar = useSelector((state: RootState) => state.ui.rightSidebar);
    const analyticsSidebar = useSelector((state: RootState) => state.ui.analyticsSidebar);
    const { height, width } = useWindowDimensions();

    const [geojsonData, setGeojsonData] = useState<any>(null);
    const { user } = useSelector((state: RootState) => state.auth);
    const [mapLoaded, setMapLoaded] = useState<boolean>(false);
    const current_polygon = useSelector(selectPolygon);
    const markers = useSelector(selectMarkers); // Get markers from state
    const [loader, setLoader] = useState<boolean>(true);
    const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lon: number } | null>(null);
    const [modalPosition, setModalPosition] = useState({ left: 0, top: 0 });
    const segActive = useSelector(activeMapBtn); // Error state for identify sessions
    const drawMode = useSelector(activeDraw); // Error state for identify sessions
    const [drawData, setDrawData] = useState<any>(drawMode);
    const markerStyle = useSelector(selectMarkerStyle); // Error state for identify sessions
    const polygonState = useSelector(selectShowPolygons); // Error state for identify sessions
    const tracerStyle = useSelector(selectPathTracerStyle); // Error state for identify sessions

    const panningAreas = useSelector(selectPanningAreas); 
    const trajectory = useSelector(selectTrajectory); 
    const selectedRecord = useSelector(selectSelectedRecord); 
    const zoomTo = useSelector(selectZoomTo); 

    const [pannedIndex, setPanIndex] = useState(0);
    const dispatch = useDispatch<AppDispatch>();
    const [markerData, setAreaMarkers] = useState<any>(null);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newMap, setMap] = useState<Map | null>(null);

    const sessionType = useSelector(selectActiveSession); // Get markers from state
    const sessionId = useSelector(selectSessionId);
    const enableDrawing = useSelector(selectEnableDrawing);

    const initialIdentifyMarker = useSelector(selectIdentifyInitialMarker); // Get markers from state
    const binSize = 60 * 1000; // 1 minute in milliseconds
    const [zoom, setZoom] = useState(3);

    const handleProfiler = (device_id: string) => {
        setIsModalVisible(false);
        console.log(sessionId);
        dispatch(setSessionId(sessionId));
        dispatch(setDeviceId(device_id));
        if (sessionType == 'Monitor'){
            dispatch(setSessionType('TASK'));
        }else if (sessionType == 'Area Search'){
            dispatch(setSessionType('AREA'));

        }else if (sessionType == 'Identify'){
            dispatch(setSessionType('IDENTIFY'));
        }
        dispatch(setProfileVisible(true));
      }

    useEffect(() => {
        // const bins: { x: number; y: number }[] = [];

        // markers.forEach((item:any) => {
        //   const binIndex = Math.floor(item[2] / binSize);
        //   if (!bins[binIndex]) {
        //     bins[binIndex] = { x: item.epochTs, y: 0 };
        //   }
        //   bins[binIndex].y += item.count;
        // });

        //  bins.filter(Boolean); // Remove undefined bins
        if (markers && markerStyle !== '') {
            const deviceIdCount = markers.reduce((acc: any, point: any) => {
                const deviceId = point[3];
                acc[deviceId] = (acc[deviceId] || 0) + 1;
                return acc;
            }, {});
            const geojson = {
                type: "FeatureCollection",
                features: markers.map((point: any) => ({
                    type: "Feature",
                    geometry: {
                        type: "Point",
                        coordinates: [point[0], point[1]],
                    },
                    properties: {
                        ts: point[2],
                        device_id: point[3],
                        count: deviceIdCount[point[3]], // Add count of device_id

                    },
                })),
            };
            setAreaMarkers(geojson);
        }
    }, [markers, mapRef, markerStyle]);
    useEffect(() => {
        if (!geojsonData && user) {
            setGeojsonData({
                id: 'boarder-countries',
                type: 'FeatureCollection',
                features: user.borders.map((boarder: any) => boarder.geojson)
            });
        }
    }, [user, geojsonData]);
    useEffect(() => {
        if (drawMode) {
            setDrawData(drawMode);
        }
    }, [drawMode]);

    useEffect(() => {
        if (zoomTo) {
            const map = mapRef.current;
            map?.flyTo({
                center: [zoomTo?.lon, zoomTo?.lat],
                zoom: 22,
                essential: true
              }); 
        }
    }, [zoomTo]);

    useEffect(() => {
        const map = mapRef.current;

        if (map && sessionType === 'Identify' && initialIdentifyMarker && markers) {
            map.flyTo({
                center: [initialIdentifyMarker.lon, initialIdentifyMarker.lat],
                zoom: zoom
            });
        } else if (sessionType === 'Identify' && markers.length > 0) {
            const map = mapRef.current;
            if (map) {

                const tempMarkers: any[] = markers;
                const test = tempMarkers[0];
                map.flyTo({
                    center: [test[0], test[1]],
                    zoom: zoom
                });
            }

        }
    }, [sessionType, initialIdentifyMarker, markers]);
    useEffect(() => {
        const map = mapRef.current;
        if (mapLoaded && current_polygon == null && map && drawRef.current && geojsonData && drawMode !== 'Multi Area' && drawMode != 'Multi Area Compare') {
            setLoader(false); // Stop loader when update is complete
            drawRef.current.deleteAll(); // Clear existing polygons
            const polygonSourceId = `current-polygon`;
            const polygonLayerId = `area-search`;
            const polygonOutlineLayerId = `outline-polygon`;

            // Remove existing source and layers if they exist
            if (map.getLayer(polygonLayerId)) {
                map.removeLayer(polygonLayerId);
            }
            if (map.getLayer(polygonOutlineLayerId)) {
                map.removeLayer(polygonOutlineLayerId);
            }
            if (map.getSource(polygonSourceId)) {
                map.removeSource(polygonSourceId);
            }
            removeSourceAndLayers(map, sourceId);

            // if (segActive === 'Static') {
            //     // Zoom to fit Europe using the bbox
            //     const bboxPolygon = turf.bbox(geojsonData); // Generates [west, south, east, north] bounds
            //     mapRef.current?.fitBounds(bboxPolygon as mapboxgl.LngLatBoundsLike, { padding: 20,maxZoom:12 });

            // }

        }
    }, [current_polygon, geojsonData, sessionType, drawMode, markerStyle, polygonState]);
    // Your function to call when drawing starts
    const onDrawingStart = () => {
        // Your custom logic here
    };

    useEffect(() => {
        mapboxgl.accessToken = window.__RUNTIME_CONFIG__.ADINT_UI_MAPBOX_TOKEN;


        if (mapContainer.current && geojsonData && !mapRef.current && markerStyle) {
            var map: any = '';
            var draw: any = '';
            var center: any ='';
            var coords: any= [];
            if(markers.length != 0 && current_polygon){
                // Allowed Boarders Source
                const center = turf.center(current_polygon);
                coords= center.geometry.coordinates;
                // Initialize the map
                // Create new map instance with selected style
                map = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: markerStyle === 'cluster'
                        ? 'mapbox://styles/mapbox/satellite-streets-v12'
                        : 'mapbox://styles/mapbox/satellite-streets-v12',
                    attributionControl: false,
                    center:markers.length == 0? [5, 34]: [coords[0], coords[1]],
                    zoom:zoom, // Adjust the zoom level as needed

                });

            }else{
                // Initialize the map
                // Create new map instance with selected style
                map = new mapboxgl.Map({
                    container: mapContainer.current,
                    style: markerStyle === 'cluster'
                        ? 'mapbox://styles/mapbox/satellite-streets-v12'
                        : 'mapbox://styles/mapbox/satellite-streets-v12',
                    attributionControl: false,
                    zoom:zoom, // Adjust the zoom level as needed

                });
            }

                    // Add click event listener for unclustered points
                    map.on('click', 'unclustered-point', (e: any) => {
                        const coordinates = e.features[0].geometry.coordinates.slice();
                        const properties = e.features[0].properties;

                        // Ensure the map smoothly pans to the clicked point
                        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                        }
                        const position = convertToScreenPosition(map, e.lngLat.lat, e.lngLat.lng);
                        setModalPosition(position);

                        //    Show a modal with latitude and longitude using Ant Design
                        Modal.info({
                            title: 'Location Information',
                            open: isModalVisible,
                            className: 'bottom-arrow',
                            // onCancel() {() => handleCloseModal},
                            // footer:null,
                            style: { position: 'absolute', left: position.left, top: position.top },

                            content: (<>
                                <Descriptions layout="horizontal"
                                >
                                    <Descriptions.Item label="Timestamp" span={4}>
                                        <div className="datetime" onClick={() => copyToClipboard(properties.ts)}>
                                            <div className="date">
                                                <span id="dayname">{parseDate(properties.ts).dayName} </span>
                                                <span id="month">{parseDate(properties.ts).monthName} </span>
                                                <span id="daynum">{parseDate(properties.ts).dayNum} </span>
                                            </div>
                                            <div className="time">
                                                <span id="hour">{parseDate(properties.ts).hours} </span>:
                                                <span id="minutes">{parseDate(properties.ts).minutes}</span>:
                                                <span id="seconds">{parseDate(properties.ts).seconds}</span>
                                                <span id="period">{parseDate(properties.ts).period}</span>
                                            </div>
                                            <div className="date">
                                                <span id="year">{parseDate(properties.ts).year} </span>
                                            </div>
                                        </div></Descriptions.Item>
                                    <Descriptions.Item label="Device ID" span={4}>

                                        <span className="td-content"
                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                            onClick={() => copyToClipboard(properties.device_id)}
                                        >
                                            {properties.device_id}
                                        </span>
                                    </Descriptions.Item>
                                    <Descriptions.Item label="Coordinates" span={4}>
                                        <span className="td-content"
                                            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                            onClick={() => copyToClipboard(coordinates[1] + "," + coordinates[0])}
                                        >
                                            {coordinates[1].toFixed(2)},{coordinates[0].toFixed(2)}
                                        </span>
                                    </Descriptions.Item>
                                    
                                    <Descriptions.Item label="Actions" span={4}>
                                    <Button 
                                            onClick={() => handleProfiler(properties.device_id)}
                                            type='primary'
                                            >
                                            Profiler
                                        </Button>
                                    </Descriptions.Item>
                                    
                                </Descriptions>
                                
                            </>

                            ),
                            onOk() { },
                        });
                    });
            setMap(map);
            const geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                reverseGeocode: true,
                mapboxgl: mapboxgl as any,
                placeholder: 'Search for streets or coordinates...', // Customize the placeholder text
                marker: false, // Disable the marker for the search result
            });

            // map.setZoom(8);
            // Add the geocoder to the top-left corner of the map
            map.addControl(geocoder, 'top-left');
            map.addControl(new mapboxgl.NavigationControl(), 'top-left');
            // Listen to the result event of the geocoder and fly to the result location
            geocoder.on('result', (event: any) => {
                const coordinates = event.result.geometry.coordinates;
                map.flyTo({
                    center: coordinates,
                    zoom: 18,
                    essential: true
                });
            });

            map.on('load', () => {
                map.setFog({});
                map.loadImage(arrow_image, (error: any, image: any) =>{
                    map.addImage('arrow', image);
                })


                // Allowed Boarders Source
                map.addSource('boarder-countries', {
                    type: 'geojson',
                    data: geojsonData
                });
                // Allow Borders layers
                map.addLayer({
                    id: 'country',
                    type: 'fill',
                    source: 'boarder-countries',
                    paint: {
                        'fill-color': '#03dcdc',
                        'fill-opacity': 0.05,
                        'fill-opacity-transition': { duration: 500 }
                    },
                    filter: ['==', '$type', 'Polygon']
                });

                map.addLayer({
                    id: 'outline',
                    type: 'line',
                    source: 'boarder-countries',
                    layout: {},
                    paint: {
                        'line-color': '#03dcdc',
                        'line-width': 3
                    }
                });

                // Initialize MapboxDraw
                
                draw = new MapboxDraw({
                    displayControlsDefault: false,
                    controls: {
                        polygon: false,
                        trash: false,
                    },
                    styles: [
                        {
                            id: 'gl-draw-polygon-fill',
                            type: 'fill',
                            paint: {
                                'fill-color': '#dc0303',
                                'fill-opacity': 0.1,
                            }
                        },
                        {
                            id: 'gl-draw-polygon-stroke',
                            type: 'line',
                            paint: {
                                'line-color': '#dc0303',
                                'line-width': 2,
                            }
                        },
                        {
                            id: 'gl-draw-line-inactive',
                            type: 'line',
                            paint: {
                                'line-color': '#dc0303',
                                'line-width': 2,
                            }
                        },
                        {
                            id: 'gl-draw-polygon-and-line-vertex-stroke-inactive',
                            type: 'circle',
                            paint: {
                                'circle-radius': 5,
                                'circle-color': '#dc0303',
                            }

                        },
                    ]
                });

                map.addControl(draw, 'top-left');
                // Initialize Geocoder


                map.on('draw.create', (e: any) => {
                    validatePolygon(e, draw, geojsonData, drawMode);
                    onDrawingStart(); // Call the function when drawing starts
                });
                // map.on('draw.update', (e) => validatePolygon(e, draw, geojsonData));
                map.on('draw.delete', () => {
                    setTimeout(() => {
                        draw.deleteAll()
                    }, 0)
                })
                // Store map and draw instances in refs
                mapRef.current = map;
                drawRef.current = draw;
                var current_zoom = map.getZoom();

                if (map && markerStyle && markerData) {
                    const sourceId = 'marker-data-source'; // Use the same ID for the source
                    removeSourceAndLayers(map, sourceId);
                    // Add a new source if it does not exist
                    if (markerStyle == 'cluster') {
                        removeSourceAndLayers(map, sourceId);

                        map.addSource(sourceId, {
                            type: 'geojson',
                            data: markerData,
                            cluster: true,
                            clusterMaxZoom: 14,
                            clusterRadius: 50,
                        });

                        // Add unclustered points layer first
                        map.addLayer({
                            id: 'unclustered-point',
                            type: 'circle',
                            source: sourceId,
                            filter: ['!', ['has', 'point_count']],
                            paint: {
                                'circle-color': '#03dcdc',
                                'circle-radius': 8,
                                'circle-stroke-width': 2,
                                'circle-stroke-color': '#101827',
                                'circle-opacity': 1,
                                'circle-stroke-opacity': 0.6
                            }
                        });
                        // Add cluster circles
                        map.addLayer({
                            id: 'clusters',
                            type: 'circle',
                            source: sourceId,
                            filter: ['has', 'point_count'],
                            paint: {
                                'circle-color': [
                                    'step',
                                    ['get', 'point_count'],
                                    '#21355d',
                                    300,
                                    '#17243d',
                                    700,
                                    '#101827'
                                ],
                                'circle-radius': [
                                    'step',
                                    ['get', 'point_count'],
                                    20,
                                    100,
                                    30,
                                    750,
                                    40
                                ]
                            }
                        }, 'unclustered-point');

                        if (current_polygon) {
                            // Allowed Boarders Source
                            if (polygonState){
                                map.addSource(`current-polygon`, {
                                    type: 'geojson',
                                    data: current_polygon
                                });
    
                                // Allow Borders layers
                                map.addLayer({
                                    id: `area-search`,
                                    type: 'fill',
                                    source: `current-polygon`,
                                    paint: {
                                        'fill-color': '#dc0303',
                                        'fill-opacity': 0.1,
                                        'fill-opacity-transition': { duration: 500 }
                                    },
                                    filter: ['==', '$type', 'Polygon']
                                }, 'clusters');
    
                                map.addLayer({
                                    id: `outline-polygon`,
                                    type: 'line',
                                    source: `current-polygon`,
                                    layout: {},
                                    paint: {
                                        'line-color': '#dc0303',
                                        'line-width': 2
                                    }
                                }, 'clusters');
                            }

                            // Allowed Boarders Source
                            const center = turf.center(current_polygon);
                            const coordinates = center.geometry.coordinates;
                            map.flyTo({
                                center: [coordinates[0], coordinates[1]],
                                zoom: zoom, // Adjust the zoom level as needed
                            });
                        }

                        // Add cluster count labels
                        map.addLayer({
                            id: 'cluster-count',
                            type: 'symbol',
                            source: sourceId,
                            filter: ['has', 'point_count'],
                            layout: {
                                'text-field': ['get', 'point_count_abbreviated'],
                                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                                'text-size': 16,
                            },
                            paint: {
                                'text-color': '#a0b3c6',
                            }
                        });
                    }
                    else if (markerStyle == 'heatmap') {
                        removeSourceAndLayers(map, sourceId);
                        createHeatmap(map, sourceId, markerData);                    
                    }
                    
                    if (current_polygon) {
                        // Allowed Boarders Source
                        const center = turf.center(current_polygon);
                        const coordinates = center.geometry.coordinates;
                        map.flyTo({
                            center: [coordinates[0], coordinates[1]],
                            zoom: zoom, // Adjust the zoom level as needed
                        });
                    }

                    setLoader(false); // Stop loader when update is complete
                }

                setMapLoaded(true);
                map.on('zoomend', () => setZoom(map.getZoom()));
            });

            return () => {
                if (mapRef.current) {
                    // mapRef.current.remove();
                    mapRef.current = null;
                    drawRef.current = null;
                }
            };
        }
    }, [geojsonData, markerStyle]);
    // Define the source ID
    const sourceId = 'marker-data-sources';

    // Function to remove existing source and layers
    function removeSourceAndLayers(map: any, sourceId: any) {
        // Remove associated layers if they exist
        const layers = ['markersSourceLayer', 'line-background', 'line-dashed','clusters', 'cluster-count', 'unclustered-point', 'current-polygon', 'unclustered-heatmap'];
        layers.forEach(layer => {
            if (map.getLayer(layer)) {
                map.removeLayer(layer);
            }
        });
        // Check if the source exists
        [sourceId, 'linestring-data-source', 'markersSource'].forEach(sourceId => {
            if (map.getSource(sourceId)) {
                // Remove the source
                map.removeSource(sourceId);
            }
        });
        dispatch(setZoomTo(null));
    
    }

    

    useEffect(() => {
        if (trajectory){
            removeTrajectory();
            createLineString(trajectory);
        }else{
            removeTrajectory();
        }

    }, [trajectory, tracerStyle]);

    useEffect(() => {
        if (mapLoaded && markerData && newMap) {
            mapRef.current = newMap
            const map = mapRef.current;

            var use_zoom = zoom;
            if(map.getZoom() < 10){
                use_zoom = 10
            }else if (map.getZoom() > 20){
                use_zoom = 16;
            }

            if (mapLoaded && current_polygon && map && drawRef.current && markerStyle) {
                setLoader(false); // Stop loader when update is complete
                // Get the ID of the current polygon to preserve it
                const currentPolygonId = current_polygon.id;

                // Retrieve all features from the draw instance
                const allFeatures = drawRef.current.getAll().features;

                // Loop through features and delete those that are not the current polygon
                if (segActive === 'Drag Edit' && drawMode != 'Multi Area' && drawMode != 'Multi Area Compare') {
                    allFeatures.forEach(feature => {
                        if (feature.id !== currentPolygonId && drawRef.current) {
                            if (feature.id) {
                                drawRef.current.delete(feature.id.toString()); // Delete feature if it's not the current polygon

                            }
                        }
                    });
                } else if (drawMode != 'Multi Area' && drawMode != 'Multi Area Compare') {
                    drawRef.current.deleteAll(); // Clear existing polygons
                }
                const polygonSourceId = `current-polygon`;
                const polygonLayerId = `area-search`;
                const polygonOutlineLayerId = `outline-polygon`;

                // Remove existing source and layers if they exist
                if (map.getLayer(polygonLayerId)) {
                    map.removeLayer(polygonLayerId);
                }
                if (map.getLayer(polygonOutlineLayerId)) {
                    map.removeLayer(polygonOutlineLayerId);
                }
                if (map.getSource(polygonSourceId)) {
                    map.removeSource(polygonSourceId);
                }

                // Add a new source if it does not exist
                if (markerStyle == 'cluster') {
                    createClusterMap(map, sourceId, markerData, use_zoom);
                }
                else if (markerStyle == 'heatmap') {
                    createHeatmap(map, sourceId, markerData);                    
                }

                if (segActive != 'Drag Edit' && current_polygon != null) {
                    const polygonSourceId = `current-polygon`;
                    const polygonLayerId = `area-search`;
                    const polygonOutlineLayerId = `outline-polygon`;

                    // Remove existing source and layers if they exist
                    if (map.getLayer(polygonLayerId)) {
                        map.removeLayer(polygonLayerId);
                    }
                    if (map.getLayer(polygonOutlineLayerId)) {
                        map.removeLayer(polygonOutlineLayerId);
                    }
                    if (map.getSource(polygonSourceId)) {
                        map.removeSource(polygonSourceId);
                    }
                    // Allowed Boarders Source
                    map.addSource(`current-polygon`, {
                        type: 'geojson',
                        data: current_polygon
                    });

                    // Allow Borders layers
                    if (polygonState){
                        map.addLayer({
                            id: `area-search`,
                            type: 'fill',
                            source: `current-polygon`,
                            paint: {
                                'fill-color': '#dc0303',
                                'fill-opacity': 0.1,
                                'fill-opacity-transition': { duration: 500 }
                            },
                            filter: ['==', '$type', 'Polygon']
                        });
    
                        map.addLayer({
                            id: `outline-polygon`,
                            type: 'line',
                            source: `current-polygon`,
                            layout: {},
                            paint: {
                                'line-color': '#dc0303',
                                'line-width': 2
                            }
                        });
                    }

                    // Allowed Boarders Source
                    const center = turf.center(current_polygon);
                    const coordinates = center.geometry.coordinates;

                    map.flyTo({
                        center: [coordinates[0], coordinates[1]],
                        zoom: use_zoom, // Adjust the zoom level as needed
                    });


                } else {
                    drawRef.current.add(current_polygon); // Add the polygon to the draw instance
                    // Allowed Boarders Source
                    const center = turf.center(current_polygon);
                    const coordinates = center.geometry.coordinates;

                    map.flyTo({
                        center: [coordinates[0], coordinates[1]],
                        zoom: use_zoom, // Adjust the zoom level as needed
                    });
                }

            } else if (mapLoaded && map && markerStyle && drawRef.current){
                // Add a new source if it does not exist
                setLoader(false);
                if (markerStyle == 'cluster') {
                    createClusterMap(map, sourceId, markerData, use_zoom);
                } else if (markerStyle == 'heatmap') {
                    createHeatmap(map, sourceId, markerData);    
                }                
            
            } else {
                // const center = turf.center(geojsonData);
                // const coordinates = center.geometry.coordinates;
                // const map = mapRef.current;
                // if (map) {
                //     map.flyTo({
                //         center: [coordinates[0], coordinates[1]],
                //         zoom: 4, // Adjust the zoom level as needed
                //     });
                // }
                if (drawRef.current) {
                    // Get the ID of the current polygon to preserve it
                    if (drawMode != 'Multi Area' && drawMode != 'Multi Area') {
                        drawRef.current.deleteAll(); // Clear existing polygons

                    }
                }
            }
        }

    }, [mapLoaded, mapRef, markerData, current_polygon, markerStyle, polygonState]);
       

    const createClusterMap = (map:any, sourceId: any, markerData: any, use_zoom: any) => {
        removeSourceAndLayers(map, sourceId);
        setPanIndex(0);
        map.addSource(sourceId, {
            type: 'geojson',
            data: markerData,
            cluster: true,
            clusterMaxZoom: 14,
            clusterRadius: 50,
        });

        // Add unclustered points layer first
        map.addLayer({
            id: 'unclustered-point',
            type: 'circle',
            source: sourceId,
            filter: ['!', ['has', 'point_count']],
            paint: {
                'circle-color': '#03dcdc',
                'circle-radius': 8,
                'circle-stroke-width': 2,
                'circle-stroke-color': '#101827',
                'circle-opacity': 1,
                'circle-stroke-opacity': 0.6
            }
        });
        // Add cluster circles
        map.addLayer({
            id: 'clusters',
            type: 'circle',
            source: sourceId,
            filter: ['has', 'point_count'],
            paint: {
                'circle-color': [
                    'step',
                    ['get', 'point_count'],
                    '#21355d',
                    300,
                    '#17243d',
                    700,
                    '#101827'
                ],
                'circle-radius': [
                    'step',
                    ['get', 'point_count'],
                    20,
                    100,
                    30,
                    750,
                    40
                ]
            }
        }, 'unclustered-point');

        if (current_polygon) {
            // Allowed Boarders Source
            map.addSource(`current-polygon`, {
                type: 'geojson',
                data: current_polygon
            });

            // Allow Borders layers
            map.addLayer({
                id: `area-search`,
                type: 'fill',
                source: `current-polygon`,
                paint: {
                    'fill-color': '#dc0303',
                    'fill-opacity': 0.1,
                    'fill-opacity-transition': { duration: 500 }
                },
                filter: ['==', '$type', 'Polygon']
            }, 'clusters');

            map.addLayer({
                id: `outline-polygon`,
                type: 'line',
                source: `current-polygon`,
                layout: {},
                paint: {
                    'line-color': '#dc0303',
                    'line-width': 2
                }
            }, 'clusters');
            // Allowed Boarders Source
            if(markers.length == 0){
                const center = turf.center(current_polygon);
                const coordinates = center.geometry.coordinates;

                map.flyTo({
                    center: [coordinates[0], coordinates[1]],
                    zoom: use_zoom, // Adjust the zoom level as needed
                });
            }

        }

        // Add cluster count labels
        map.addLayer({
            id: 'cluster-count',
            type: 'symbol',
            source: sourceId,
            filter: ['has', 'point_count'],
            layout: {
                'text-field': ['get', 'point_count_abbreviated'],
                'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
                'text-size': 16,
            },
            paint: {
                'text-color': '#a0b3c6',
            }
        });
    }

    const createHeatmap = (map:any, sourceId: any, markerData: any) => {
        removeSourceAndLayers(map, sourceId);
        setPanIndex(0);
        map.addSource(sourceId, {
            type: 'geojson',
            data: markerData
        });
        // Add circle layer to simulate heatmap effect
        map.addLayer({
            id: 'unclustered-heatmap',
            type: 'heatmap',
            source: sourceId,
            'paint': {
                'heatmap-weight': {
                    property: 'dbh',
                    type: 'exponential',
                    stops: [
                        [1, 0],
                        [62, 1]
                    ]   
                },
                'heatmap-intensity': {
                    stops: [
                    [11, 1],
                    [15, 3]
                    ]
                },
                'heatmap-color': [
                    'interpolate',
                    ['linear'],
                    ['heatmap-density'],
                    0,
                    'rgba(236,222,239,0)',
                    0.2,
                    'rgb(208,209,230)',
                    0.4,
                    'rgb(166,189,219)',
                    0.6,
                    'rgb(103,169,207)',
                    0.8,
                    'rgb(28,144,153)'
                ],
                'heatmap-radius': {
                    stops: [
                        [13, 16],
                        [16, 20]
                    ]
                },
                'heatmap-opacity': {
                    default: 1,
                    stops: [
                        [16, 1],
                        [17, 0]
                    ]
                }
            }
        });
        map.addLayer({
            'id': 'unclustered-point',
            'type': 'circle',
            'source': sourceId,
            'minzoom': 15,
            'paint': {
                'circle-radius': {
                    'property': 'dbh',
                    'type': 'exponential',
                    'stops': [
                    [{ zoom: 17, value: 1 }, 5],
                    [{ zoom: 17, value: 62 }, 10],
                    [{ zoom: 22, value: 1 }, 20],
                    [{ zoom: 22, value: 62 }, 50]
                    ]
                },
                'circle-color': {
                    'property': 'dbh',
                    'type': 'exponential',
                    'stops': [
                    [0, 'rgba(3, 220, 220, 1)'],
                    [10, 'rgba(3, 220, 220, 1)'],
                    [20, 'rgb(208,209,230)'],
                    [30, 'rgb(166,189,219)'],
                    [40, 'rgb(103,169,207)'],
                    [50, 'rgb(28,144,153)'],
                    [60, 'rgb(1,108,89)']
                    ]
                },
                'circle-stroke-color': '#03dcdc',
                'circle-stroke-width': 1,
                'circle-opacity': {
                    'stops': [
                    [14, 0],
                    [15, 1]
                    ]
                }
            }
        });   
        
    }

    const removeTrajectory = () => {
        const mapMain = mapRef.current;
        if (!mapMain){
          return;
        }
        if (mapMain.getLayer('directions')) {
            mapMain.removeLayer('directions');
          }
        if (mapMain.getLayer('line-background')) {
          mapMain.removeLayer('line-background');
        }
        if (mapMain.getLayer('line-dashed')) {
          mapMain.removeLayer('line-dashed');
        }
    
        if (mapMain.getLayer('markersSourceLayer')) {
          mapMain.removeLayer('markersSourceLayer');
        }
      
        if (mapMain.getSource('trajectorySource')) {
          mapMain.removeSource('trajectorySource');
        }
        if (mapMain.getSource('markersSource')) {
          mapMain.removeSource('markersSource');
        }
    
    };

  const createLineString = (trajectory: any) => {
        const mapMain = mapRef.current;

        if (!mapMain){
            return;
        }

        removeTrajectory();
        mapMain.addSource('trajectorySource', {
            type: 'geojson',
            data: {
            type: 'FeatureCollection',
            features:[trajectory.geojson]
            }
        });

        let features: any = [];
        for (var i in trajectory.geojson.properties.timestamps) {
            var point = trajectory.geojson.geometry.coordinates[i];
            var ts = dayjs(trajectory.geojson.properties.timestamps[i]).format('MM:DD-HH:mm');
            features.push({
                type: "Feature",
                geometry: {
                    type: "Point",
                    coordinates: point,
                },
                properties: {
                    ts: ts,
                    index: Number(i) + 1
                }
            })
        }
        

        mapMain.addSource('markersSource', {
        type: 'geojson',
        data: {
            type: 'FeatureCollection',
            features:features
        }
        });

        mapMain.addLayer({
            id: 'markersSourceLayer',
            type: 'symbol',
            source: 'markersSource',
            'layout': {
                'text-field': '{index}   {ts}',
                "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
                "text-anchor": "bottom",
                'icon-allow-overlap': true,  // The icon will be visible even if it collides with other previously drawn symbols.
                'icon-ignore-placement': true,  // Other symbols can be visible even if they collide with the icon.
                'text-optional': true  // Label will be hidden if it collides with other labels
            },
            paint: {
                'icon-translate': [-25, -25],
                'text-translate': [-28, -28],
                'text-color': '#02DBDB',
                'text-halo-color': '#233333',
                'text-halo-width': 10,
            }
        });

        mapMain.addLayer({
          type: 'line',
          source: sourceId,
          id: 'line-background',
          paint: {
            'line-color': 'yellow',
            'line-width': 6,
            'line-opacity': 0.4
          }
        });
        mapMain.addLayer({
            id: 'directions',
            type: 'symbol',
            source: 'trajectorySource',
            layout: {
                'symbol-placement': 'line',
                'icon-image': 'arrow',
                'icon-rotate': 90,
                'icon-size': 0.2,
                'icon-rotation-alignment': 'map',
                'icon-allow-overlap': true,
                'icon-ignore-placement': false
            }
        });

        if (tracerStyle == 'arrows'){
            mapMain.addLayer({
                type: 'line',
                source: 'trajectorySource',
                id: 'line-dashed',
                paint: {
                    'line-color': '#16BCD4',
                    'line-width': 6
                }
            });

            mapMain.flyTo({
                center: [selectedRecord.lon, selectedRecord.lat],
                zoom: 22,
                essential: true
            }); 
            return;
        }
        mapMain.addLayer({
            type: 'line',
            source: 'trajectorySource',
            id: 'line-dashed',
            paint: {
                'line-color': '#16BCD4',
                'line-width': 6,
                'line-dasharray': [0, 4, 3]
            }
        });
        mapMain.flyTo({
            center: [selectedRecord.lon, selectedRecord.lat],
            zoom: 22,
            essential: true
        }); 
        const dashArraySequence = [
        [0, 4, 3],
        [0.5, 4, 2.5],
        [1, 4, 2],
        [1.5, 4, 1.5],
        [2, 4, 1],
        [2.5, 4, 0.5],
        [3, 4, 0],
        [0, 0.5, 3, 3.5],
        [0, 1, 3, 3],
        [0, 1.5, 3, 2.5],
        [0, 2, 3, 2],
        [0, 2.5, 3, 1.5],
        [0, 3, 3, 1],
        [0, 3.5, 3, 0.5]
        ];

        let step = 0;
        function animateDashArray(timestamp: number) {
        const newStep = Math.trunc((timestamp / 80) % dashArraySequence.length);
        if (!mapMain?.getLayer('line-dashed')) {
            return;
        }
        if (newStep !== step) {
            mapMain.setPaintProperty(
            'line-dashed',
            'line-dasharray',
            dashArraySequence[step]
            );
            step = newStep;
        }

        requestAnimationFrame(animateDashArray);
        }

        animateDashArray(0);
    };

    const validatePolygon = (e: any, draw: MapboxDraw, geojsonData: any, mode: any) => {
        const drawnPolygon = e.features[0];
        const drawnPolygonGeometry = drawnPolygon.geometry;
        const map = mapRef.current;
        dispatch(setFeatureId(drawnPolygon.id));
        const withinBoundaries = geojsonData.features.some((feature: any) => {
            return booleanWithin(turf.polygon(drawnPolygonGeometry.coordinates), feature);
        });
        // Function to remove all layers and sources
        const removeLayerAndSource = () => {
            const polygonSourceId = `current-polygon`;
            const polygonLayerId = `area-search`;
            const polygonOutlineLayerId = `outline-polygon`;
            if (map) {
                // Remove existing source and layers if they exist
                if (map.getLayer(polygonLayerId)) {
                    map.removeLayer(polygonLayerId);
                }
                if (map.getLayer(polygonOutlineLayerId)) {
                    map.removeLayer(polygonOutlineLayerId);
                }
                if (map.getSource(polygonSourceId)) {
                    map.removeSource(polygonSourceId);
                }
            }
        };

        if (!withinBoundaries && draw) {
            // If polygon is not within boundaries, delete it from the draw layer
            draw.delete(drawnPolygon.id);

            removeLayerAndSource();
            Modal.error({
                title: 'Boundary Limits',
                content: (
                    <p style={{ color: 'white', fontSize: 16 }}>
                        Polygons can only be drawn within the specified boundaries!
                    </p>
                ),
                onOk() { },
            });
        } else {
            // Cleanup cluster-related and polygon-related layers and sources if necessary
            removeLayerAndSource();
            const allPolygons = draw.getAll().features.filter((feature: any) => feature.geometry.type === 'Polygon');
            dispatch(setSegm('Drag Edit'));
            setTimeout(() => {
                dispatch(clearSession());
                dispatch(resetQueryForm(true));

                // Dispatch the new polygon to the state
                if (sessionType) {
                    dispatch(toggleRightSidebar(true));
                    dispatch(toggleAnalyticsSidebar(false));
                }
                if (allPolygons.length == 1){
                    dispatch(setPolygon(drawnPolygon));
                }else{
                    dispatch(setMultiPolygon(allPolygons));
                }
                dispatch(removeMarkers());

            }, 100); // Adjust delay if needed
        }
    };

    const zoomToGeohash = (geohash_string: string) => {
        const map = mapRef.current;
        // const polygon = geohashToPolygonFeature(panningAreas[pannedIndex]);
        const coordinates = geohash.decode(geohash_string);
        var use_zoom = zoom;
        if (map){
            if(map.getZoom() < 10){
                use_zoom = 14;
            }else if (map.getZoom() > 20){
                use_zoom = 16;
            }
            map.flyTo({
                center: [coordinates.longitude, coordinates.latitude],
                zoom: use_zoom, // we must calculate zoom lvl with polygon at some point
            });
        }
    }

    const handlePanningForward = () => {
        if (panningAreas.length - 1 == pannedIndex){
            zoomToGeohash(panningAreas[0]);
            setPanIndex(0);
        }else{
            zoomToGeohash(panningAreas[pannedIndex + 1]);
            setPanIndex(pannedIndex + 1);
        }
        
    }

    const handlePanningBackwards = () => {
        if (pannedIndex == 0){
            zoomToGeohash(panningAreas[panningAreas.length - 1]);
            setPanIndex(panningAreas.length - 1);
        }else{
            zoomToGeohash(panningAreas[pannedIndex - 1]);
            setPanIndex(pannedIndex - 1);
        }
    }

    const icons = [
        <RightOutlined type="primary" key="right" onClick={handlePanningForward}/>,
        <LeftOutlined type="primary" key="left" onClick={handlePanningBackwards}/>
    ];
    
    const insetInlineEnd: React.CSSProperties['insetInlineEnd'][] = [ -(100 / 2), 100 - 40 / 2];
    const bottom: React.CSSProperties['bottom'][] = [
        (100 - 40) / 2,
        (100 - 40) / 2
    ];

    return (
        <>
            {!mapLoaded || loader && (
                <Flex align="center" justify="center" style={{ height: "100vh" }}>
                    <Spin size="large" />
                </Flex>
            )}
            {mapLoaded && ['Monitor', 'Area Search'].includes(sessionType)  
            && enableDrawing && <ControlPanel map={mapRef} drawRef={drawRef}></ControlPanel>}

            {mapLoaded && <SettingsComponent />}
            {mapLoaded && panningAreas && panningAreas.length > 1 && <Flex justify="space-evenly" align="center" style={{ width: '100%', height: '100vh', overflow: 'hidden', position: 'relative'}}>
                <div style={{width: 100, height: 100, position: "absolute", right: width / 2}}>
                        {(['right', 'left'] as const).map((placement, i) => {
                        
                        var top = height / 5;
                        if (sessionType == 'Identify'){
                            top = 518;
                        }
                        return (
                            <FloatButton.Group
                            key={placement}
                            trigger="click"
                            open={false}
                            placement={placement}
                            style={{
                                position: 'absolute',
                                insetInlineEnd: insetInlineEnd[i],
                                bottom: bottom[i],
                                top: top,
                                zIndex: 2
                            }}
                            icon={icons[i]}
                            >
                            <FloatButton />
                            <FloatButton icon={<CommentOutlined />} />
                            </FloatButton.Group>
                        );
                        })}
                    </div>
                </Flex>
            }

            <div
                ref={mapContainer}
                style={{
                    opacity: (mapLoaded && !loader) ? "1" : "0",
                    position: "absolute",
                    background: "#101827",
                    top: 0,
                    bottom: 0,
                    zIndex: 1,
                    width: "100%",
                }}
            ></div>
            {/* {analyticsSidebar&& <BottomTabComponent></BottomTabComponent>} */}
        </>
    );
};

export default MapComponent;