export const dummydata =[
    {
      "name": "JohnDoe",
      "timeframe": {
        "start": "2024-08-23T07:58:34.112Z",
        "end": "2024-08-23T08:58:34.112Z"
      },
      "area_search": [
        {
          "bbox": [
            -74.006,
            40.7128,
            -73.935242,
            40.73061
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -74.006,
              40.7128,
              -73.935242,
              40.73061
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -74.006,
                  40.7128
                ],
                [
                  -74.001,
                  40.715,
                  -73.998
                ],
                [
                  -73.935242,
                  40.73061
                ],
                [
                  -73.930,
                  40.732,
                  -73.925
                ]
              ]
            ]
          },
          "properties": {},
          "id": 1
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "M",
        "maid": "a1b2c3d4",
        "device_brand": "Apple",
        "device_model": "iPhone 14",
        "ip": "192.168.1.1",
        "app": "Instagram",
        "operator": "Verizon",
        "id_type": "ADID"
      }
    },
    {
      "name": "JaneDoe",
      "timeframe": {
        "start": "2024-08-23T09:00:00.000Z",
        "end": "2024-08-23T10:00:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            -118.2437,
            34.0522,
            -118.1937,
            34.0622
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -118.2437,
              34.0522,
              -118.1937,
              34.0622
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -118.2437,
                  34.0522
                ],
                [
                  -118.243,
                  34.053,
                  -118.240
                ],
                [
                  -118.1937,
                  34.0622
                ],
                [
                  -118.193,
                  34.063,
                  -118.190
                ]
              ]
            ]
          },
          "properties": {},
          "id": 2
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "F",
        "maid": "e5f6g7h8",
        "device_brand": "Samsung",
        "device_model": "Galaxy S23",
        "ip": "192.168.2.2",
        "app": "Snapchat",
        "operator": "AT&T",
        "id_type": "ADID"
      }
    },
    {
      "name": "AliceSmith",
      "timeframe": {
        "start": "2024-08-23T11:15:00.000Z",
        "end": "2024-08-23T12:15:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            -0.1278,
            51.5074,
            -0.1178,
            51.5174
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -0.1278,
              51.5074,
              -0.1178,
              51.5174
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -0.1278,
                  51.5074
                ],
                [
                  -0.127,
                  51.508,
                  -0.124
                ],
                [
                  -0.1178,
                  51.5174
                ],
                [
                  -0.117,
                  51.518,
                  -0.114
                ]
              ]
            ]
          },
          "properties": {},
          "id": 3
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "F",
        "maid": "i9j0k1l2",
        "device_brand": "Google",
        "device_model": "Pixel 7",
        "ip": "192.168.3.3",
        "app": "TikTok",
        "operator": "Vodafone",
        "id_type": "ADID"
      }
    },
    {
      "name": "BobBrown",
      "timeframe": {
        "start": "2024-08-23T13:30:00.000Z",
        "end": "2024-08-23T14:30:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            -122.4194,
            37.7749,
            -122.4094,
            37.7849
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -122.4194,
              37.7749,
              -122.4094,
              37.7849
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -122.4194,
                  37.7749
                ],
                [
                  -122.419,
                  37.776,
                  -122.416
                ],
                [
                  -122.4094,
                  37.7849
                ],
                [
                  -122.409,
                  37.785,
                  -122.406
                ]
              ]
            ]
          },
          "properties": {},
          "id": 4
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "M",
        "maid": "m3n4o5p6",
        "device_brand": "OnePlus",
        "device_model": "OnePlus 11",
        "ip": "192.168.4.4",
        "app": "Facebook",
        "operator": "T-Mobile",
        "id_type": "ADID"
      }
    },
    {
      "name": "CharlieWhite",
      "timeframe": {
        "start": "2024-08-23T15:45:00.000Z",
        "end": "2024-08-23T16:45:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            -74.006,
            40.7128,
            -73.935242,
            40.73061
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -74.006,
              40.7128,
              -73.935242,
              40.73061
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -74.006,
                  40.7128
                ],
                [
                  -74.001,
                  40.715,
                  -73.998
                ],
                [
                  -73.935242,
                  40.73061
                ],
                [
                  -73.930,
                  40.732,
                  -73.925
                ]
              ]
            ]
          },
          "properties": {},
          "id": 5
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "M",
        "maid": "q7r8s9t0",
        "device_brand": "Huawei",
        "device_model": "P50 Pro",
        "ip": "192.168.5.5",
        "app": "Twitter",
        "operator": "Sprint",
        "id_type": "ADID"
      }
    },
    {
      "name": "EmilyGreen",
      "timeframe": {
        "start": "2024-08-23T17:00:00.000Z",
        "end": "2024-08-23T18:00:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            2.3522,
            48.8566,
            2.3622,
            48.8666
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              2.3522,
              48.8566,
              2.3622,
              48.8666
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  2.3522,
                  48.8566
                ],
                [
                  2.352,
                  48.857,
                  2.354
                ],
                [
                  2.3622,
                  48.8666
                ],
                [
                  2.362,
                  48.867,
                  2.364
                ]
              ]
            ]
          },
          "properties": {},
          "id": 6
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "F",
        "maid": "u1v2w3x4",
        "device_brand": "Xiaomi",
        "device_model": "Mi 11",
        "ip": "192.168.6.6",
        "app": "LinkedIn",
        "operator": "Orange",
        "id_type": "ADID"
      }
    },
    {
      "name": "DavidBlack",
      "timeframe": {
        "start": "2024-08-23T19:15:00.000Z",
        "end": "2024-08-23T20:15:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            139.6917,
            35.6895,
            139.7017,
            35.6995
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              139.6917,
              35.6895,
              139.7017,
              35.6995
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  139.6917,
                  35.6895
                ],
                [
                  139.692,
                  35.690,
                  139.694
                ],
                [
                  139.7017,
                  35.6995
                ],
                [
                  139.702,
                  35.700,
                  139.704
                ]
              ]
            ]
          },
          "properties": {},
          "id": 7
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "M",
        "maid": "y5z6a7b8",
        "device_brand": "Sony",
        "device_model": "Xperia 5",
        "ip": "192.168.7.7",
        "app": "YouTube",
        "operator": "Docomo",
        "id_type": "ADID"
      }
    },
    {
      "name": "SophiaBlue",
      "timeframe": {
        "start": "2024-08-23T21:30:00.000Z",
        "end": "2024-08-23T22:30:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            -3.7038,
            40.4168,
            -3.6938,
            40.4268
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -3.7038,
              40.4168,
              -3.6938,
              40.4268
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -3.7038,
                  40.4168
                ],
                [
                  -3.703,
                  40.417,
                  -3.700
                ],
                [
                  -3.6938,
                  40.4268
                ],
                [
                  -3.693,
                  40.427,
                  -3.690
                ]
              ]
            ]
          },
          "properties": {},
          "id": 8
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "F",
        "maid": "c9d0e1f2",
        "device_brand": "Oppo",
        "device_model": "Reno 8",
        "ip": "192.168.8.8",
        "app": "Spotify",
        "operator": "Movistar",
        "id_type": "ADID"
      }
    },
    {
      "name": "JamesGrey",
      "timeframe": {
        "start": "2024-08-23T23:45:00.000Z",
        "end": "2024-08-24T00:45:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            151.2093,
            -33.8688,
            151.2193,
            -33.8588
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              151.2093,
              -33.8688,
              151.2193,
              -33.8588
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  151.2093,
                  -33.8688
                ],
                [
                  151.210,
                  -33.868,
                  151.212
                ],
                [
                  151.2193,
                  -33.8588
                ],
                [
                  151.220,
                  -33.858,
                  151.222
                ]
              ]
            ]
          },
          "properties": {},
          "id": 9
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "M",
        "maid": "g3h4i5j6",
        "device_brand": "Vivo",
        "device_model": "X90",
        "ip": "192.168.9.9",
        "app": "Netflix",
        "operator": "Telstra",
        "id_type": "ADID"
      }
    },
    {
      "name": "OliviaBrown",
      "timeframe": {
        "start": "2024-08-24T02:00:00.000Z",
        "end": "2024-08-24T03:00:00.000Z"
      },
      "area_search": [
        {
          "bbox": [
            -43.1729,
            -22.9068,
            -43.1629,
            -22.8968
          ],
          "type": "Feature",
          "geometry": {
            "bbox": [
              -43.1729,
              -22.9068,
              -43.1629,
              -22.8968
            ],
            "type": "Polygon",
            "coordinates": [
              [
                [
                  -43.1729,
                  -22.9068
                ],
                [
                  -43.172,
                  -22.906,
                  -43.169
                ],
                [
                  -43.1629,
                  -22.8968
                ],
                [
                  -43.162,
                  -22.896,
                  -43.159
                ]
              ]
            ]
          },
          "properties": {},
          "id": 10
        }
      ],
      "filters": {
        "rat": "5G",
        "gender": "F",
        "maid": "k7l8m9n0",
        "device_brand": "Realme",
        "device_model": "GT 2",
        "ip": "192.168.10.10",
        "app": "WhatsApp",
        "operator": "Vivo",
        "id_type": "ADID"
      }
    }
  ]
  