// src/redux/widgetSlice.js
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '..';



export interface ProfileData {
  device_id: string;
  apps: any[];
  ids: any[];
  ips: any[];
  ssids: any[];
  social_media: any[];
  name: string | null;
  msisdn: string | null;
  email: string | null;
  gender: string | null;
  age: number | null;
  devices: any[];
  carriers: any[];
  browsers: any[];
  cellids: any[];
  dean_status: string | null;
  total_events: number | 0;
  co_travellers: any[];
  activities: any[];
  target_id: string | null;
  keywords: any[];
  relationships: any[];

}

interface ProfileState {
    loading: boolean;
    error: string | null;
    profileData: ProfileData | null;
    visible: boolean;
    sessionType: string;
    sessionId: string;
    deviceId: string;
}


const initialState: ProfileState = {
    loading: false,
    error: null,
    visible: false,
    profileData: null,
    sessionType: '',
    sessionId: '',
    deviceId: ''
};


// Async thunk for downloading the report as PDF
export const fetchProfile = createAsyncThunk(
    'session/fetchProfile',
    async ({
      deviceId = '', 
      sessionId = '', 
      sessionType = ''
    }: 
    { 
      deviceId: string, 
      sessionId: string, 
      sessionType: string 
    }, { getState, rejectWithValue }) => {
      const state = getState() as RootState;
      const token =
        state.auth.token ||
        localStorage.getItem("token") ||
        sessionStorage.getItem("token");
      if (!token) {
        return rejectWithValue("No token available");
      }
      try {
        const response = await axios.get(
          `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL}/${window.__RUNTIME_CONFIG__.ADINT_ENV}/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION}/sessions/datatables/profiler`,
          {
            params:{
              session_id: sessionId,
              session_type: sessionType,
              device_id: deviceId,
            },
            headers: {
              accept: "application/json",
              Authorization: `Bearer ${token}`
            },
          }
        );
  
        // Map the response data to the AnalyticsData interface
  
        return response.data;
      } catch (err: any) {
        return rejectWithValue(
          err.response?.data?.message || "Failed to fetch analytics data"
        );
      }
    }
  );

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setProfileVisible: (state, action: PayloadAction<boolean>) => {
      state.visible = action.payload;
    },
    setSessionType: (state, action: PayloadAction<string>) => {
      state.sessionType = action.payload;
    },
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    setDeviceId: (state, action: PayloadAction<string>) => {
      state.deviceId = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
    .addCase(fetchProfile.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchProfile.fulfilled, (state, action: PayloadAction<any>) => {
        state.profileData = action.payload;
        state.loading = false;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = action.payload as string;
        state.loading = false;
        state.visible = false;
      });
  }
});

export const {
  setProfileVisible,
  setDeviceId,
  setSessionType,
  setSessionId,
  setLoading
 } = profileSlice.actions;
export default profileSlice.reducer;
