import React, { useEffect, useRef, useState } from "react";
import { Card, Button, Collapse, Descriptions, Typography, Tag, Space, Spin, Badge, Statistic } from "antd";
import './FlippingCard.css';
import { Content } from "antd/es/layout/layout";
import mapboxgl from 'mapbox-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import MapDynamic from "./MapDynamic";
import dayjs from 'dayjs';

import type { CountdownProps } from 'antd';
const { Countdown } = Statistic;


const { Panel } = Collapse;

interface TaskCardProps {
    active: string,
    session: {
        session_id: any;
        status: any;
        user_viewed: boolean;
        task_config:any;
        next_run: string;
        ended_on: string;
        run_counter: number;
        created_at: string;
        updated_at: string;
        filters: {
            gender: Array<string> | [];
            rat: Array<string> | [];
            device_id: Array<string> | [];
            device_brand: Array<string> | [];
            device_model: Array<string> | [];
            ip: Array<string> | [];
            app: Array<string> | [];
            carrier: Array<string> | [];
            ssid: Array<string> | [];
            bssid: Array<string> | [];
            id_type: Array<string> | [];
            location_type: Array<string> | [];
        };
        session_type: string;
        data_count: number;
    },
    onActivated: (evt: any, polygon: any) => any;
}

const TaskCard: React.FC<TaskCardProps> = ({ session, active, onActivated }) => {
    const [flipped, setFlipped] = useState(false);
    const [startTime, setStartTime] = useState<any>('');
    const [endTime, setEndTime] = useState<any>('');
    const map = useRef<mapboxgl.Map | null>(null);
    const [currentPolygon, setcurrentPolygon] = useState<any>('');
    const mapContainer = useRef<HTMLDivElement | null>(null);
    const toggleFlip = (event: React.MouseEvent) => {
        event.stopPropagation();
        setFlipped(!flipped);
    };

    const handleCardClick = () => {
        onActivated(session.session_id, {
            "type": "FeatureCollection",
            "features": [session.task_config.geofence]
        });
    };
    const parseDate = (dateString: string) => {
        const tempDate = new Date(dateString);

        // Get the day name
        const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        const dayName = daysOfWeek[tempDate.getDay()];

        // Get the month name
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const monthName = months[tempDate.getMonth()];

        // Get the day number, year, and time parts
        const dayNum = tempDate.getDate();
        const year = tempDate.getFullYear();
        let hours = tempDate.getHours();
        const minutes = tempDate.getMinutes();
        const seconds = tempDate.getSeconds();
        const period = hours >= 12 ? "PM" : "AM";

        // Convert 24-hour format to 12-hour format
        hours = hours % 12 || 12;

        // Format numbers to always have two digits
        const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);

        // Return all parts as an object
        return {
            dayName,
            monthName,
            dayNum: formatNumber(dayNum),
            year: year.toString(),
            hours: formatNumber(hours),
            minutes: formatNumber(minutes),
            seconds: formatNumber(seconds),
            period
        };
    };
    const { filters, session_type, data_count, status, user_viewed, next_run, ended_on, updated_at, created_at, task_config } = session;
    const handleAccordionClick = (event: any) => {
        // Prevent card click from being triggered when interacting with the accordion
        event.stopPropagation();
    };
    useEffect(() => {
        if (session.task_config.operation == 'Geofence' || session.task_config.operation == 'Border Control'){
            setcurrentPolygon({
                "type": "FeatureCollection",
                "features": [session.task_config.geofence]
            });
        }
    }, [session])
    useEffect(() => {
        // Parse the  date and set the state with the returned object
        if (next_run && updated_at) {
            setEndTime(parseDate(next_run));
            setStartTime(parseDate(updated_at));
        }

    }, [updated_at]); // Dependency array contains apoel, so it runs when apoel changes

    return (
        <>
            <Badge.Ribbon text={session.data_count}  color="#03dcdc">

                <Card
                    className={`flipping-card ${flipped ? 'flipped' : ''}`}
                    hoverable
                    onClick={handleCardClick}
                    style={{
                        marginBottom: '4px',
                        border: '0px solid #27e8e1'
                    }}
                >
                    <Content className="flipping-card-inner">
                        <div onClick={handleAccordionClick} className={`flipping-card-front ${!flipped ? 'active' : ''}`}  >

                            <Collapse defaultActiveKey={['0']} accordion style={{ 
                                boxShadow: (!user_viewed && data_count) ? '5px 8px 24px 5px rgba(0, 216, 243, 0.6)': 'none',
                                border: (active === session.session_id) ? '2px solid #27e8e1' : ''
                            }}
                            >
                                {/* Session Information */}
                                <Panel header={
                                    <Space>{task_config.operation} {!user_viewed && data_count ? <Tag color='error'>New Data</Tag>: <Tag color="cyan">
                                        { 
                                        <div >
                                            {session.status != 'COMPLETED' && <Countdown valueStyle={{color: "#03dcdc", fontSize: '1.2em', borderRadius: '300px'}} value={next_run} format='DD-HH:mm:ss'/>}

                                        </div>
                                        }
                                    </Tag>}
                                    </Space>} key="1">
                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label="Updated On" span={4}>
                                            <div className="datetime">
                                                <div className="date">
                                                    <span id="dayname">{startTime.dayName} </span>
                                                    <span id="month">{startTime.monthName} </span>
                                                    <span id="daynum">{startTime.dayNum} </span>
                                                </div>
                                                <div className="time">
                                                    <span id="hour">{startTime.hours} </span>:
                                                    <span id="minutes">{startTime.minutes}</span>:
                                                    <span id="seconds">{startTime.seconds}</span>
                                                    <span id="period">{startTime.period}</span>
                                                </div>
                                                <div className="date">
                                                    <span id="year">{startTime.year} </span>
                                                </div>
                                            </div>
                                        </Descriptions.Item>
                                        <Descriptions.Item label="Next Run" span={4}>
                                            <div className="datetime">
                                                <div className="date">
                                                    <span id="dayname">{endTime.dayName} </span>
                                                    <span id="month">{endTime.monthName} </span>
                                                    <span id="daynum">{endTime.dayNum} </span>,
                                                </div>
                                                <div className="time">
                                                    <span id="hour">{endTime.hours} </span>:
                                                    <span id="minutes">{endTime.minutes} </span>:
                                                    <span id="seconds">{endTime.seconds} </span>
                                                    <span id="period">{endTime.period} </span>
                                                </div>
                                                <div className="date">
                                                    <span id="year">{endTime.year} </span>
                                                </div>
                                            </div></Descriptions.Item>
                                        {["Geofence", "Border Control"].includes(task_config?.operation) && <Descriptions.Item label={session.session_type} span={4}>
                                            <MapDynamic markerCoordinates={[40, -74]} polygonData={currentPolygon} />
                                        </Descriptions.Item>}
                                    </Descriptions>
                                </Panel>

                                
                            </Collapse>
                        </div>
                        <div className={`flipping-card-back ${flipped ? 'active' : ''}`} title="Filters" onClick={handleAccordionClick}>
                            <Collapse defaultActiveKey={['0']} accordion                         // Prevent accordion click from activating the card
                            >
                                {/* Session Information */}


                                {/* Device Information */}
                                <Panel header="Device Information" key="1">
                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label={"Device ID"} span={4}>{filters.device_id || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Device Brand"} span={4}>{filters.device_brand || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Device Model"} span={4}>{filters.device_model || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"App"} span={4}>{filters.app || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Rat"} span={4}>{filters.rat || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"Carrier"} span={4}>{filters.carrier || 'N/A'}</Descriptions.Item>


                                    </Descriptions>
                                </Panel>

                                {/* Personal Identifiable Information */}
                                <Panel header="Personal Identifiable Information" key="2">
                                    <Descriptions layout="vertical"
                                    >
                                        <Descriptions.Item label={"Gender"} span={4}>{filters.gender || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"IP"} span={4}>{filters.ip || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"SSID"} span={4}>{filters.ssid || 'N/A'}</Descriptions.Item>
                                        <Descriptions.Item label={"BSSID"} span={4}>{filters.bssid || 'N/A'}</Descriptions.Item>
                                    </Descriptions>
                                </Panel>
                            </Collapse>
                        </div>
                    </Content>
                    <Button type={flipped ? "primary" : "default"} style={{ margin: '4px 0' }} onClick={toggleFlip}>
                        {flipped ? "Show Info" : "Show Filters"}
                    </Button>
                </Card>    
            </Badge.Ribbon>
        </>
    );
};

export default TaskCard;
