import React, { useState } from 'react';
import { Layout, Tabs, Select } from 'antd';
import QueryForm from './QueryForm';
import AnalyticsTable from './IdentifyTable';
import { ColumnWidthOutlined } from '@ant-design/icons'; // Import the icon
import AdvancedAnalytics from './AdvancedAnalytics';

const { Sider } = Layout;
const { Option } = Select;

const TaskSiderbar: React.FC<{ collapsed: boolean; onCollapse: (collapsed: boolean) => void; onFilterChange: (value: any) => void; }> = ({ collapsed, onCollapse, onFilterChange }) => {
  // State to keep track of the current tab
  const [currentTab, setCurrentTab] = useState<string>('1');

  // State to manage the width of the Sider
  const [siderWidth, setSiderWidth] = useState<any>(500);
  const [siderId, setSiderId] = useState<any>('small');

  // Handler to update the current tab
  const handleTabChange = (key: string) => {
    setCurrentTab(key);
    if(key=='2'){
      handleWidthChange('large');
      setSiderId('large');
    }
  };

  // Handler to update the width based on the dropdown selection
  const handleWidthChange = (value: string) => {
    switch (value) {
      case 'small':
        setSiderWidth(500);
        setSiderId('small');
        break;
      case 'medium':
        setSiderWidth('calc(75% - 350px)');
        setSiderId('medium');

        break;
      case 'large':
        setSiderWidth('calc(100% - 350px)');
        setSiderId('large');

        break;
      default:
        setSiderId('small');

        setSiderWidth(400);
    }
  };

  return (
    <Sider
      width={siderWidth}
      style={{
        backgroundColor: 'rgba(16, 24, 39, 0.8)',
        padding: '10px',
        position: 'absolute',
        top: 45,
        right: 0,
        bottom: 0,
        zIndex: 10,
        overflowY: 'auto',
        opacity: !collapsed ? "0" : "1",
        transition: "all .2s",
        visibility: !collapsed ? "hidden" : "visible",
      }}
    >
      {/* Dropdown to select Sider width */}
      <div style={{ marginBottom: '10px', display: 'flex', alignItems: 'center' }}>
         {/* Add the icon here */}
        <Select value={siderId} style={{ width: 120 }} onChange={handleWidthChange}>
        <Option value="small" disabled={currentTab === '2'}>
            <ColumnWidthOutlined style={{ marginRight: 8, fontSize: 10 }} /> Small
          </Option>
          <Option value="medium" disabled={currentTab === '2'}>
            <ColumnWidthOutlined style={{ marginRight: 8, fontSize: 12 }} /> Medium
          </Option>
          <Option value="large"><ColumnWidthOutlined style={{ marginRight: 8,fontSize:16 }} /> Large</Option>
        </Select>
      </div>

      <Tabs
        defaultActiveKey="1"
        activeKey={currentTab}  // Set the active tab based on the state
        onChange={handleTabChange}  // Update state when tab changes
        items={[
          {
            label: 'Data Table',
            key: '1',
            children: <AnalyticsTable tableSize={siderId} />,
            disabled: false,
          },
          {
            label: 'Advanced Analytics',
            key: '2',
            children: <AdvancedAnalytics tableSize={siderId} siderWidth={siderWidth} />,
            disabled: false,
          }
        ]}
      />
    </Sider>
  );
};

export default TaskSiderbar;