// src/redux/widgetSlice.js
import { createSlice } from '@reduxjs/toolkit';
const tempData = localStorage.getItem('widgetPositions');
// Load widget positions from localStorage if available
const savedWidgetPositions = tempData? JSON.parse(tempData): {};

const initialState = {
  widgetPositions: savedWidgetPositions, // Initialize with saved positions
};

const widgetSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    setWidgetPosition: (state, action) => {
      const { key, position } = action.payload;
      state.widgetPositions[key] = position;
      localStorage.setItem('widgetPositions', JSON.stringify(state.widgetPositions)); // Save to localStorage
    },
    resetWidgetPositions: (state) => {
      state.widgetPositions = {};
      localStorage.removeItem('widgetPositions'); // Clear from localStorage
    },
  },
});

export const { setWidgetPosition, resetWidgetPositions } = widgetSlice.actions;
export default widgetSlice.reducer;
