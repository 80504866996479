import React, { useState } from 'react';
import { Popconfirm, Button, Radio } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import { useSelector, useDispatch } from 'react-redux';
import { RootState, AppDispatch } from '../store';
import { setMarkerStyle, setShowPolygons, setPathTracerStyle } from '../store/slices/MapSettingsSlice';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import { selectMarkerStyle, selectPathTracerStyle, selectShowPolygons } from '../store/selectors/mapSettingsSelectors';

const SettingsComponent: React.FC = () => {
    const { height, width } = useWindowDimensions();
    const rightSidebar = useSelector((state: RootState) => state.ui.rightSidebar);
    const analyticsSidebar = useSelector((state: RootState) => state.ui.analyticsSidebar);
    const identifySidebar = useSelector((state: RootState) => state.ui.identifySidebar);

    const currentMarkerStyle = useSelector(selectMarkerStyle);
    const currentShowPolygons = useSelector(selectShowPolygons);
    const pathTracerStyle = useSelector(selectPathTracerStyle);

    const dispatch = useDispatch<AppDispatch>();

    const [markerStyle, setMarkerStyleState] = useState(currentMarkerStyle);
    const [showPolygons, setShowPolygonsState] = useState(currentShowPolygons);
    const [tracerStyle, setTracerStyleState] = useState(pathTracerStyle);

    const handleToggle = (e: any) => {
        setMarkerStyleState(e.target.value);
    };
    const handlePolygonToggle = (e: any) => {
        setShowPolygonsState(e.target.value);
    };
    const handleTracerToggle = (e: any) => {
        setTracerStyleState(e.target.value);
    };
    const handleSave = () => {
        dispatch(setMarkerStyle(markerStyle));
        dispatch(setShowPolygons(showPolygons));
        dispatch(setPathTracerStyle(tracerStyle));

    };
    const content = (
        <div style={{ width: 300, paddingBottom: 24 }}>
            <h3>Select Marker Styling</h3>
            <Radio.Group
                onChange={handleToggle}
                value={markerStyle}
                optionType="button"
                buttonStyle="solid"
                style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
            >
                <Radio.Button value="cluster">Clusters</Radio.Button>
                <Radio.Button value="heatmap">Heatmap</Radio.Button>
            </Radio.Group>
            <h3>Show Searched Areas</h3>
            <Radio.Group
                onChange={handlePolygonToggle}
                value={showPolygons}
                optionType="button"
                buttonStyle="solid"
                style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
            >
                <Radio.Button value={true}>Enabled</Radio.Button>
                <Radio.Button value={false}>Disabled</Radio.Button>
            </Radio.Group>
            <h3>Path Tracer Styling</h3>
            <Radio.Group
                onChange={handleTracerToggle}
                value={tracerStyle}
                optionType="button"
                buttonStyle="solid"
                style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}
            >
                <Radio.Button value='arrows'>Fixed</Radio.Button>
                <Radio.Button value='animated'>Animated Path</Radio.Button>
            </Radio.Group>
        </div>
    );

    return (
        <>
            <Popconfirm
                style={{ padding: 16, height: 200, width: 300 }}
                placement="bottom"
                title={<><SettingOutlined /> Map Settings</>}
                description={content}
                onConfirm={handleSave} // Save to Redux on "Save" click
                okText="Save"
            >
                <Button
                    style={{
                        position: 'absolute',
                        top: 70,
                        right: 180 + (analyticsSidebar || rightSidebar || identifySidebar ? 360 : 0),
                        zIndex: 2,
                    }}
                >
                    <SettingOutlined />
                </Button>
            </Popconfirm>
        </>
    );
};

export default SettingsComponent;
