import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Card, Spin } from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { AppDispatch } from '../store';
import { selectMarkers, selectMarkerLoading, selectAddedSession, selectInitialMarkers, selectInitialMarkerLoading } from '../store/selectors/sessionSelectors';
import { selectCurrentFilters, selectActiveSession } from '../store/selectors/uiSelectors';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import BrushBarChart from './BrushChart';
import { selectHistogram } from '../store/selectors/timeframeSelectors';
import { selectCurrentIdentify } from '../store/selectors/identifySelectors';
import { fetchHistogram } from '../store/slices/timeframeSlice';

const HistogramSlider: React.FC = () => {
    const [filterRange, setFilterRange] = useState<{ startIndex: number; endIndex: number } | null>(null);
    const [showComponent, setShowComponent] = useState(false); // State to handle timeout visibility
    const markers = useSelector(selectInitialMarkers);
    const filters = useSelector(selectCurrentFilters);
    const markersLoading = useSelector(selectInitialMarkerLoading);
    const dispatch = useDispatch<AppDispatch>();
    const timebarData = markers;
    const [markerData, setMarkers] = useState<any>(null);
    const { height, width } = useWindowDimensions();
    const currentSession = useSelector(selectAddedSession);
    const currentIdentifySession = useSelector(selectCurrentIdentify);
    const sessionType = useSelector(selectActiveSession);
    const histogram = useSelector(selectHistogram);

    // Helper function to format the date to dd/mm/yyyy
    const formatToEuropeanDate = useCallback((ts: string) => {
      const date = new Date(ts);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }, []);
  
    useEffect(() => {
      if (currentSession) {
        dispatch(fetchHistogram({ sessionId: currentSession.session_id.toString(), sessionType: 'AREA'}));
      } 
      else if (currentIdentifySession) {
        dispatch(fetchHistogram({ sessionId: currentIdentifySession.session_id.toString(), sessionType: sessionType.toUpperCase()}));
      }
    }, []);
  
    // Delay the component display by 3 seconds
    useEffect(() => {
      const timer = setTimeout(() => {
        setShowComponent(true);
      }, 3000);
  
      return () => clearTimeout(timer); // Clean up the timeout on unmount
    }, []);
  
// Helper function to convert the timestamp to epoch time (in seconds)


  
  return (
    <Card
      style={{
        position: 'absolute',
        bottom: 0,
        height:height/6,
        left: 350,
        border:0,
        width: (width / 1.28), // Adjust the width as needed
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
        borderLeft: 'none',
        background: 'rgba(16,24,39,0.5)',
        borderRadius: 0,
        zIndex:2
      }}
    >
<Spin percent={'auto'} spinning={markersLoading}>{histogram.length >0 && <BrushBarChart width={(width-750)-50} height={height/6-20}data={histogram}></BrushBarChart>} </Spin>  </Card>
  );
};

export default HistogramSlider;
