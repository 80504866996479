import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { Brush } from '@visx/brush';
import { scaleLinear, scaleTime } from '@visx/scale';
import { Bar } from '@visx/shape';
import { TooltipWithBounds, useTooltip } from '@visx/tooltip';
import { useDispatch, useSelector } from 'react-redux';
import { selectAddedSession, selectMarkerLoading, selectCurrentTask } from '../store/selectors/sessionSelectors';
import { Group } from '@visx/group';
import { AxisLeft, AxisBottom } from '@visx/axis';
import { LinearGradient } from '@visx/gradient';
import { max } from '@visx/vendor/d3-array';
import dayjs from 'dayjs';
import { BrushHandleRenderProps } from '@visx/brush/lib/BrushHandle';
import { fetchData, fetchMarkers } from '../store/slices/sessionSlice';
import { AppDispatch } from '../store';
import { selectActiveSession, selectCurrentFilters } from '../store/selectors/uiSelectors';
import { setBrushTimeRange } from '../store/slices/uiSlice';
import { Button, Card, Space } from 'antd';
import { selectCurrentIdentify } from '../store/selectors/identifySelectors';
import { CaretRightOutlined, PauseOutlined } from '@ant-design/icons';
import { selectHistogram } from '../store/selectors/timeframeSelectors';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);

interface DataPoint {
  epochTs: number;
  count: number;
  uniqueDeviceIds: string[];
}

interface Histogram {
  label: string;
  start: number;
  end: number;
  count: number;
}

interface BrushBarChartProps {
  data: Histogram[];
  width: number;
  height: number;
  // histogram: Histogram[];
}

export type BrushProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  compact?: boolean;
};
const BrushBarChart: React.FC<BrushBarChartProps> = ({ data, width, height }) => {
  const markersLoading = useSelector(selectMarkerLoading);
  const { showTooltip, hideTooltip, tooltipData, tooltipLeft, tooltipTop }: any = useTooltip();


  const brushMargin = { top: 10, bottom: 15, left: 50, right: 40 };
  const margin = { top: 20, right: 100, bottom: 80, left: 20 };
  const xMax = width - margin.left - margin.right;
  if (height < 200){
    var yMax = height - margin.top - margin.bottom;  
  }else{
    var yMax = height - margin.top - margin.bottom - 20;
  }
  const currentSession = useSelector(selectAddedSession);
  const currentIdentifySession = useSelector(selectCurrentIdentify);
  const currentTask = useSelector(selectCurrentTask);

  const dispatch = useDispatch<AppDispatch>();
  const binSize = 60 * 1000; // 1 minute in milliseconds
  const filters = useSelector(selectCurrentFilters);
  const initialBrushBounds = { start: { x: 0, y: 0 }, end: { x: xMax, y: yMax } };
  const sessionType = useSelector(selectActiveSession);
  const histogram = useSelector(selectHistogram);

  const [brushBounds, setBrushBounds] = useState(initialBrushBounds);
  const [isInitialBounds, setIsInitialBounds] = useState(true);
  const [brushKey, setBrushKey] = useState(0); // State to force re-render the Brush component
  let start: any = data[0].start * 1000;
  let end: any = data[data.length - 1].end * 1000
  if (sessionType == 'Area Search'){
    start = currentSession?.timeframe.start;
    end = currentSession?.timeframe.end;
  }else if (sessionType == 'Identify' && currentIdentifySession?.timeframe){
    start = currentIdentifySession?.timeframe.start;
    end = currentIdentifySession?.timeframe.end;
  }else if (sessionType == 'Task' && currentTask?.updated_at){
    start = currentTask?.created_at;
    end = currentTask?.updated_at;
  }

  const [startTime, setStartTime] = useState<any>(dayjs.utc(start));
  const [endTime, setEndTime] = useState<any>(dayjs.utc(end));

  const [runningPlayback, setPlaybackRunning] = useState(false);
  const [nextIndex, setNextIndex] = useState<number>(0);
  const [reRunner, setReRunner] = useState<number>(0);
  const [playbackData, setPlaybackData] = useState<any>(null);


  useEffect(() => {
    const timer = setTimeout(() => {
      setReRunner(reRunner + 1);
    }, 3000);

    return () => clearTimeout(timer); // Clean up the timeout on unmount
  }, [nextIndex]);

  useEffect(() => {
    if (nextIndex > data.length - 1){
      stopPlayback();
      return;
    }
    if (runningPlayback) {
      let entry = playbackData[nextIndex];
      var startTimestamp = dayjs.utc(entry.start * 1000).format('YYYY-MM-DDTHH:mm:ss[Z]');
      var endTimestamp = dayjs.utc(entry.end * 1000).format('YYYY-MM-DDTHH:mm:ss[Z]');
      setStartTime(dayjs.utc(entry.start * 1000));
      setEndTime(dayjs.utc(entry.end * 1000));
      // if (entry.count == 1){
      //   startTimestamp = dayjs(entry.start * 1000).subtract(10,'seconds').format('YYYY-MM-DDTHH:mm:ss[Z]');
      //   endTimestamp = dayjs(entry.end * 1000).add(10,'seconds').format('YYYY-MM-DDTHH:mm:ss[Z]');
      // }
      brushBounds.start.x = entry.start;
      brushBounds.end.x = entry.end;
      if (currentSession) {
        dispatch(fetchMarkers({ sessionId: currentSession.session_id.toString(), sessionType:'AREA', timeframe: { start: startTimestamp, end: endTimestamp }, filters }));
      } else if (currentIdentifySession) {
        dispatch(fetchMarkers({ sessionId: currentIdentifySession.session_id.toString(), sessionType:sessionType.toUpperCase(), timeframe: { start: startTimestamp, end: endTimestamp }, filters }));
      }  
      setNextIndex(nextIndex + 1);
    }
  }, [runningPlayback, reRunner]);

  // Scales
  const xScale = scaleTime({
    domain: [Math.min(...data.map(d => d.start)), Math.max(...data.map(d => d.end))],
    range: [0, xMax],
  });

  const yScale = scaleLinear({
    domain: [0, max(data.map(d => d.count)) || 0],
    range: [yMax, 0],
  });
  // Brush state

  const handleBrushChange = useCallback((bounds: any) => {
    if (bounds && bounds.x0 !== undefined && bounds.x1 !== undefined) {
      setBrushBounds({
        start: { x: bounds.x0, y: bounds.y0 },
        end: { x: bounds.x1, y: bounds.y1 },
      });

      setIsInitialBounds(bounds.x0 === initialBrushBounds.start.x && bounds.x1 === initialBrushBounds.end.x);
      setStartTime(dayjs(brushBounds.start.x * 1000))
      setEndTime(dayjs(brushBounds.end.x * 1000));
      if (brushBounds.start.x !== 0 && brushBounds.start.y !== 0){
        const timeframe = {
          start: dayjs(brushBounds.start.x).format('YYYY-MM-DDTHH:mm:ss'),
          end: dayjs(brushBounds.end.x).format('YYYY-MM-DDTHH:mm:ss')
        };
        dispatch(setBrushTimeRange(timeframe));
      }

    } else {
      setBrushBounds(initialBrushBounds);
      setIsInitialBounds(true);
    }
  }, [initialBrushBounds]);

  const handleApplyFilters = () => {
    const startTimestamp = dayjs(brushBounds.start.x * 1000).format('YYYY-MM-DDTHH:mm:ss[Z]');
    const endTimestamp = dayjs(brushBounds.end.x * 1000).format('YYYY-MM-DDTHH:mm:ss[Z]');
    if (currentSession) {
      dispatch(fetchData({ sessionId: currentSession.session_id.toString(), sessionType: 'AREA', timeframe: { start: startTimestamp, end: endTimestamp }, pageSize: 50, page: 1, filters, orderBy:null }));

 
      dispatch(fetchMarkers({ sessionId: currentSession.session_id.toString(), sessionType:'AREA', timeframe: { start: startTimestamp, end: endTimestamp }, filters }));
    } else if (currentIdentifySession) {
      dispatch(fetchData({ sessionId: currentIdentifySession.session_id.toString(), sessionType: 'IDENTIFY', timeframe: { start: startTimestamp, end: endTimestamp }, pageSize: 50, page: 1, filters: filters, orderBy: null }));
      dispatch(fetchMarkers({ sessionId: currentIdentifySession.session_id.toString(), sessionType: 'IDENTIFY', timeframe: { start: startTimestamp, end: endTimestamp }, filters }));

    }
  };

  const handleResetFilters = () => {
    setBrushBounds(initialBrushBounds);
    setStartTime(dayjs.utc(start));
    setEndTime(dayjs.utc(end));
    setIsInitialBounds(true);
    setBrushKey((prevKey: any) => prevKey + 1); // Increment brushKey to force re-render
    if (currentSession) {
        dispatch(fetchData({ sessionId: currentSession.session_id.toString(), sessionType: 'AREA', timeframe: null, pageSize: 50, page: 1, filters, orderBy: null }));
        dispatch(fetchMarkers({ sessionId: currentSession.session_id.toString(), sessionType: 'AREA', timeframe: null, filters }));

      } else if (currentIdentifySession) {{
        dispatch(fetchData({ sessionId: currentIdentifySession.session_id.toString(), sessionType: 'IDENTIFY', timeframe: null, pageSize: 50, page: 1, filters: filters, orderBy: null }));
        dispatch(fetchMarkers({ sessionId: currentIdentifySession.session_id.toString(), sessionType: sessionType.toUpperCase(), timeframe: null, filters }));

      }
    }
  };

  const stopPlayback = () => {
    setPlaybackRunning(false);
    setNextIndex(0);
    setReRunner(0);
    setPlaybackData(null);
    handleResetFilters();
  }


  const handlePlayback = () => {
    // let playData: any = [];
    // if (dayjs(brushBounds.start.x).year() != 1970){
    //   data.forEach(entry => {
    //     let entryStart = dayjs(entry.start* 1000).format('YYYY-MM-DDTHH:mm:ss[Z]');
    //     let brushStart = dayjs(brushBounds.start.x).format('YYYY-MM-DDTHH:mm:ss[Z]');
    //     let brushEnd = dayjs(brushBounds.end.x).format('YYYY-MM-DDTHH:mm:ss[Z]');

    //     if (entryStart >= brushStart && entryStart <= brushEnd){
    //         playData.push(entry);
    //     }
    //   })
    // }else{
    //   playData = data;
    // }
    
    setPlaybackData(data);
    setPlaybackRunning(true);
  };
  return (
    <div style={{ zIndex: 2 }}
    >
      
      <Space style={{ width: width, marginLeft: 50 }}>
        <Button
          type='primary'
          style={{ marginRight: 8 }}
          onClick={handleApplyFilters}
          disabled={isInitialBounds}
        >
          Apply Filters
        </Button>
        <Button
          type='default'
          style={{ marginRight: 18 }}
          onClick={handleResetFilters}
          disabled={isInitialBounds}

        >
          Reset Filters
        </Button>
        <Button
          type='default'
          icon={<CaretRightOutlined />}
          onClick={handlePlayback}
          disabled={runningPlayback}
        >
        </Button>
        <Button
          type='default'
          icon={<PauseOutlined />}
          onClick={() => { stopPlayback()}}
          disabled={!runningPlayback}
        >
        </Button>
      </Space>
    
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: -40,  marginLeft: 50}}>
          {/* Start Time */}
          <div className="datetimeTimer">
            <div className="date">
              <span id="dayname">{startTime.format('dddd')} </span>
              <span id="month">{startTime.format('MMMM DD')} </span>
              <span id="minutes">{startTime.format('hh:mm')}</span>:
              <span id="seconds">{startTime.format('ss')}</span>
              <span id="period">{startTime.format('A')}</span>
            </div>
          </div>

          <strong className='divider-datetime'> - </strong>

          {/* End Time */}
          <div className="datetimeTimer">
            <div className="date">
              <span id="dayname">{endTime.format('dddd')} </span>
              <span id="month">{endTime.format('MMMM DD')} </span>
              <span id="minutes">{endTime.format('hh:mm')}</span>:
              <span id="seconds">{endTime.format('ss')}</span>
              <span id="period">{endTime.format('A')}</span>
            </div>
          </div>
        </div>
      <svg width={width} height={height}>
        <LinearGradient id="gradient" from="#008C8C" to="#af8baf" rotate={45} />
        <LinearGradient id="gradient2" from="#a0b3c6" to="#af8baf" rotate={45} />

        <Group left={margin.left} top={margin.top}>
          {data.map((d, i) => {
            const barX = xScale(d.start);
            const barWidth = xScale(binSize) - xScale(0); // Width of each bar
            const isSelected = brushBounds && ((brushBounds?.start.x == 0 && brushBounds?.end.x == xMax) ||

              brushBounds.start.x <= d.start && // Check if brush start is before bar's end
              brushBounds.end.x >= d.end // Check if brush end is after bar's start
            );
            return (
              <Bar
                key={`bar-${i}`}
                x={barX}
                y={yScale(d.count)}
                width={barWidth}
                height={yMax - yScale(d.count)}
                fill={isSelected ? 'url(#gradient)' : 'url(#gradient2)'}
              />
            );
          })}
          <AxisBottom
            top={yMax}
            scale={xScale}
            numTicks={Math.trunc(width / 130)}
            stroke="#fff"
            orientation='bottom'
            tickStroke="#fff"
            tickTransform={'45'}
            tickLabelProps={{
              fill: '#fff',
              fontSize: '1em',
              strokeWidth: 0,
              stroke: '#fff',
              paintOrder: 'stroke',
              fontFamily: 'sans-serif',
              textAnchor: 'end',
            }}
            tickFormat={(value: any) => dayjs(value * 1000).format('DD/MM, HH')}
          />
          <AxisLeft
            scale={yScale}
            numTicks={10}
            stroke="#fff"
            tickStroke="#fff"
            tickLabelProps={{
              dx: '-0.25em',
              dy: '0.25em',
              fontFamily: 'Arial',
              fontSize: 10,
              textAnchor: 'end',
              fill: '#fff',
            }}
          />
          <Brush
            key={`brush-${brushKey}`} // Use the brushKey to force re-render

            xScale={xScale}
            yScale={yScale}
            width={xMax}
            height={yMax}
            onChange={handleBrushChange}
            resizeTriggerAreas={['left', 'right']}

            brushDirection="horizontal"
            renderBrushHandle={(props) => <BrushHandle {...props} />}
            selectedBoxStyle={{
              fill: 'none', // No background color
              stroke: '#ffffff', // White stroke for left and right edges
              strokeWidth: 2,
              pointerEvents: 'none', // Prevent events if needed

            }}
            initialBrushPosition={({ start: { x: 0, y: 0 }, end: { x: xMax, y: yMax } })}
          />
          {tooltipData && (
            <TooltipWithBounds
              top={tooltipTop}
              left={tooltipLeft}
              style={{ backgroundColor: 'black', color: 'white' }}
            >
              {`Count: ${tooltipData.y.toString()}`} {/* Accessing y value correctly */}
            </TooltipWithBounds>
          )}
        </Group>
      </svg>
      
    </div>
  );
};
function BrushHandle({ x, height, isBrushActive }: BrushHandleRenderProps) {
  const pathWidth = 8;
  const pathHeight = 15;
  if (!isBrushActive) {
    return null;
  }
  return (
    <Group left={x + pathWidth / 2} top={(height - pathHeight) / 2}>
      <path
        fill="#f2f2f2"
        d="M -4.5 0.5 L 3.5 0.5 L 3.5 15.5 L -4.5 15.5 L -4.5 0.5 M -1.5 4 L -1.5 12 M 0.5 4 L 0.5 12"
        stroke=" #101827"
        strokeWidth="1"
        style={{ cursor: 'ew-resize' }}
      />
    </Group>
  );
}
export default BrushBarChart;
