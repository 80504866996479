import React, { useState } from 'react';
import MapComponent from '../components/MapComponent';
import { ViewState } from 'react-map-gl';

const MapPage: React.FC = () => {


  return (
    <MapComponent
    //   viewState={viewState}
    //   onViewStateChange={handleViewStateChange}
    //   queries={[]}
    //   selectedDataQuery={null}
    //   onMarkerClick={handleMarkerClick}
    //   onPopupClose={handlePopupClose}
    />
  );
};

export default MapPage;
