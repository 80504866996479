import React, { useEffect, useState } from 'react';
import { Modal, Form, Input, Button } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { createDean, fetchDeanData } from '../store/slices/deanSlice';
import { selectDeanLoading } from '../store/selectors/deanSelectors';
import { AppDispatch } from '../store';

interface CreateDeanModalProps {
  visible: boolean;
  onClose: () => void;
  device_id: string ;
}

const DeanModal: React.FC<CreateDeanModalProps> = ({ visible, onClose ,device_id}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch<AppDispatch>();
  const createLoading = useSelector(selectDeanLoading);
  const [selectedDeviceId, setSelectedDeviceId] = useState<string>(device_id);

  useEffect(() => {
    if (device_id) {
      setSelectedDeviceId(device_id);  
      form.setFieldsValue({device_id: device_id });
    }
  }, [device_id]);

  const handleCreate = (values: any) => {
    const newDean = {
      device_id: values.device_id,
      email: values.email,
      msisdn: values.msisdn,
      md5_device_id: values.md5_device_id,
      user: '',
    };
    dispatch(createDean(newDean)).then(() => {
      form.resetFields();
      dispatch(fetchDeanData({page: 1, pageSize: 3, searchText: null}));

      onClose();
    });
  };

  return (
    <Modal
      title="Create New Dean Request"
      visible={visible}
      onCancel={onClose}
      footer={[
        <Button key="cancel" onClick={onClose}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" loading={createLoading} onClick={() => form.submit()}>
          Submit
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleCreate}
      >
        <Form.Item
          label="Device ID"
          name="device_id"
          rules={[{  message: 'Please enter the device ID' }]}
        >
          <Input     value={selectedDeviceId}      defaultValue={device_id}
 />
        </Form.Item>
        <Form.Item
          label="Email"
          name="email"
          rules={[{ type: 'email', message: 'Please enter a valid email' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="MSISDN" name="msisdn">
          <Input />
        </Form.Item>
        <Form.Item label="MD5 Device ID" name="md5_device_id">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default DeanModal;
