import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { RootState } from '../store';
import { selectToken } from '../store/selectors/authSelectors';

const PrivateRoute: React.FC<{ children: JSX.Element }> = ({ children }) => {
    const tokenTemp = useSelector(selectToken);

    const token = sessionStorage.getItem('token') || localStorage.getItem('token');
    return token ? children : <Navigate to="/login" />;
};

export default PrivateRoute;
