import React, { useEffect, useState } from 'react';
import {
  DeleteOutlined,
  SelectOutlined,
  GatewayOutlined,
  GroupOutlined,
} from '@ant-design/icons';
import { Segmented, Tag, Tooltip } from 'antd';
import { AppDispatch } from '../store';
import { useDispatch, useSelector } from 'react-redux';
import { activeDraw, activeMapBtn, currentFeatureId, selectMultiPolygon, selectEnableDrawing } from '../store/selectors/uiSelectors';
import CustomGrabIcon from './icons/CustomGrabIcon';
import {  setDraw, setFeatureId, setSegm, setPolygon, setMultiPolygon, toggleIdentifySidebar, toggleAnalyticsSidebar } from '../store/slices/uiSlice';
import {
  fetchSessions,
  setCurrentSession,
  fetchMarkers,
  setInitialMarkers,
  fetchAnalyticsData,
  clearSession,
  setMarkers,
  removeMarkers,
} from "../store/slices/sessionSlice";
import {
  clearIdentifySession,
} from "../store/slices/identifySlice";
import { selectDrawOptions } from '../store/selectors/sessionSelectors';
import { title } from 'process';
interface ControlPanelProps {
  map: any; // Pass the Mapbox map instance as a prop to the ControlPanel
  drawRef: any; // Pass the Mapbox draw instance as a prop to the ControlPanel
}

const ControlPanel: React.FC<ControlPanelProps> = ({ map, drawRef }) => {
  const dispatch = useDispatch<AppDispatch>();
  const featureId = useSelector(currentFeatureId); // Error state for identifying sessions
  const segActive = useSelector(activeMapBtn); // Error state for identifying sessions
  const drawActive = useSelector(activeDraw); // Error state for identifying sessions
  const [selectedMode, setSelectedMode] = useState<any>(drawActive); // State to track selected value
  const multipolygon = useSelector(selectMultiPolygon); // Error state for identifying sessions
  const drawOptions = useSelector(selectDrawOptions);
  const [selectedValue, setSelectedValue] = useState<string>(segActive); // State to track selected value
  const [showSecondSegment, setShowSecondSegment] = useState<boolean>(false); // State to track visibility of the second segmented control
  const enableDrawing = useSelector(selectEnableDrawing);

  useEffect(() => {
    setSelectedValue(segActive); // Update selected value when segActive changes
    // if (segActive === 'Drag Edit') {
    //   drawRef.current.changeMode('direct_select', { featureId: featureId.toString() });
    //   setShowSecondSegment(false); // Hide the second segmented control
    handleSegmentChange(segActive);
    // }
  }, [segActive]);
  useEffect(() => {
    // if(drawActive === 'Multi Area'){
    //   drawRef.current.changeMode('draw_polygon');
    //   setShowSecondSegment(false); // Hide the second segmented control

    // }
      // dispatch(setDraw(drawActive));
      setSelectedMode(drawActive); // Update selected value when segActive changes
      // if(drawRef){
      // drawRef.current.changeMode('draw_polygon');
      // }
    

  }, [drawActive]);

  // Function to handle changes in the Segmented control
  const handleSegmentChange = (value: string) => {
    if ((value === 'Thrash' || value === 'Drag Edit') && featureId === '') {
      return; // Do nothing if featureId is empty
    }
    // if (drawActive != null) {
    //   setShowSecondSegment(false); // Hide the second segmented control

    // }
    if (drawRef.current) {
      switch (value) {
        case 'Single':
          setShowSecondSegment(true); // Show the second segmented control on click
          // Update selected value
          // setSelectedValue(value);
          if (selectedMode && selectedMode == 'Single Area'){
            drawRef.current.deleteAll(); 
          }

          dispatch(setSegm(value));
          drawRef.current.changeMode('draw_polygon');



          // dispatch(setDraw('Single Area'));
          break;
        case 'Static':
          drawRef.current.changeMode('simple_select', { featureId });
          setShowSecondSegment(false); // Hide the second segmented control
              // Update selected value
          setSelectedValue(value);
          dispatch(setSegm(value));

          break;

        case 'Thrash':
          drawRef.current.deleteAll();
          dispatch(setFeatureId(''));
          setShowSecondSegment(false); // Hide the second segmented control
              // Update selected value
          setSelectedValue('Static');

          dispatch(setSegm('Static'));

          break;
        // case 'Drag Edit':
        //   drawRef.current.changeMode('direct_select', { featureId: featureId.toString() });
        //   setShowSecondSegment(false); // Hide the second segmented control
        //   setSelectedValue(value);

        //   dispatch(setSegm(value));
        //   break;
        case 'Single Area':   
          setSelectedMode(value);
          dispatch(setDraw(value));
          drawRef.current.deleteAll(); 
          drawRef.current.changeMode('draw_polygon');




          break;
        case 'Border Control':         
          setSelectedMode(value);
          dispatch(setDraw(value));
          drawRef.current.deleteAll(); 
          drawRef.current.changeMode('draw_polygon');
          // Update selected value



          break;
        case 'Multi Area':          // Update selected value
        case 'Multi Area Compare':          // Update selected value
          setSelectedMode(value);
          dispatch(setDraw(value));
if(multipolygon ){
 break;}else{ drawRef.current.deleteAll(); 

}
          drawRef.current.changeMode('draw_polygon');




          break;

        default:
          console.log(`Unhandled segment value: ${value}`);
      }
    }


  };
  // Function to handle changes in the Segmented control
  const handleSecSegmentChange = (value: string) => {
    if ((value === 'Thrash' || value === 'Drag Edit') && featureId === '') {
      return; // Do nothing if featureId is empty
    }
    if (drawRef.current) {
      switch (value) {

        case 'Single Area':
          drawRef.current.deleteAll(); 
          drawRef.current.changeMode('simple_select', { featureId });
          drawRef.current.changeMode('draw_polygon');
          setSelectedMode(value);
          dispatch(setDraw(value));
          break;
        case 'Border Control':
          drawRef.current.deleteAll(); 
          drawRef.current.changeMode('simple_select', { featureId });
          setSelectedMode(value);
          dispatch(setDraw(value));
          drawRef.current.changeMode('draw_polygon');
          break;
        case 'Multi Area':
          drawRef.current.changeMode('simple_select', { featureId });
          setSelectedMode(value);
          dispatch(setDraw(value));
          drawRef.current.changeMode('draw_polygon');
          break;
        case 'Multi Area Compare':
          drawRef.current.changeMode('simple_select', { featureId });
          setSelectedMode(value);
          dispatch(setDraw(value));
          drawRef.current.changeMode('draw_polygon');
          break;
          
        default:
          console.log(`Unhandled segment value: ${value}`);
      }
    }
  };
  // Function to handle the click event for the "Single" option
  const handleSingleClick = () => {
    dispatch(clearSession());
    dispatch(clearIdentifySession());
    dispatch(setPolygon(null));
    dispatch(setMultiPolygon(null));
    dispatch(toggleAnalyticsSidebar(false));
    dispatch(toggleIdentifySidebar(false))
    setShowSecondSegment((prev) => !prev); // Toggle visibility
  };

  let options = [
    { label: 'Single Area', value: 'Single Area', disabled: false, title:'Search using only a single area'},
    { label: 'Multi Area', value: 'Multi Area', disabled: false, title:'Search using multiple areas in parallel' },
    { label: 'Multi Area Compare', value: 'Multi Area Compare', disabled: false, title:'Search only the common devices from multiple areas'  },
    { label: 'Border Control', value: 'Border Control', disabled: false, title: 'Search the users of an area traveling to other countries' },
  ]

  if (!drawOptions.includes('Multi') && !drawOptions.includes('Border')){
    options = [
      { label: 'Single Area', value: 'Single Area', disabled: false, title: 'Search the users of an area traveling to other countries' }
    ]
  }
  return (
    <>
      <Tag style={{
        position: 'absolute',
        top: 70,
        left: 630,
        padding: 8,
        paddingLeft: 16,
        paddingRight: 16,
        border: 0,
        background: 'rgba(16, 24, 39, 0.8)',
        color: '#03dcdc',
        zIndex: 2,
        fontWeight: 600,
      }} title={(showSecondSegment && drawActive !== null) ? drawActive : segActive}>{(showSecondSegment && drawActive !== null) ? drawActive : segActive}</Tag>
      <Segmented
        className='control-p'
        vertical
        onChange={handleSegmentChange}
        options={[
          {
            value: 'Static',
            title: 'Select & Move',
            icon: (
              <Tooltip title="Move around">
                <CustomGrabIcon color={selectedValue === 'Static' ? "rgb(160, 179, 198)" : "rgba(181, 196, 211, 0.894)"} />
              </Tooltip>
            ),
          },
          {
            value: 'Single',
            icon: (
              <Tooltip title="Perform an area search">
                <span onClick={handleSingleClick}>
                  <GatewayOutlined />
                </span>
              </Tooltip>
            ),
          },
          // {
          //   value: 'Drag Edit',
          //   icon: (
          //     <Tooltip title="Drag/Edit a feature">
          //       <SelectOutlined />
          //     </Tooltip>
          //   ),
          //   disabled: featureId === '',
          // },
          {
            value: 'Thrash',
            title: 'Delete all polygons',
            icon: (
              <Tooltip title="Delete all polygons">
                <DeleteOutlined />
              </Tooltip>
            ),
            disabled: featureId === '',
          },
        ]}
        value={selectedValue}
        style={{
          position: 'relative',
          top: 320,
          left: 360,
          zIndex: 2,
          visibility: !enableDrawing ? "hidden" : "visible",
        }}
      />

      {showSecondSegment && (
        <Segmented
          style={{
            position: 'relative',
            top: 350,
            left: 360,
            zIndex: 2,
            background: '#207d7d',
            fontWeight: 600,
            visibility: !enableDrawing ? "hidden" : "visible",
          }}
          options={options}
          value={selectedMode}

          onChange={handleSegmentChange} // Handle changes in the second segmented control
        />
      )}
    </>
  );
};

export default ControlPanel;
