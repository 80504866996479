import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';



export interface Alerts {
    session_id: string | null;
    msg: string;
    created_at: string;
    session_type: string | null;
    user: string;
    event: string | null;
}

interface PaginationData {
    items: Alerts[] | [];
    total: number;
    page: number;
    size: number;
    pages: number;
  }

interface User {
    username: string;
    email: string;
    allowed_countries: string[];
    borders: Array<{
        name: string;
        geojson: {
            type: string;
            geometry: {
                type: string;
                coordinates: any[];
                iso_3: string;
                total_db_records:number;
                unique_db_records:number;
            };
        };
    }>;
    properties: {
        population: number;
        center: string[];
        iso_3: string;
    };
}

interface AuthState {
    token: string | null;
    loading: boolean;
    error: string | null;
    user: User | null;
    allowed_countries: any;
    alerts: number;
    alertData: PaginationData | null;
}

const initialState: AuthState = {
    token: null,
    loading: false,
    error: null,
    user: null,
    allowed_countries: [],
    alerts: 0,
    alertData: null
};

export const login = createAsyncThunk(
    'auth/login',
    async (credentials: { username: string; password: string; remember: boolean }, { rejectWithValue }) => {
        try {
            const params = new URLSearchParams();
            params.append('grant_type', 'password');
            params.append('username', credentials.username);
            params.append('password', credentials.password);
            params.append('scope', '');
            params.append('client_id', 'string');  // Replace with actual client_id if needed
            params.append('client_secret', 'string');  // Replace with actual client_secret if needed

            const response = await axios.post(
                `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/token`,
                params,
                {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                }
            );
            return response.data.access_token;
        } catch (err: any) {
            return rejectWithValue(err.response?.data?.message || 'Login failed');
        }
    }
);



export const fetchUserInfo = createAsyncThunk(
    'auth/fetchUserInfo',
    async (_, { getState, rejectWithValue }) => {
        const state = getState() as { auth: AuthState };
        const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');

        if (!token) {
            return rejectWithValue('No token available');
        }

        try {
            const response = await axios.get(`${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/users/me/`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                },
            });

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data?.message || 'Failed to fetch user info');
        }
    }
);



export const fetchAlerts = createAsyncThunk(
    'auth/fetchAlerts',
    async (
        {
            page = 1,
            pageSize = 10,
          }: {
            page: number;
            pageSize: number;
          }
        , { getState, rejectWithValue }) => {
        const state = getState() as { auth: AuthState };
        const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');

        if (!token) {
            return rejectWithValue('No token available');
        }

        try {
            const response = await axios.get(`${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/users/alerts`, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Accept': 'application/json',
                },
            });

            return response.data;
        } catch (err: any) {
            return rejectWithValue(err.response?.data?.message || 'Failed to fetch user info');
        }
    }
);

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logout: (state) => {
            state.token = null;
            state.user = null;
            localStorage.clear();
            localStorage.removeItem('token'); // Remove token from localStorage
            sessionStorage.removeItem('token'); // Remove token from localStorage
            sessionStorage.clear();

        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action: PayloadAction<string>) => {
                state.loading = false;
                state.token = action.payload;
            })
            .addCase(login.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchUserInfo.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchUserInfo.fulfilled, (state, action: PayloadAction<User>) => {
                state.loading = false;
                state.user = action.payload;
                state.allowed_countries = action.payload.allowed_countries;
            })
            .addCase(fetchUserInfo.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            })
            .addCase(fetchAlerts.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchAlerts.fulfilled, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.alertData = action.payload;
            })
            .addCase(fetchAlerts.rejected, (state, action: PayloadAction<any>) => {
                state.loading = false;
                state.error = action.payload;
            });
    },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
