import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../index';

export const selectSessions = (state: RootState) => state.sessions.sessions;
export const selectCompletedTasks = (state: RootState) => state.sessions.tasksCompleted;
export const selectRunningTasks = (state: RootState) => state.sessions.tasksRunning;
export const selectPendingTasks = (state: RootState) => state.sessions.tasksPending;

export const selectZoomTo = (state: RootState) => state.sessions.zoomTo;

export const selectSelectedRecord = (state: RootState) => state.sessions.selectedRecord;

export const selectTaskType = (state: RootState) => state.sessions.taskType;

export const selectPaginationSessions = (state: RootState) => state.sessions.paginationSessions;

export const selectSessionsError = (state: RootState) => state.sessions.error;
export const selectAddedSession = (state: RootState) => state.sessions.currentSession;
export const selectSessionsLoading = (state: RootState) => state.sessions.sessionLoading;

export const selectTaskLoading = (state: RootState) => state.sessions.taskLoading;
export const selectCurrentTask = (state: RootState) => state.sessions.currentTask;
export const selectDrawOptions = (state: RootState) => state.sessions.drawOptions;

export const selectData = (state: RootState) => state.sessions.analyticsData;
export const selectDataLoading = (state: RootState) => state.sessions.analyticsLoading;
export const selectPaginationData = (state: RootState) => state.sessions.paginationAnalytics;

export const selectAnalyticsGraphData = (state: RootState) => state.sessions.analyticsGraphData;

export const selectMarkers = (state: RootState) => state.sessions.markers;
export const selectTrajectory = (state: RootState) => state.sessions.trajectory;

export const selectInitialMarkers = (state: RootState) => state.sessions.initialMarkers;
export const selectFilteredMarkers = (state: RootState) => state.sessions.filteredMarkers;
export const selectMarkerLoading = (state: RootState) => state.sessions.markerLoading;
export const selectInitialMarkerLoading = (state: RootState) => state.sessions.initialMarkerLoading;
// Selector to get the advanced analytics data
export const selectAdvancedAnalyticsData = (state: RootState) => state.sessions.advancedAnalyticsData;

// Selector to check if advanced analytics data is loading
export const selectAdvancedAnalyticsLoading = (state: RootState) => state.sessions.advancedAnalyticsLoading;

// Selector to get any errors related to fetching advanced analytics data
export const selectAdvancedAnalyticsError = (state: RootState) => state.sessions.error;

// Example selector to get top device IDs from the advanced analytics data
export const selectTopDeviceIDs = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.top_device_ids || []
);

// Example selector to get carrier usage from the advanced analytics data
export const selectCarrierUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.carrier_usage || []
);

// Example selector to get RAT usage from the advanced analytics data
export const selectRATUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.rat_usage || []
);

// Example selector to get SSID usage from the advanced analytics data
export const selectSSIDUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.ssid_usage || []
);

export const selectAPPUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.top_apps || []
);

export const selectBrowserUsage = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.browser_usage || []
);

export const selectTotalAppleDevices = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_apple_devices || 0
);

export const selectTotalAndroidDevices = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_android_devices || 0
);

export const selectPCDevices = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_pc_devices || 0
);

export const selectUniqueIds = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_unique_ids || 0
);

export const selectTotalIds = createSelector(
  [selectAdvancedAnalyticsData],
  (advancedAnalyticsData) => advancedAnalyticsData?.total_ids || 0
);