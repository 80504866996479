import React, { useState, useEffect } from 'react';
import { Tabs, Badge } from 'antd';
import useWindowDimensions from '../hooks/useWindowsDimensions';
import { useSelector } from 'react-redux';
import { RootState } from '../store';
import DeanTable from './DeanTable';
import { selectMarkers } from '../store/selectors/sessionSelectors';
import AlertsTable from './AlertsTable';
import { selectAlertNumber } from '../store/selectors/authSelectors';
import { icons } from 'antd/es/image/PreviewGroup';

const { TabPane } = Tabs;

const FooterBar: React.FC = () => {
    const [activeKey, setActiveKey] = useState<string | null>(null);
    const { height, width } = useWindowDimensions();
    const analyticsSidebar = useSelector((state: RootState) => state.ui.analyticsSidebar);
    const identifySidebar = useSelector((state: RootState) => state.ui.identifySidebar);
    const markers = useSelector(selectMarkers); // Get markers from state
    const alerts = useSelector(selectAlertNumber); // Get markers from state

    useEffect(() => {
        // If analyticsSidebar is false, set activeKey to null
        // if (!analyticsSidebar) {
        //     setActiveKey(null);
        // }
    }, [analyticsSidebar]);

    const handleClick = (key: string) => {
        // Allow deselection by checking if the clicked key is already active
        setActiveKey(activeKey === key ? null : key);
    };

    const tabItems = [
        { key: '1', tab: 'Dean', content: <DeanTable></DeanTable> },
        { key: '2', tab: 'Alerts', content: <AlertsTable></AlertsTable> },
        // { key: '3', tab: 'Analytics', content: 'Content for Tab 3' },
    ];

    return (
        <div
            style={{
                position: 'absolute',
                left: 350,
                bottom: 0,
                height: (activeKey ? height - 400 : height - 39) + ((markers.length>0 ? -10 : 0)),
                top: (activeKey ? height - 470 : height - 39) + ((markers.length>0? -(height/6) : 0)), // Change top position based on activeKey
                zIndex: 2,
                width: width - 350 + (analyticsSidebar || identifySidebar ? -485 : 0),
                overflowY: 'hidden',
            }}
        >
            <Tabs
                className="footer-bar"
                activeKey={activeKey ? activeKey : '10'}
                onChange={setActiveKey}
                type="card"
                style={{ marginBottom: '10px' }}
            // Disable the tabs when analyticsSidebar is false
            >
                {tabItems.map(item => (
                    
                    <TabPane
                        style={{
                            height: (activeKey ? height - 400 : height - 39) + ((markers.length>0 ? -140 : 0)),
                            top: (activeKey ? height - 400 : height - 39) + ((analyticsSidebar || identifySidebar? -190 : 0)), // Change top position based on activeKey
                        }}
                        // disabled={!analyticsSidebar}

                        tab={
                            item.key == '2' ? <Badge count={alerts} color="#03dcdc" offset={[15,0]}><div
                                onClick={() => handleClick(item.key)}
                                style={{ cursor: 'pointer' }}
                            >
                                {item.tab}
                            </div></Badge> : <div
                                onClick={() => handleClick(item.key)}
                                style={{ cursor: 'pointer' }}
                            >
                                {item.tab}
                            </div>
                        }
                        
                        key={item.key}
                    >
                        {/* Show content only if this tab is active */}
                        {activeKey === item.key && item.content}
                    </TabPane>
                ))}
            </Tabs>
        </div>
    );
};

export default FooterBar;
