import React, { useEffect, useRef, useState } from "react";
import mapboxgl, { Map } from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';  // <-- Import the Geocoder
import 'mapbox-gl/dist/mapbox-gl.css';
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../store";
import * as turf from "@turf/turf";
import booleanWithin from "@turf/boolean-within";
import { Spin, Flex, Modal, Descriptions, message } from "antd";
import { resetQueryForm, resetUI, setMapRef, setPolygon, toggleAnalyticsSidebar, toggleBottomSidebar, toggleRightSidebar } from '../store/slices/uiSlice';
import { selectActiveSession, selectPolygon } from "../store/selectors/uiSelectors";
import { selectAddedSession, selectMarkers } from "../store/selectors/sessionSelectors";
import { clearSession, removeMarkers, setCurrentSession } from "../store/slices/sessionSlice";
import { current } from "@reduxjs/toolkit";
export const parseDate = (dateString: string) => {
  const tempDate = new Date(dateString);

  // Get the day name
  const daysOfWeek = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  const dayName = daysOfWeek[tempDate.getDay()];

  // Get the month name
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  const monthName = months[tempDate.getMonth()];

  // Get the day number, year, and time parts
  const dayNum = tempDate.getDate();
  const year = tempDate.getFullYear();
  let hours = tempDate.getHours();
  const minutes = tempDate.getMinutes();
  const seconds = tempDate.getSeconds();
  const period = hours >= 12 ? "PM" : "AM";

  // Convert 24-hour format to 12-hour format
  hours = hours % 12 || 12;

  // Format numbers to always have two digits
  const formatNumber = (num: number) => (num < 10 ? `0${num}` : num);

  // Return all parts as an object
  return {
      dayName,
      monthName,
      dayNum: formatNumber(dayNum),
      year: year.toString(),
      hours: formatNumber(hours),
      minutes: formatNumber(minutes),
      seconds: formatNumber(seconds),
      period
  };
};
export const copyToClipboard = (text: any) => {
  navigator.clipboard.writeText(text);
  message.success('Copied ' + text + ' to clipboard!');
};
// Function to convert the marker coordinates to a position on the screen
export const convertToScreenPosition = (map: any, lat: any, lon: any) => {
  if (map) {
      const point = map.project([lon, lat]);
      const modalHeight = 350; // Set your modal height here

      // Calculate the top position of the modal
      let position = { left: point.x, top: point.y };

      // Check if modal would touch or go off the bottom of the viewport
      const viewportHeight = window.innerHeight;
      if (position.top + modalHeight > viewportHeight) {
          position.top = viewportHeight - modalHeight - 20; // Adjust to fit within the viewport with a margin
      }

      return position;
  }
  return { left: 0, top: 0 };
};
export const handleZoom = (map: mapboxgl.Map) => {
  const bounds = map.getBounds();
  if (!bounds) { return; }
  const center = map.getCenter();
  const topLeft = bounds.getNorthWest();

  const radius = turf.distance(
      turf.point([center.lng, center.lat]),
      turf.point([topLeft.lng, topLeft.lat]),
      'kilometers'
  );

  // Use zoomData as needed
};
export   const shortenText = (text: any) => {

  var length = 20;
  var trimmedString = text.length > length ?
    text.substring(0, length - 3) + "..." : text;
  return trimmedString;
};
