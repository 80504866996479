import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '..';

interface ReportState {
  loading: boolean;
  error: string | null;
}

const initialState: ReportState = {
  loading: false,
  error: null,
};


// Async thunk for downloading the report as PDF
export const downloadReport = createAsyncThunk(
  'session/downloadReport',
  async ({sessionId = '', sessionType = ''}: { sessionId: string, sessionType: string }, { getState, rejectWithValue }) => {
    try {
      const state = getState() as RootState;
      const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');
      
      // Requesting the report
      const params = new URLSearchParams();
      params.append('session_id', sessionId);
      params.append('session_type', sessionType);

      const response = await axios.get(
        `${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/analytics/report`,
        {
          params: {
            session_id: sessionId,
            session_type: sessionType
          },
          headers: {
            Authorization: `Bearer ${token}`,
            Accept: 'application/json', // Request PDF format explicitly
          },
          responseType: 'blob', // Fetching the file as a blob
        }
      );

    //   // Create a download link and trigger the download for PDF
    //   const url = window.URL.createObjectURL(new Blob([response.data], { type: 'text/html' }));
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', `report-${sessionId}.html`); // Save the file as .pdf
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
      // Create a download link and trigger download
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `ai-report-${sessionId}.html`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      return true;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'Download failed');
    }
  }
);

const reportSlice = createSlice({
  name: 'report',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(downloadReport.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(downloadReport.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(downloadReport.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      });
  },
});

export default reportSlice.reducer;
