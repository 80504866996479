import { RootState } from '../index';

export const selectPolygon = (state: RootState) => state.ui.polygon;
export const selectPanningAreas = (state: RootState) => state.ui.panningAreas;
export const selectSessionId = (state: RootState) => state.ui.sessionId;
export const selectFilters = (state: RootState) => state.ui.filters;
export const selectMapRef = (state: RootState) => state.ui.mapref;
export const selectActiveSession = (state: RootState) => state.ui.activeSession;
export const selectCreateType = (state: RootState) => state.ui.createType;
export const resetQF = (state: RootState) => state.ui.queryForm;
export const currentFeatureId = (state: RootState) => state.ui.featureId;
export const activeMapBtn = (state: RootState) => state.ui.activeseg;
export const selectCurrentFilters = (state: RootState) => state.ui.currentFilters;
export const activeDraw = (state: RootState) => state.ui.activeDraw;
export const selectMultiPolygon = (state: RootState) => state.ui.multipolygon;
export const selectZoomLvl = (state: RootState) => state.ui.zoomLvl;
export const selectBrushTimeRange = (state: RootState) => state.ui.brushTimeRange;
export const selectEnableDrawing = (state: RootState) => state.ui.enableDrawing;
