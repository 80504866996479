import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '..';

export interface Dean {
  device_id: string;
  status: string;
  msisdn: string;
  email: string;
  name: string;
  hashed_device_id: string | null;
  created_at: string;
  correlated_at: string | null;
}

interface DeanPaginationData {
  items: Dean[] | [];
  total: number;
  page: number;
  size: number;
  pages: number;
}

interface DeanState {
  data: DeanPaginationData | null;
  loading: boolean;
  error: string | null;
  createLoading: boolean;
  createError: string | null;
}

const initialState: DeanState = {
  data: null,
  loading: false,
  error: null,
  createLoading: false,
  createError: null,
};

// Fetch dean data
export const fetchDeanData = createAsyncThunk('dean/fetchDeanData', async (
  {
    page = 1,
    pageSize = 10,
    searchText = null,
  }: {
    page: number;
    pageSize: number;
    searchText: any;
  }, 
  { getState, dispatch, rejectWithValue }) => {
  const state = getState() as RootState;
          const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');


  try {
    const response = await axios.get(`${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/dean/datatable`, {
      
      params: {
        page: page,
        size: pageSize,
        searchText: searchText
      },
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data || 'Error fetching data');
  }
});

// Create dean thunk
export const createDean = createAsyncThunk('dean/createDean', async (newDean: Partial<Dean>, { getState, dispatch, rejectWithValue }) => {
  const state = getState() as RootState;
          const token = state.auth.token || localStorage.getItem('token') || sessionStorage.getItem('token');


  try {
    const response = await axios.post(`${window.__RUNTIME_CONFIG__.ADINT_BACKEND_URL }/${window.__RUNTIME_CONFIG__.ADINT_ENV }/${window.__RUNTIME_CONFIG__.ADINT_API_VERSION }/dean/create`, newDean, {
        
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        
    });
    return response.data;
  } catch (error: any) {
    return rejectWithValue(error.response?.data || 'Error creating dean');
  }
});

const deanSlice = createSlice({
  name: 'dean',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      // Fetch dean data
      .addCase(fetchDeanData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchDeanData.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
      })
      .addCase(fetchDeanData.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload as string;
      })
      // Create dean
      .addCase(createDean.pending, (state) => {
        state.createLoading = true;
        state.createError = null;
      })
      .addCase(createDean.fulfilled, (state, action) => {
        state.data = action.payload;
        state.createLoading = false;
      })
      .addCase(createDean.rejected, (state, action) => {
        state.createLoading = false;
        state.createError = action.payload as string;
      });
  },
});

export default deanSlice.reducer;
