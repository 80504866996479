import React, { useEffect, useRef, useState } from 'react';
import { AppstoreOutlined, MailOutlined, SettingOutlined } from '@ant-design/icons';
import type { MenuProps } from 'antd';
import { Menu } from 'antd';
import { Layout } from 'antd';
import DataQueryList from '../components/DataQueryList';
import Map from '../components/Map';
import BrushChart from '../components/BrushChart';
import { useDataQuery } from '../hooks/useDataQuery';
import RightSidebar from '../components/RightSidebar';  // Import the RightSidebar component
import Navigation from '../components/Navigation';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import { dummyData } from '../components/dummyData';
import Sider from 'antd/es/layout/Sider';
import { MapRef } from 'react-map-gl';
import MapPage from './MapPage';
import SessionList from '../components/SessionList';
import { selectInitialMarkers, selectMarkers } from '../store/selectors/sessionSelectors';
import { setFilteredMarkers, setMarkers } from '../store/slices/sessionSlice';
import AnalyticsSidebar from '../components/AnalyticsSidebar';
import IdentifySiderbar from '../components/IdentifySiderbar';
import TaskSidebar from '../components/TaskSidebar';

import QueryForm from '../components/QueryForm';
import IdentifyQueryForm from '../components/IdentifyQueryForm';
import TaskQueryForm from '../components/TaskQueryForm';
import { selectActiveSession } from '../store/selectors/uiSelectors';

import FooterBar from '../components/FooterBar';
import HistogramSlider from '../components/HistogramSlider';

const { Header, Content } = Layout;

const Home: React.FC = () => {
  const { queries, loading } = useDataQuery();
  const [filteredDataQuerys, setFilteredDataQuerys] = useState(queries);
  const [collapsed, setCollapsed] = useState(false);
  const rightSidebar = useSelector((state: RootState) => state.ui.rightSidebar);
  const analyticsSidebar = useSelector((state: RootState) => state.ui.analyticsSidebar);
  const identifySidebar = useSelector((state: RootState) => state.ui.identifySidebar);
  const taskSidebar = useSelector((state: RootState) => state.ui.taskSidebar);
  const [rightSidebarForm, setRightSidebarForm] = useState<number>(0);

  const leftSidebar = useSelector((state: RootState) => state.ui.leftSidebar);
  const markers = useSelector(selectMarkers); // Get markers from state
  const inititialMarkers = useSelector(selectInitialMarkers); // Get markers from state

  const dispatch = useDispatch<AppDispatch>();
  const [selectedDataQuery, setSelectedDataQuery] = useState<any[]>(markers);

  const mapRef = useRef<MapRef>(null);
  const { token, user, error } = useSelector((state: RootState) => state.auth);
  const sessionType = useSelector(selectActiveSession); // Get markers from state

  useEffect(() => {
    setFilteredDataQuerys(queries); // Initialize filteredDataQuerys with all queries initially
  }, [queries]);

  useEffect(() => {
    if (sessionType){
      if (sessionType == 'Identify'){
        setRightSidebarForm(2);
      }else if (sessionType == 'Monitor'){
        setRightSidebarForm(0);
      }else{
        setRightSidebarForm(1);
      }
    }

  }, [sessionType]);
  
  const sideBarOptions = [
  <TaskQueryForm></TaskQueryForm>, 
  <QueryForm></QueryForm>,
  <IdentifyQueryForm></IdentifyQueryForm>
  ]
  const handleMarkerClick = (user: any) => {
    setSelectedDataQuery(user);
    // setViewState({
    //   longitude: parseFloat(user.address.coordinates.lng),
    //   latitude: parseFloat(user.address.coordinates.lat),
    //   zoom: 8,
    //   bearing: 0,
    //   pitch: 0,
    //   padding: { top: 0, bottom: 0, left: 0, right: 0 }
    // });
  };

  // const handlePopupClose = () => {
  //   setSelectedDataQuery(null);
  // };

  const handleBrushChange = (startDate: Date, endDate: Date) => {
    const filtered = inititialMarkers.filter(marker => {
      const markerDate = new Date(marker.ts);
      return markerDate >= startDate && markerDate <= endDate;
    });
    setTimeout(() => {
      dispatch(setMarkers(filtered));

    }, 2000);
    // const filteredDataQuerys = queries.filter((user: { birthDate: string | number | Date }) => {
    //   const birthDate = new Date(user.birthDate);
    //   return birthDate >= startDate && birthDate <= endDate;
    // });

    // if (filteredDataQuerys.length > 0) {
    //   const randomDataQuery = filteredDataQuerys[Math.floor(Math.random() * filteredDataQuerys.length)];
    //   setSelectedDataQuery(randomDataQuery);
    //   setViewState({
    //     longitude: parseFloat(randomDataQuery.address.coordinates.lng),
    //     latitude: parseFloat(randomDataQuery.address.coordinates.lat),
    //     zoom: 8,
    //     bearing: 0,
    //     pitch: 0,
    //     padding: { top: 0, bottom: 0, left: 0, right: 0 }
    //   });
    // }
  };

  const handleFilterChange = (value: any) => {
    const filtered = queries.filter(user => {
      // Add your filtering logic here, e.g., by city, gender, etc.
      return true; // Replace with actual filtering condition
    });
    setFilteredDataQuerys(filtered);
  };
  // Function to format the raw data

  const layoutCSS = {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };


  return (
    <Layout style={{ height: '100vh', position: 'relative' }}>
      <Layout style={{ height: 'calc(100%)', position: 'relative' }}>
        <Navigation />
        <Content>
          <MapPage />
        </Content>
        <RightSidebar

          collapsed={rightSidebar}
          onCollapse={setCollapsed}
          onFilterChange={handleFilterChange}
          children={sideBarOptions[rightSidebarForm]}
        />
        <AnalyticsSidebar

          collapsed={analyticsSidebar}
          onCollapse={setCollapsed}
          onFilterChange={handleFilterChange}
        />
        <TaskSidebar
          collapsed={taskSidebar}
          onCollapse={setCollapsed}
          onFilterChange={handleFilterChange}
          />
        <IdentifySiderbar
          collapsed={identifySidebar}
          onCollapse={setCollapsed}
          onFilterChange={handleFilterChange}/>
          
        <Sider
          width={350}
          style={{
            position: 'absolute',
            top: 45,
            left: 0,
            bottom: 0,
            zIndex: 10,
            overflowY: 'scroll',
            overflowX: 'hidden',
            padding: '10px',
            scrollbarWidth: 'none', // Hide scrollbar in Firefox
            backgroundColor: 'rgba(16, 24, 39, 0.8)',
            borderLeft: 'none',
            borderTopLeftRadius: 0,
            borderBottomLeftRadius: 0,

          }}
        >
          <SessionList></SessionList>
          {/* <DataQueryList queries={queries} loading={loading} onDataQueryClick={handleMarkerClick} /> */}
        </Sider>      
      <FooterBar></FooterBar>

      </Layout>
      {/* <BrushChart data={formattedData} onBrushChange={handleBrushChange} show={true} />*/}
      { markers.length> 0 &&<HistogramSlider></HistogramSlider>} 
    </Layout>
  );
};

export default Home;